import { Paper } from "@mui/material";
import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import InsideHeader from "../../layout/InsideHeader";
import { Link } from "react-router-dom";

function QuickPost() {
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          background: "#f8f8f8",
          marginBottom: "15px",
        }}
      >
        <InsideHeader title="Quick Posts">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Quick Posts
            </Link>
            <Typography color="textPrimary">Posts</Typography>
          </Breadcrumbs>
        </InsideHeader>
      </Paper>
    </>
  );
}

export default QuickPost;
