import { toast } from "react-toastify";
import {
  deleteAdsApi,
  deleteCategoryPromotionalDataApi,
  deletePromotedContentApi,
  getAdsApi,
  getAdsByTitleApi,
  getAdsCategoryApi,
  getCategoryPromotionalApi,
  getCategoryPromotionalDataApi,
  getPromotedContentApi,
  getPromotedContentCategoryApi,
  getPromotedContentUpdateApi,
  postAdsApi,
  postAdsUpdateApi,
  postCategoryPromotionalApi,
  postPromotedContentApi,
  postPromotedContentStatusApi,
  updatePromotedContentApi,
} from "../../../api/adsManagement/AdsManagementApi";
import * as actions from "./Type";

export const postAds = (data, handleError, setButtonLoader) => (dispatch) => {
  postAdsApi(data)
    .then((res) => {
      toast.success(res?.data?.message);
    })
    .catch((err) => {
      console.log(err, "err");
      handleError && handleError(err.response.data?.errors);
    })
    .finally(() => {
      setButtonLoader(false);
    });
};

export const getAds = () => (dispatch) => {
  dispatch({ type: actions.GET_ADS_BEGIN });
  getAdsApi()
    .then((res) => {
      dispatch({
        type: actions.GET_ADS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_ADS_FAIL,
      });
    });
};

export const getAdsCategory = () => (dispatch) => {
  dispatch({ type: actions.GET_ADS_CATEGORY_BEGIN });
  getAdsCategoryApi()
    .then((res) => {
      dispatch({
        type: actions.GET_ADS_CATEGORY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_ADS_CATEGORY_FAIL,
      });
    });
};

export const getAdsByTitle = (category, title) => (dispatch) => {
  dispatch({ type: actions.GET_ADS_BY_TITLE_BEGIN });
  getAdsByTitleApi(category, title)
    .then((res) => {
      dispatch({
        type: actions.GET_ADS_BY_TITLE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_ADS_BY_TITLE_FAIL,
      });
    });
};

export const postAdsUpdate =
  (id, data, handleError, setButtonLoader) => (dispatch) => {
    postAdsUpdateApi(id, data)
      .then((res) => {
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        console.log(err, "err");
        handleError && handleError(err.response.data?.errors);
      })
      .finally(() => {
        setButtonLoader(false);
      });
  };

export const deleteAds = (id, handleClose) => (dispatch) => {
  dispatch({ type: actions.DELETE_ADS_BEGIN });
  deleteAdsApi(id)
    .then((res) => {
      dispatch({
        type: actions.DELETE_ADS_SUCCESS,
        payload: res.id,
      });
      handleClose();
      dispatch(getAds());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.DELETE_ADS_FAIL,
      });
    });
};

///////////////////////////////////////////////////////////////////////////

export const postPromotedContent =
  (data, handleToggle, reset, handleError) => (dispatch) => {
    dispatch({ type: actions.POST_PROMOTED_CONTENT_BEGIN });
    postPromotedContentApi(data)
      .then((res) => {
        dispatch({
          type: actions.POST_PROMOTED_CONTENT_SUCCESS,
          payload: res.data,
        });
        handleToggle(false);
        reset();
        dispatch(getPromotedContent());
      })
      .catch((err) => {
        console.log(err, "err");
        handleError && handleError(err.response.data?.errors);
        dispatch({
          type: actions.POST_PROMOTED_CONTENT_FAIL,
        });
      });
  };

export const getPromotedContent = () => (dispatch) => {
  dispatch({ type: actions.GET_PROMOTED_CONTENT_BEGIN });
  getPromotedContentApi()
    .then((res) => {
      console.log("response", res);
      dispatch({
        type: actions.GET_PROMOTED_CONTENT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_PROMOTED_CONTENT_FAIL,
      });
    });
};

export const deletePromotedContent = (id, handleClose) => (dispatch) => {
  dispatch({ type: actions.DELETE_PROMOTED_CONTENT_BEGIN });
  deletePromotedContentApi(id)
    .then((res) => {
      dispatch({
        type: actions.DELETE_PROMOTED_CONTENT_SUCCESS,
        payload: res.id,
      });
      handleClose();
      dispatch(getPromotedContent());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.DELETE_PROMOTED_CONTENT_FAIL,
      });
    });
};

export const updatePromotedContents =
  (slug, data, handleToggle, handleError) => (dispatch) => {
    dispatch({ type: actions.UPDATE_PROMOTED_CONTENT_BEGIN });
    updatePromotedContentApi(slug, data)
      .then((res) => {
        dispatch({
          type: actions.UPDATE_PROMOTED_CONTENT_SUCCESS,
          payload: res.id,
        });
        handleToggle();
        dispatch({ type: actions.CLEAN_PROMOTED_CONTENT_UPDATE });
        dispatch(getPromotedContent());
      })
      .catch((err) => {
        console.log(err, "err");
        handleError && handleError(err.response.data?.errors);
        dispatch({
          type: actions.UPDATE_PROMOTED_CONTENT_FAIL,
        });
      });
  };

export const getPromotedContentUpdate = (slug) => (dispatch) => {
  dispatch({ type: actions.GET_PROMOTED_CONTENT_UPDATE_BEGIN });
  getPromotedContentUpdateApi(slug)
    .then((res) => {
      dispatch({
        type: actions.GET_PROMOTED_CONTENT_UPDATE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_PROMOTED_CONTENT_UPDATE_FAIL,
      });
    });
};

export const postPromotedContentStatus = (slug, data) => (dispatch) => {
  dispatch({ type: actions.POST_PROMOTED_CONTENT_STATUS_BEGIN });
  postPromotedContentStatusApi(slug, data)
    .then((res) => {
      dispatch({
        type: actions.POST_PROMOTED_CONTENT_STATUS_SUCCESS,
        payload: res.id,
      });

      dispatch(getPromotedContent());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.POST_PROMOTED_CONTENT_STATUS_FAIL,
      });
    });
};

export const getCategoryPromotional = (data) => (dispatch) => {
  dispatch({ type: actions.GET_CATEGORY_PROMOTIONAL_BEGIN });
  getCategoryPromotionalApi(data)
    .then((res) => {
      dispatch({
        type: actions.GET_CATEGORY_PROMOTIONAL_SUCCESS,
        payload: { category: data.category, content: res.data.data },
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_CATEGORY_PROMOTIONAL_FAIL,
      });
    });
};

export const postCategoryPromotional = (data) => (dispatch) => {
  console.log("POst category promotional data ", data);
  dispatch({ type: actions.POST_CATEGORY_PROMOTIONAL_BEGIN });
  postCategoryPromotionalApi(data)
    .then((res) => {
      toast.success(res?.data?.message);
      dispatch({
        type: actions.POST_CATEGORY_PROMOTIONAL_SUCCESS,
      });
      dispatch(getCategoryPromotionalData());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.POST_CATEGORY_PROMOTIONAL_FAIL,
      });
    });
};

export const getCategoryPromotionalData = () => (dispatch) => {
  dispatch({ type: actions.GET_CATEGORY_PROMOTIONAL_DATA_BEGIN });
  getCategoryPromotionalDataApi()
    .then((res) => {
      dispatch({
        type: actions.GET_CATEGORY_PROMOTIONAL_DATA_SUCCESS,
        payload: { data: res.data.data },
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_CATEGORY_PROMOTIONAL_DATA_FAIL,
      });
    });
};

export const deleteCategoryPromotionalData = (data) => (dispatch) => {

  dispatch({ type: actions.DELETE_CATEGORY_PROMOTIONAL_DATA_BEGIN });

  deleteCategoryPromotionalDataApi(data)
    .then((res) => {
      toast.success(res?.data?.message);
      dispatch({
        type: actions.DELETE_CATEGORY_PROMOTIONAL_DATA_FAIL,
      });

      dispatch(getCategoryPromotionalData());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.DELETE_CATEGORY_PROMOTIONAL_DATA_FAIL,
      });

    });
};
