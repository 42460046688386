import * as actions from "./Auth.type";
const initialState = {
  token: localStorage.getItem("token") || "",
  isAuthenticated: false,
  isLoading: false,
  user: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    // case actions.USER_LOADING:
    //   return {
    //     ...state,
    //     isLoading: true,
    //     token: localStorage.getItem("token"),
    //     user: JSON.parse(localStorage.getItem("user")),
    //   };

    // case actions.USER_LOADED:
    //   return {
    //     ...state,
    //     isAuthenticated: true,
    //     isLoading: false,
    //     loaded: true,
    //     // user: action.payload.data.user,
    //   };

    case actions.LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.access_token);
      localStorage.setItem("user", action.payload.user.id);
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        token: action.payload.token,
        user: action.payload.user,
      };

    // case actions.REGISTER_SUCCESS:
    //   return state;

    // case actions.REGISTER_FAIL:
    case actions.AUTH_ERROR:
    case actions.LOGOUT_SUCCESS:
    case actions.LOGIN_FAIL:
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      };

    default:
      return state;
  }
};
