import { axiosInstance } from "../../utils/axiosInstance";
import { handleError, handleResponse } from "../apiResponse";

export function getBusinessReviewsApi() {
    return axiosInstance()
        .get('/admin/all-business-review')
        .catch(handleError)
}

export function deleteBusinessReviewsApi(id) {
    return axiosInstance()
        .get(`/admin/delete-business-review/${id}`)
        .catch(handleError)
}