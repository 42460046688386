import { clearErrors } from '../Error/Error.action';
import * as actions from './Success.type';

export const returnSuccess = (message) => (dispatch) => {
  dispatch(clearSuccess());
  dispatch(clearErrors());
  dispatch({
    type: actions.GET_SUCCESS,
    payload: message,
  });
};

export const clearSuccess = () => {
  return {
    type: actions.CLEAR_SUCCESS,
  };
};
