export const RENTAL_POST_BEGIN = "RENTAL_POST_BEGIN";
export const RENTAL_POST_FAIL = "RENTAL_POST_FAIL";
export const RENTAL_POST_SUCCESS = "RENTAL_POST_SUCCESS";

export const GET_RENTAL_BEGIN = "GET_RENTAL_BEGIN";
export const GET_RENTAL_FAIL = "GET_RENTAL_FAIL";
export const GET_RENTAL_SUCCESS = "GET_RENTAL_SUCCESS";

export const GET_RENTAL_UPDATE_BEGIN = "GET_RENTAL_UPDATE_BEGIN";
export const GET_RENTAL_UPDATE_SUCCESS = "GET_RENTAL_UPDATE_SUCCESS";
export const GET_RENTAL_UPDATE_FAIL = "GET_RENTAL_UPDATE_FAIL";

export const PUT_RENTAL_UPDATE_BEGIN = "PUT_RENTAL_UPDATE_BEGIN";
export const PUT_RENTAL_UPDATE_SUCCESS = "PUT_RENTAL_UPDATE_SUCCESS";
export const PUT_RENTAL_UPDATE_FAIL = "PUT_RENTAL_UPDATE_FAIL";

export const RENTAL_DELETE_BEGIN = "RENTAL_DELETE_BEGIN";
export const RENTAL_DELETE_SUCCESS = "RENTAL_DELETE_SUCCESS";
export const RENTAL_DELETE_FAIL = "RENTAL_DELETE_FAIL";

export const POST_RENTAL_FEATURE_BEGIN = "POST_RENTAL_FEATURE_BEGIN";
export const POST_RENTAL_FEATURE_SUCCESS = "POST_RENTAL_FEATURE_SUCCESS";
export const POST_RENTAL_FEATURE_FAIL = "POST_RENTAL_FEATURE_FAIL";

export const GET_RENTAL_FEATURE_BEGIN = "GET_RENTAL_FEATURE_BEGIN";
export const GET_RENTAL_FEATURE_SUCCESS = "GET_RENTAL_FEATURE_SUCCESS";
export const GET_RENTAL_FEATURE_FAIL = "GET_RENTAL_FEATURE_FAIL";

export const GET_RENTAL_FEATURE_UPDATE_BEGIN =
  "GET_RENTAL_FEATURE_UPDATE_BEGIN";
export const GET_RENTAL_FEATURE_UPDATE_SUCCESS =
  "GET_RENTAL_FEATURE_UPDATE_SUCCESS";
export const GET_RENTAL_FEATURE_UPDATE_FAIL = "GET_RENTAL_FEATURE_UPDATE_FAIL";

export const PUT_RENTAL_FEATURE_UPDATE_BEGIN =
  "PUT_RENTAL_FEATURE_UPDATE_BEGIN";
export const PUT_RENTAL_FEATURE_UPDATE_SUCCESS =
  "PUT_RENTAL_FEATURE_UPDATE_SUCCESS";
export const PUT_RENTAL_FEATURE_UPDATE_FAIL = "PUT_RENTAL_FEATURE_UPDATE_FAIL";

export const RENTAL_FEATURE_DELETE_BEGIN = "RENTAL_FEATURE_DELETE_BEGIN";
export const RENTAL_FEATURE_DELETE_SUCCESS = "RENTAL_FEATURE_DELETE_SUCCESS";
export const RENTAL_FEATURE_DELETE_FAIL = "RENTAL_FEATURE_DELETE_FAIL";

export const POST_RENTAL_AMENITIES_BEGIN = "POST_RENTAL_AMENITIES_BEGIN";
export const POST_RENTAL_AMENITIES_SUCCESS = "POST_RENTAL_AMENITIES_SUCCESS";
export const POST_RENTAL_AMENITIES_FAIL = "POST_RENTAL_AMENITIES_FAIL";

export const GET_RENTAL_AMENITIES_BEGIN = "GET_RENTAL_AMENITIES_BEGIN";
export const GET_RENTAL_AMENITIES_SUCCESS = "GET_RENTAL_AMENITIES_SUCCESS";
export const GET_RENTAL_AMENITIES_FAIL = "GET_RENTAL_AMENITIES_FAIL";

export const GET_RENTAL_AMENITY_UPDATE_BEGIN =
  "GET_RENTAL_AMENITY_UPDATE_BEGIN";
export const GET_RENTAL_AMENITY_UPDATE_SUCCESS =
  "GET_RENTAL_AMENITY_UPDATE_SUCCESS";
export const GET_RENTAL_AMENITY_UPDATE_FAIL = "GET_RENTAL_AMENITY_UPDATE_FAIL";

export const PUT_RENTAL_AMENITY_UPDATE_BEGIN =
  "PUT_RENTAL_AMENITY_UPDATE_BEGIN";
export const PUT_RENTAL_AMENITY_UPDATE_SUCCESS =
  "PUT_RENTAL_AMENITY_UPDATE_SUCCESS";
export const PUT_RENTAL_AMENITY_UPDATE_FAIL = "PUT_RENTAL_AMENITY_UPDATE_FAIL";

export const RENTAL_AMENITY_DELETE_BEGIN = "RENTAL_AMENITY_DELETE_BEGIN";
export const RENTAL_AMENITY_DELETE_SUCCESS = "RENTAL_AMENITY_DELETE_SUCCESS";
export const RENTAL_AMENITY_DELETE_FAIL = "RENTAL_AMENITY_DELETE_FAIL";

// -----------------------------------------------------------------------------------------

export const GET_ALL_RENTAL_BEGIN = "GET_ALL_RENTAL_BEGIN";
export const GET_ALL_RENTAL_SUCCESS = "GET_ALL_RENTAL_SUCCESS";
export const GET_ALL_RENTAL_FAIL = "GET_ALL_RENTAL_FAIL";

export const POST_RENTAL_STATUS_BEGIN = "POST_RENTAL_STATUS_BEGIN";
export const POST_RENTAL_STATUS_SUCCESS = "POST_RENTAL_STATUS_SUCCESS";
export const POST_RENTAL_STATUS_FAIL = "POST_RENTAL_STATUS_FAIL";

export const CLEAN_CATEGORY_UPDATE = "CLEAN_CATEGORY_UPDATE";
export const CLEAN_FEATURE_UPDATE = "CLEAN_FEATURE_UPDATE";
export const CLEAN_AMENITIES_UPDATE = "CLEAN_AMENITIES_UPDATE";

export const RENTAL_LIST_DELETE_BEGIN = "RENTAL_LIST_DELETE_BEGIN";
export const RENTAL_LIST_DELETE_SUCCESS = "RENTAL_LIST_DELETE_SUCCESS";
export const RENTAL_LIST_DELETE_FAIL = "RENTAL_LIST_DELETE_FAIL";
