import {
  Box,
  Modal,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { postJobStatus } from "../../job/redux/Action";
import { postBusinessStatus } from "../../business/redux/Action";
import { postBlogStatus } from "../../blogs/redux/Action";
import ClearAllIcon from "@mui/icons-material/ClearAll";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0),
  },
  tableRoot: {
    width: "100%",
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    "& .MuiTableCell-stickyHeader": {
      background: theme.palette.background.dark,
      //   color: theme.palette.primary.dark,
    },
    "& .MuiTableBody-root": {
      "& tr:nth-of-type(odd)": {
        background: theme.palette.background.main,
      },
    },
  },
  delete: {
    color: "#ff6363",
    cursor: "pointer",
    "&:hover": {
      color: "red",
    },
  },
  container: {
    maxHeight: 550,
    borderRadius: "5px",
  },
  paragraph: {
    fontSize: "14px",
    color: "#403131",
    display: "-webkit-box",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));
const columns = [
  {
    id: "description",
    label: "Description",
    minWidth: 50,
  },
];

const CustomQuickPostRow = ({ index, columns, row }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  console.log("quick", columns, row);
  const getSwitchValue = (e, value, slug) => {
    dispatch(
      postBlogStatus(slug, { _method: "patch", value: value === true ? 1 : 0 })
    );
  };
  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
        <TableCell>{index + 1}</TableCell>
        {columns.map((column, ind) => {
          const value = row[column.id];
          return column.id === "description" ? (
            <TableCell>
              <Box
                display="flex"
                alignItems="center"
                sx={{ cursor: "pointer" }}
                className={classes.paragraph}
                onClick={() => setOpen(true)}
              >
                {row?.description}
                <ClearAllIcon />
              </Box>
            </TableCell>
          ) : (
            <TableCell key={column.id} align={column.align}>
              {value}
            </TableCell>
          );
        })}
      </TableRow>
      <CustomModal open={open} handleClose={handleClose} description={row} />
    </>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "30%",
  width: 800,
};

const CustomModal = ({ open, handleClose, description }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper>
        <Box sx={style}>
          <div>
            <Paper className={classes.tableRoot}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          sx={{ fontWeight: "bold" }}
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow>
                      <TableCell>
                        {" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: description?.description,
                          }}
                          style={{ fontSize: "14px", color: "#403131" }}
                        ></div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </Box>
      </Paper>
    </Modal>
  );
};

export default CustomQuickPostRow;
