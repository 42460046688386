import UploadIcon from "@mui/icons-material/Upload";
import { Breadcrumbs, Button, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import InsideHeader from "../../layout/InsideHeader";
import { serializer } from "../../utils/helper";

import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { CustomHiddenInput } from "../common/customInput/CustomHiddenInput";
import { CustomInput } from "../common/customInput/CustomInput";
import { getSiteSetting, postSiteSetting } from "./redux/Action";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "../../pages/Home/Loader";
import { QuillTextEditor } from "../common/customTextarea/QuillTextEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    "& .uploadAndEdit": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  about: {
    "& .MuiOutlinedInput-root": {
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
    },
  },
}));

const SiteSetting = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const siteData = useSelector((state) => state.siteSetting);
  console.log("New Site Data is ", siteData);

  const defaultValues = {
    full_address_1: "",
    full_address_2: "",
    primary_mobile: "",
    secondary_mobile: "",
    landline_no: "",
    primary_email: "",
    informational_email: "",
    customer_support_email: "",
    facebook_link: "",
    instagram_link: "",
    youtube_link: "",
    company_logo: "",
    company_favicon: "",
    contact_section: "",
    about_us: "",
    tos: "",
    privacy_policy: "",
    login_upper_text: "",
    login_lower_text: "",
    login_image: "",
    register_upper_text: "",
    register_lower_text: "",
    register_image: "",
    referral: "",
    account_delete: "",
    footer_description: "",
    footer_contact_header: "",
    footer_contact_description: "",
  };

  const schema = yup.object({
    full_address_1: yup.string().required("Primary full address is required"),
    full_address_2: yup.string().required("Secondary full address is required"),
    primary_mobile: yup
      .number()
      .typeError("Primary mobile no. is required")
      .required(),
    secondary_mobile: yup
      .number()
      .typeError("Secondary mobile no. is required")
      .required(),
    landline_no: yup.number().typeError("Landline no. is required").required(),
    primary_email: yup.string().email().required("Primary email is required"),
    informational_email: yup
      .string()
      .email()
      .required("Informational email is required"),
    customer_support_email: yup
      .string()
      .email()
      .required("Customer Support email is required"),
    facebook_link: yup
      .string()
      // .matches(
      //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      //     'Enter correct url!'
      // )
      .required("Please enter facebook link"),
    instagram_link: yup
      .string()
      // .matches(
      //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      //     'Enter correct url!'
      // )
      .required("Please enter Instagram link"),
    youtube_link: yup
      .string()
      // .matches(
      //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      //     'Enter correct url!'
      // )
      .required("Please enter youtube link"),
    company_logo: yup.mixed().required("Company logo is required"),
    company_favicon: yup.mixed().required("Company favicon is required"),
    // footer_description: yup.string().required("Footer Description is required"),
    // footer_contact_header: yup
    //   .string()
    //   .required("Footer Contact Header is required"),
    // footer_contact_description: yup
    //   .string()
    //   .required("Footer Contact Description is required"),
  });

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema) });

  const watchAll = watch();
  console.log("Watching All ", watchAll);

  const uploadLogo = useRef(null);
  const uploadLoginBackgroundRef = useRef(null);
  const uploadRegisterBackgroundRef = useRef(null);

  const logo = watch("company_logo");
  const loginBackgroundLogo = watch("login_image");
  const RegisterBackgroundLogo = watch("register_image");

  const uploadFavicon = useRef(null);
  const favicon = watch("company_favicon");

  const uploadCompanyLogo = () => {
    uploadLogo.current.click();
  };

  const uploadLoginBackground = () => {
    uploadLoginBackgroundRef.current.click();
  };

  const uploadRegisterBackground = () => {
    uploadRegisterBackgroundRef.current.click();
  };

  const uploadCompanyFavicon = () => {
    uploadFavicon.current.click();
  };

  const onSubmit = (values) => {
    const formData = new FormData();
    serializer(formData, values);
    dispatch(postSiteSetting(formData));
  };

  useEffect(() => {
    dispatch(getSiteSetting());
  }, []);

  useEffect(() => {
    if (siteData?.siteData) {
      setValue("full_address_1", siteData?.siteData[0]?.value);
      setValue("full_address_2", siteData?.siteData[1]?.value);
      setValue("primary_mobile", siteData?.siteData[2]?.value);
      setValue("secondary_mobile", siteData?.siteData[3]?.value);
      setValue("landline_no", siteData?.siteData[4]?.value);
      setValue("primary_email", siteData?.siteData[5]?.value);
      setValue("informational_email", siteData?.siteData[6]?.value);
      setValue("customer_support_email", siteData?.siteData[7]?.value);
      setValue("facebook_link", siteData?.siteData[8]?.value);
      setValue("instagram_link", siteData?.siteData[9]?.value);
      setValue("youtube_link", siteData?.siteData[10]?.value);
      // setValue("contact_section", contactSection?.value);
      setValue("about_us", siteData?.siteData[14]?.value);
      setValue("tos", siteData?.siteData[15]?.value);
      setValue("privacy_policy", siteData?.siteData[16]?.value);
      setValue("map_iframe", siteData?.siteData[17]?.value);
      setValue("site_title", siteData?.siteData[26]?.value);
      setValue("meta_description", siteData?.siteData[27]?.value);
      // setValue("register_image", siteData?.siteData[28]?.value);
      setValue("login_upper_text", siteData?.siteData[23]?.value);
      setValue("login_lower_text", siteData?.siteData[24]?.value);
      // setValue("login_image", siteData?.siteData[25]?.value);
    }
  }, [siteData, setValue]);

  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };
  const textEditorData = (type) => {
    const data = siteData?.siteData?.find((data) => data?.key === type);
    return data?.value;
  };

  return siteData === "null" || siteData?.isLoading ? (
    <Loader />
  ) : (
    <>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          background: "#f8f8f8",
          marginBottom: "15px",
        }}
      >
        <InsideHeader title="Site Setting">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Setting
            </Link>
            <Typography color="textPrimary">Site Setting</Typography>
          </Breadcrumbs>
        </InsideHeader>
      </Paper>

      <Paper
        elevation={1}
        className={classes.paper}
        sx={{ padding: "25px", marginBottom: "30px" }}
      >
        <div className={classes.root}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => checkKeyDown(e)}
          >
            <Grid container spacing={2}>
              <Grid item sm={6} className={classes.about}>
                <CustomInput
                  label="Primary Full Address *"
                  control={control}
                  errors={errors}
                  name="full_address_1"
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.full_address_1?.message}
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <CustomInput
                  label="Full Address 2 *"
                  control={control}
                  errors={errors}
                  name="full_address_2"
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.full_address_2?.message}
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <CustomInput
                  label="Primary Mobile No *"
                  control={control}
                  errors={errors}
                  name="primary_mobile"
                  type="number"
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.primary_mobile?.message}
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <CustomInput
                  label="Mobile No 2 *"
                  control={control}
                  errors={errors}
                  name="secondary_mobile"
                  type="number"
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.secondary_mobile?.message}
                </Typography>
              </Grid>

              <Grid item sm={6}>
                <CustomInput
                  label="Landline No *"
                  control={control}
                  errors={errors}
                  name="landline_no"
                  type="number"
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.landline_no?.message}
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <CustomInput
                  label="Primary Email"
                  control={control}
                  errors={errors}
                  name="primary_email"
                  type="email"
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.primary_email?.message}
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <CustomInput
                  label="Informational Email"
                  control={control}
                  errors={errors}
                  name="informational_email"
                  type="email"
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.informational_email?.message}
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <CustomInput
                  label="Customer Support Email"
                  control={control}
                  errors={errors}
                  name="customer_support_email"
                  type="email"
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.customer_support_email?.message}
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <CustomInput
                  label="Facebook Link *"
                  control={control}
                  errors={errors}
                  name="facebook_link"
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.facebook_link?.message}
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <CustomInput
                  label="Instagram Link *"
                  control={control}
                  errors={errors}
                  name="instagram_link"
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.instagram_link?.message}
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <CustomInput
                  label="Youtube Link *"
                  control={control}
                  errors={errors}
                  name="youtube_link"
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.youtube_link?.message}
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <CustomInput
                  label="Map Iframe *"
                  control={control}
                  errors={errors}
                  name="map_iframe"
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.youtube_link?.message}
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <CustomInput
                  label="Site Title"
                  control={control}
                  errors={errors}
                  name="site_title"
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.site_title?.message}
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <CustomInput
                  label="Meta Description"
                  control={control}
                  errors={errors}
                  name="meta_description"
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.meta_description?.message}
                </Typography>
              </Grid>

              <Grid item sm={6}>
                <CustomInput
                  label="Footer Description"
                  control={control}
                  errors={errors}
                  name="footer_description"
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.footer_description?.message}
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <CustomInput
                  label="Footer Contact Header"
                  control={control}
                  errors={errors}
                  name="footer_contact_header"
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.footer_contact_header?.message}
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <CustomInput
                  label="Footer Contact Description"
                  control={control}
                  errors={errors}
                  name="footer_contact_description"
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.footer_contact_description?.message}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{ marginTop: "30px", borderTop: "3px solid #eee" }}
            >
              <Grid item sm={6}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="uploadAndEdit">
                    <label
                      style={{
                        color: "#828282",
                        fontSize: "14px",
                        paddingBottom: "4px",
                      }}
                    >
                      Upload Company Logo
                    </label>
                  </div>
                  <div
                    onClick={uploadCompanyLogo}
                    style={{
                      height: "180px",
                      width: "180px",
                      background: "#f4f9ff",
                      border: "1px solid #e4e4e4",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        objectFit: "cover",
                      }}
                      src={
                        !logo
                          ? siteData?.siteData[11]?.image
                          : URL?.createObjectURL(logo)
                      }
                      width="100%"
                      height="100%"
                      alt=""
                    />

                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <UploadIcon />
                      Upload
                    </span>
                  </div>
                  <CustomHiddenInput
                    style={{
                      display: "none",
                    }}
                    className="uploadImage"
                    type="file"
                    accept="image/*"
                    control={control}
                    name="company_logo"
                    ref={uploadLogo}
                  />
                  <Typography
                    sx={{ color: "red !important", fontSize: "12px" }}
                  >
                    {errors?.company_logo?.message}
                  </Typography>
                </div>
              </Grid>

              <Grid item sm={6}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="uploadAndEdit">
                    <label
                      style={{
                        color: "#828282",
                        fontSize: "14px",
                        paddingBottom: "4px",
                      }}
                    >
                      Upload Company Favicon
                    </label>
                  </div>
                  <div
                    onClick={uploadCompanyFavicon}
                    style={{
                      height: "180px",
                      width: "180px",
                      background: "#f4f9ff",
                      border: "1px solid #e4e4e4",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        objectFit: "cover",
                      }}
                      src={
                        !favicon
                          ? siteData?.siteData[12]?.image
                          : URL?.createObjectURL(favicon)
                      }
                      width="100%"
                      height="100%"
                      alt=""
                    />
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <UploadIcon />
                      Upload
                    </span>
                  </div>
                  <CustomHiddenInput
                    style={{
                      display: "none",
                    }}
                    className="uploadImage"
                    type="file"
                    accept="image/*"
                    control={control}
                    name="company_favicon"
                    ref={uploadFavicon}
                  />
                  <Typography
                    sx={{ color: "red !important", fontSize: "12px" }}
                  >
                    {errors?.company_favicon?.message}
                  </Typography>
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{
                marginTop: "30px",
                borderTop: "3px solid #eee",
              }}
            >
              <Grid
                item
                sm={6}
                sx={{ marginTop: "20px" }}
                className={classes.descriptionField}
              >
                <Typography
                  sx={{
                    color: "rgba(0, 0, 0, 0.6)",
                    fontWeight: "400",
                    fontSize: "15px",
                    paddingLeft: "8px",
                  }}
                >
                  Contact Section
                </Typography>

                <QuillTextEditor
                  title=""
                  control={control}
                  errors={errors}
                  name="contact_section"
                  setValue={setValue}
                  initialText={textEditorData("contact_section")}
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.description?.message}
                </Typography>
              </Grid>

              <Grid
                item
                sm={6}
                sx={{ marginTop: "20px" }}
                className={classes.descriptionField}
              >
                <Typography
                  sx={{
                    color: "rgba(0, 0, 0, 0.6)",
                    fontWeight: "400",
                    fontSize: "15px",
                    paddingLeft: "8px",
                  }}
                >
                  About Us
                </Typography>

                <QuillTextEditor
                  title=""
                  control={control}
                  errors={errors}
                  name="about_us"
                  setValue={setValue}
                  initialText={textEditorData("about_us")}
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.description?.message}
                </Typography>
              </Grid>

              <Grid
                item
                sm={6}
                sx={{ marginTop: "20px" }}
                className={classes.descriptionField}
              >
                <Typography
                  sx={{
                    color: "rgba(0, 0, 0, 0.6)",
                    fontWeight: "400",
                    fontSize: "15px",
                    paddingLeft: "8px",
                  }}
                >
                  Terms Of Service
                </Typography>
                <QuillTextEditor
                  title=""
                  control={control}
                  errors={errors}
                  name="tos"
                  setValue={setValue}
                  initialText={textEditorData("tos")}
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.description?.message}
                </Typography>
              </Grid>

              <Grid
                item
                sm={6}
                sx={{ marginTop: "20px" }}
                className={classes.descriptionField}
              >
                <Typography
                  sx={{
                    color: "rgba(0, 0, 0, 0.6)",
                    fontWeight: "400",
                    fontSize: "15px",
                    paddingLeft: "8px",
                  }}
                >
                  Privacy Policy
                </Typography>
                <QuillTextEditor
                  title=""
                  control={control}
                  errors={errors}
                  name="privacy_policy"
                  setValue={setValue}
                  initialText={textEditorData("privacy_policy")}
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.description?.message}
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{ marginTop: "20px" }}
                className={classes.descriptionField}
              >
                <Typography
                  sx={{
                    color: "rgba(0, 0, 0, 0.6)",
                    fontWeight: "400",
                    fontSize: "15px",
                    paddingLeft: "8px",
                  }}
                >
                  Referral
                </Typography>

                <QuillTextEditor
                  title=""
                  control={control}
                  errors={errors}
                  name="referral"
                  setValue={setValue}
                  initialText={textEditorData("referral")}
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.description?.message}
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{ marginTop: "20px" }}
                className={classes.descriptionField}
              >
                <Typography
                  sx={{
                    color: "rgba(0, 0, 0, 0.6)",
                    fontWeight: "400",
                    fontSize: "15px",
                    paddingLeft: "8px",
                  }}
                >
                  Account Delete Instructions
                </Typography>
                <QuillTextEditor
                  title=""
                  control={control}
                  errors={errors}
                  name="account_delete"
                  setValue={setValue}
                  initialText={textEditorData("account_delete")}
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.description?.message}
                </Typography>
              </Grid>

              {/* login and register Banner Information */}
              <Grid
                container
                sx={{
                  marginTop: "30px",
                  borderTop: "3px solid #eee",
                }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{ marginTop: "20px", paddingRight: "6px" }}
                  className={classes.descriptionField}
                >
                  <Typography
                    sx={{
                      color: "rgba(0, 0, 0, 0.6)",
                      fontWeight: "400",
                      fontSize: "15px",
                      paddingLeft: "8px",
                    }}
                  >
                    Login Banner
                  </Typography>
                  <Box mt={2} mb={2}>
                    <CustomInput
                      label="Upper Login Banner Text "
                      control={control}
                      errors={errors}
                      name="login_upper_text"
                    />
                  </Box>

                  <Box mt={2} mb={2}>
                    <CustomInput
                      label="Lower Login Banner Text "
                      control={control}
                      errors={errors}
                      name="login_lower_text"
                    />
                  </Box>

                  <Box>
                    <div className="uploadAndEdit">
                      <label
                        style={{
                          color: "#828282",
                          fontSize: "14px",
                          paddingBottom: "4px",
                        }}
                      >
                        Login Background Image
                      </label>
                    </div>

                    <div
                      onClick={uploadLoginBackground}
                      style={{
                        height: "180px",
                        width: "180px",
                        background: "#f4f9ff",
                        border: "1px solid #e4e4e4",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          objectFit: "cover",
                        }}
                        src={
                          !loginBackgroundLogo
                            ? siteData?.siteData[25]?.image
                            : URL?.createObjectURL(loginBackgroundLogo)
                        }
                        width="100%"
                        height="100%"
                        alt=""
                      />

                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <UploadIcon />
                        Upload
                      </span>
                    </div>
                    <CustomHiddenInput
                      style={{
                        display: "none",
                      }}
                      className="uploadImage"
                      type="file"
                      accept="image/*"
                      control={control}
                      name="login_image"
                      ref={uploadLoginBackgroundRef}
                    />
                  </Box>

                  <Typography
                    sx={{ color: "red !important", fontSize: "12px" }}
                  >
                    {errors?.description?.message}
                  </Typography>
                </Grid>

                <Grid
                  item
                  sm={6}
                  sx={{ marginTop: "20px", paddingLeft: "8px" }}
                  className={classes.descriptionField}
                >
                  <Typography
                    sx={{
                      color: "rgba(0, 0, 0, 0.6)",
                      fontWeight: "400",
                      fontSize: "15px",
                      paddingLeft: "8px",
                    }}
                  >
                    Register Banner
                  </Typography>
                  <Box mt={2} mb={2}>
                    <CustomInput
                      label="Upper Register Banner Text "
                      control={control}
                      errors={errors}
                      name="register_upper_text"
                    />
                  </Box>

                  <Box mt={2} mb={2}>
                    <CustomInput
                      label="Lower Register Banner Text "
                      control={control}
                      errors={errors}
                      name="register_lower_text"
                    />
                  </Box>

                  <Box>
                    <div className="uploadAndEdit">
                      <label
                        style={{
                          color: "#828282",
                          fontSize: "14px",
                          paddingBottom: "4px",
                        }}
                      >
                        Register Background Image
                      </label>
                    </div>

                    <div
                      onClick={uploadRegisterBackground}
                      style={{
                        height: "180px",
                        width: "180px",
                        background: "#f4f9ff",
                        border: "1px solid #e4e4e4",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          objectFit: "cover",
                        }}
                        src={
                          !RegisterBackgroundLogo
                            ? siteData?.siteData[28]?.image
                            : URL?.createObjectURL(RegisterBackgroundLogo)
                        }
                        width="100%"
                        height="100%"
                        alt=""
                      />

                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <UploadIcon />
                        Upload
                      </span>
                    </div>
                    <CustomHiddenInput
                      style={{
                        display: "none",
                      }}
                      className="uploadImage"
                      type="file"
                      accept="image/*"
                      control={control}
                      name="register_image"
                      ref={uploadRegisterBackgroundRef}
                    />
                  </Box>

                  <Typography
                    sx={{ color: "red !important", fontSize: "12px" }}
                  >
                    {errors?.description?.message}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item sm={2}>
                <Box
                  marginTop={2.5}
                  marginLeft={0}
                  sx={{ margiLeft: "0px", marginTop: "0px" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                  >
                    Post
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </div>
      </Paper>
    </>
  );
};

export default SiteSetting;
