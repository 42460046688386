import {
  Box,
  Button,
  Modal,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { postBusinessStatus } from '../../business/redux/Action';
import ReviewStar from '../../ReviewStar';
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0),
  },
  tableRoot: {
    width: '100%',
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    '& .MuiTableCell-stickyHeader': {
      background: theme.palette.background.dark,
      //   color: theme.palette.primary.dark,
    },
    '& .MuiTableBody-root': {
      '& tr:nth-of-type(odd)': {
        background: theme.palette.background.main,
      },
    },
  },
  delete: {
    color: '#ff6363',
    cursor: 'pointer',
    '&:hover': {
      color: 'red',
    },
  },
  container: {
    maxHeight: 550,
    borderRadius: '5px',
  },
  button: {
    '& .button:hover': {
      background: 'red',
    },
  },
}));
const columns = [
  {
    id: 'description',
    label: 'Description',
    minWidth: 50,
  },
  {
    id: 'created_at',
    label: 'Created At',
    minWidth: 80,
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 50,
  },
  {
    id: 'star',
    label: 'Star',
    minWidth: 80,
  },
];

const CustomBusinessRow = ({ index, columns, row }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  console.log('columns', columns, row);
  const getSwitchValue = (e, value, slug) => {
    dispatch(
      postBusinessStatus(slug, {
        _method: 'patch',
        value: value === true ? 1 : 0,
      })
    );
  };
  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
        <TableCell>{index + 1}</TableCell>
        {columns.map((column, ind) => {
          const value = row[column.id];
          return columns?.length !== ind + 1 && column.id === 'review' ? (
            <TableCell
              key={column.id}
              align={column.align}
              onClick={() => setOpen(true)}>
              <div className={classes.button}>
                <Button
                  className="button"
                  sx={{ background: 'hsl(348deg 83% 47%)', color: '#fff' }}>
                  Review{`(${row?.review?.length})`}
                </Button>
              </div>
            </TableCell>
          ) : column.id !== 'action' ? (
            <TableCell key={column.id} align={column.align}>
              {value}
            </TableCell>
          ) : (
            <TableCell
              key={column.id}
              align={column.align}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Tooltip arrow title={`Change Status`}>
                <Switch
                  defaultChecked={row?.status?.title === 'active' ? 1 : 0}
                  inputProps={{
                    'aria-label': 'ant design',
                  }}
                  onChange={(e, value) => getSwitchValue(e, value, row?.slug)}
                />
              </Tooltip>
            </TableCell>
          );
        })}
      </TableRow>
      <CustomModal open={open} handleClose={handleClose} review={row?.review} />
    </>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '5px',
  p: 3,
};

const CustomModal = ({ open, handleClose, review }) => {
  const classes = useStyles();
  const updatedReview = review.map((data) => {
    return { ...data, email: data?.reviewer?.email };
  });
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Paper elevation={2}>
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ paddingBottom: '20px' }}>
            Business
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Paper className={classes.tableRoot}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>S.No.</TableCell>
                      {columns.map((column) => (
                        <TableCell
                          sx={{ fontWeight: 'bold' }}
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {updatedReview != ''
                      ? (updatedReview || [])
                          //   .slice(
                          //     page * rowsPerPage,
                          //     page * rowsPerPage + rowsPerPage
                          //   )
                          .map((row, index) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.code}>
                                <TableCell>{index + 1}</TableCell>
                                {columns.map((column, ind) => {
                                  const value = row[column.id];
                                  return column.id === 'star' ? (
                                    <TableCell>
                                      <ReviewStar
                                        rating={row?.star}
                                        small={true}
                                      />
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}>
                                      {value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })
                      : false}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </Box>
      </Paper>
    </Modal>
  );
};

export default CustomBusinessRow;
