export const BUSINESS_POST_BEGIN = "BUSINESS_POST_BEGIN";
export const BUSINESS_POST_SUCCESS = "BUSINESS_POST_SUCCESS";
export const BUSINESS_POST_FAIL = "BUSINESS_POST_FAIL";

export const GET_BUSINESS_BEGIN = "GET_BUSINESS_BEGIN";
export const GET_BUSINESS_SUCCESS = "GET_BUSINESS_SUCCESS";
export const GET_BUSINESS_FAIL = "GET_BUSINESS_FAIL";

export const DELETE_BUSINESS_BEGIN = "DELETE_BUSINESS_BEGIN";
export const DELETE_BUSINESS_SUCCESS = "DELETE_BUSINESS_SUCCESS";
export const DELETE_BUSINESS_FAIL = "DELETE_BUSINESS_FAIL";

export const GET_BUSINESS_UPDATE_BEGIN = "GET_BUSINESS_UPDATE_BEGIN";
export const GET_BUSINESS_UPDATE_SUCCESS = "GET_BUSINESS_UPDATE_SUCCESS";
export const GET_BUSINESS_UPDATE_FAIL = "GET_BUSINESS_UPDATE_FAIL";

export const PUT_BUSINESS_UPDATE_BEGIN = "PUT_BUSINESS_UPDATE_BEGIN";
export const PUT_BUSINESS_UPDATE_SUCCESS = "PUT_BUSINESS_UPDATE_SUCCESS";
export const PUT_BUSINESS_UPDATE_FAIL = "PUT_BUSINESS_UPDATE_FAIL";

export const GET_ALL_BUSINESS_BEGIN = "GET_ALL_BUSINESS_BEGIN";
export const GET_ALL_BUSINESS_SUCCESS = "GET_ALL_BUSINESS_SUCCESS";
export const GET_ALL_BUSINESS_FAIL = "GET_ALL_BUSINESS_FAIL";

export const POST_BUSINESS_STATUS_BEGIN = "POST_BUSINESS_STATUS_BEGIN";
export const POST_BUSINESS_STATUS_SUCCESS = "POST_BUSINESS_STATUS_SUCCESS";
export const POST_BUSINESS_STATUS_FAIL = "POST_BUSINESS_STATUS_FAIL";

export const CLEAN_BUSINESS_CATEGORY_UPDATE = "CLEAN_BUSINESS_CATEGORY_UPDATE";

export const BUSINESS_LIST_DELETE_BEGIN = "BUSINESS_LIST_DELETE_BEGIN";
export const BUSINESS_LIST_DELETE_SUCCESS = "BUSINESS_LIST_DELETE_SUCCESS";
export const BUSINESS_LIST_DELETE_FAIL = "BUSINESS_LIST_DELETE_FAIL";
