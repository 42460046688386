import { axiosInstance } from "../../utils/axiosInstance";
import { handleError, handleResponse } from "../apiResponse";

// sale category
export function postSaleCategoryApi(data) {
  return axiosInstance()
    .post("/admin/sale-category", data)
    .then(handleResponse)
    .catch(handleError);
}

export function getSaleCategoryBySlugApi(slug) {
  return axiosInstance().get(`/admin/sale-category/${slug}`).catch(handleError);
}

export function updateSaleCategoryApi(slug, data) {
  return axiosInstance()
    .post(`/admin/sale-category/${slug}`, data)
    .then(handleResponse)
    .catch(handleError);
}

export function deleteSaleCategoryApi(slug) {
  return axiosInstance()
    .delete(`/admin/sale-category/${slug}`)
    .then(handleResponse)
    .catch(handleError);
}

export function getSaleCategoryApi(page, rowsPerPage) {
  return axiosInstance()
    .get("/admin/sale-category", {
      params: {
        page: page + 1,
        limit: rowsPerPage,
      },
    })
    .catch(handleError);
}

// sale features
export function postSaleFeatureApi(data) {
  return axiosInstance()
    .post("/admin/sale-feature", data)
    .then(handleResponse)
    .catch(handleError);
}

export function getSaleFeatureApi() {
  return axiosInstance().get("/admin/sale-feature").catch(handleError);
}

export function getSingleSaleFeatureApi(id) {
  return axiosInstance().get(`/admin/sale-feature/${id}`).catch(handleError);
}

export function updateSaleFeatureApi(id, data) {
  return axiosInstance()
    .post(`/admin/sale-feature/${id}`, data)
    .then(handleResponse)
    .catch(handleError);
}

export function deleteSaleFeatureApi(id) {
  return axiosInstance()
    .delete(`/admin/sale-feature/${id}`)
    .then(handleResponse)
    .catch(handleError);
}

//For all Sale lists

export function getAllSaleApi(page, rows) {
  const limit = rows ? `&limit=${rows}` : "";
  const active = rows ? `?page=${page + 1}` : "";
  return axiosInstance().get(`/admin/sale${active}${limit}`).catch(handleError);
}

export function updateSaleStatusApi(slug, data) {
  return axiosInstance()
    .post(`/admin/sale/${slug}/change-active`, data)
    .then(handleResponse)
    .catch(handleError);
}

export function deleteSaleListApi(house_category_id) {
  return axiosInstance()
    .delete(`/admin/sale/${house_category_id}`)
    .then(handleResponse)
    .catch(handleError);
}
