import React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getAllJob } from './redux/Action'

const SingleJobPage = () => {
    const { id } = useParams()

    const dispatch = useDispatch()
    const allJobs = useSelector((state) => state?.jobs)


    const singleJob = allJobs?.allJobs?.filter((item) => {
        return (
            item?.slug === id
        )
    })


    useEffect(() => {
        dispatch(getAllJob())
    }, [])

    return (
        <div>{singleJob?.[0]?.title}</div>
    )
}

export default SingleJobPage