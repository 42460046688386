import React from 'react'
import { useParams } from 'react-router-dom'
import CategoryJob from './CategoryJob';
import ListJob from './ListJob';
import PageNotFound from '../../pages/PageNotFound/Index';


const JobPage = () => {

    const { jobId } = useParams()
    return (
        <>

            {
                jobId == 'category' || !jobId ?
                    <CategoryJob />
                    :
                    jobId === 'lists' ?
                        <ListJob />
                        :
                        <PageNotFound />

            }
        </>
    )
}

export default JobPage