import { Box, Button, CircularProgress, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { CustomInput } from '../common/customInput/CustomInput';
import { CustomSelect } from '../common/customSelect/CustomSelect';
import { CustomHiddenInput } from '../common/customInput/CustomHiddenInput';
import CustomTextarea from '../common/customTextarea/CustomTextarea';
import { Link } from 'react-router-dom';
import InsideHeader from '../../layout/InsideHeader';
import { Breadcrumbs, Typography } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import ViewBusiness from './ViewBusiness';
import Tags from '../rental/Tags';
import { serializer } from '../../utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import { postBusiness, putBusinessUpdate } from './redux/Action';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import * as actions from './redux/Type';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),

    '& .uploadAndEdit': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },

  descriptionField: {
    '& .MuiOutlinedInput-root': {
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
    },
  },

  delete: {
    backgroundColor: '#ff6363 !important',
    color: 'white',
    cursor: 'pointer',
    marginLeft: '10px',
    fontSize: '14px !important',
    '&:hover': {
      backgroundColor: '#DB143C !important',
    },
  },
}));

function CategoryBusiness() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const updateBusiness = useSelector((state) => state?.businesses);

  const [toggle, setToggle] = useState(false);
  const [buttonName, setButtonName] = useState('Add');

  const options = [
    {
      label: 'Active',
      value: 1,
    },
    {
      label: 'Inactive',
      value: 0,
    },
  ];
  const defaultValues = {
    title: '',
    description: '',
    icon: '',
    order_level: '',
    status_id: 1,
    meta_title: '',
    meta_description: '',
  };

  const schema = yup
    .object({
      title: yup.string().required('Title is required'),
      description: yup.string().required('Description is required'),
      icon: yup.mixed().required('Icon is required'),
      order_level: yup
        .number()
        .typeError('Order Level is required')
        .min(0, 'Must be greater than or equal to 0')
        .required(),
      meta_title: yup.string().required('Meta title is required'),
      meta_description: yup.string().required('Meta description is required'),
    })
    .required();

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
    setError,
  } = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema) });

  const upload = useRef(null);
  const image = watch('icon');

  const uploadImage = () => {
    upload.current.click();
  };

  const handleError = (errors) => {
    console.log(errors, 'errors');
    Object.entries(errors)?.map((item) => {
      console.log('error', item);
      setError(item[0], { message: item[1] });
    });
  };

  useEffect(() => {
    if (Object.keys(updateBusiness?.updateBusiness).length) {
      setValue('title', updateBusiness?.updateBusiness?.data?.title);
      setValue(
        'description',
        updateBusiness?.updateBusiness?.data?.description
      );
      setValue('status_id', updateBusiness?.updateBusiness?.data?.status?.id);
      setValue('meta_title', updateBusiness?.updateBusiness?.data?.slug);
      setValue(
        'order_level',
        updateBusiness?.updateBusiness?.data?.order_level
      );
      setValue(
        'meta_description',
        updateBusiness?.updateBusiness?.data?.meta_description
      );
    }
  }, [updateBusiness, setValue]);

  const addTags = (data) => {
    setValue('meta_keyword', data);
  };

  const handleToggle = () => {
    dispatch({ type: actions.CLEAN_BUSINESS_CATEGORY_UPDATE });
    setButtonName('Add');
    setToggle((prev) => !prev);
    reset();
  };

  const handleCancelButton = () => {
    setToggle((prev) => !prev);
    dispatch({ type: actions.CLEAN_BUSINESS_CATEGORY_UPDATE });
  };

  const onSubmit = (values, e) => {
    const formData = new FormData();

    if (updateBusiness?.updateBusiness?.data?.slug) {
      serializer(formData, { ...values, _method: 'patch' });
      dispatch(
        putBusinessUpdate(
          updateBusiness?.updateBusiness?.data?.slug,
          formData,
          handleToggle,
          reset,
          handleError
        )
      );
    } else {
      serializer(formData, values);
      dispatch(postBusiness(formData, handleToggle, reset, handleError));
    }
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          background: '#f8f8f8',
          marginBottom: '15px',
        }}>
        <InsideHeader title="Business">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Business
            </Link>
            <Typography color="textPrimary">Category</Typography>
          </Breadcrumbs>
        </InsideHeader>
        <Button
          sx={{ height: '40px' }}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleToggle}>
          Add Business
        </Button>
      </Paper>
      {toggle && (
        <Paper
          elevation={1}
          className={classes.paper}
          sx={{ padding: '25px', marginBottom: '30px' }}>
          <div className={classes.root}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => checkKeyDown(e)}>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <CustomInput
                    label="Title"
                    control={control}
                    errors={errors}
                    name="title"
                  />
                  <Typography
                    sx={{ color: 'red !important', fontSize: '12px' }}>
                    {errors?.title?.message}
                  </Typography>
                </Grid>
                <Grid
                  item
                  sm={12}
                  sx={{ marginTop: '20px' }}
                  className={classes.descriptionField}>
                  <CustomTextarea
                    label="Description"
                    control={control}
                    errors={errors}
                    name="description"
                  />
                  <Typography
                    sx={{ color: 'red !important', fontSize: '12px' }}>
                    {errors?.description?.message}
                  </Typography>
                </Grid>
                <Grid item sm={2} sx={{ paddingTop: '8px !important' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <div className="uploadAndEdit">
                      <label style={{ color: '#828282', fontSize: '14px' }}>
                        Upload Icon
                      </label>
                    </div>
                    <div
                      onClick={uploadImage}
                      style={{
                        height: '50px',
                        width: '50px',
                        background: '#f4f9ff',
                        border: '1px solid #e4e4e4',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        cursor: 'pointer',
                      }}>
                      <img
                        style={{ position: 'absolute', top: '0', left: '0' }}
                        src={
                          !image
                            ? updateBusiness?.updateBusiness?.data?.icon
                            : URL?.createObjectURL(image)
                        }
                        width="100%"
                        height="100%"
                        alt=""
                      />
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={uploadImage}>
                        <UploadIcon />
                      </span>
                    </div>
                    <CustomHiddenInput
                      style={{
                        display: 'none',
                      }}
                      className="uploadImage"
                      type="file"
                      accept="image/*"
                      control={control}
                      name="icon"
                      ref={upload}
                    />
                    <Typography
                      sx={{ color: 'red !important', fontSize: '12px' }}>
                      {errors?.icon?.message}
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={5}>
                  <CustomInput
                    label="Order Level"
                    control={control}
                    errors={errors}
                    name="order_level"
                    type="number"
                  />
                  <Typography
                    sx={{ color: 'red !important', fontSize: '12px' }}>
                    {errors?.order_level?.message}
                  </Typography>
                </Grid>
                <Grid item sm={5}>
                  <CustomSelect
                    label="Status"
                    control={control}
                    errors={errors}
                    name="status_id"
                    options={options}
                  />
                </Grid>

                <Grid
                  container
                  spacing={2}
                  sx={{
                    paddingTop: '15px !important',
                    paddingLeft: '0px !important',
                    marginTop: '40px',
                    marginLeft: '16px',

                    borderTop: '1px solid #cfddea',
                  }}>
                  <Grid item sm={12} sx={{ paddingLeft: '0px !important' }}>
                    <Typography
                      variant="h6"
                      noWrap
                      component="div"
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                        paddingBottom: '15px',
                        fontSize: '25px',
                      }}>
                      SEO
                    </Typography>
                  </Grid>

                  <Grid item sm={6} sx={{ paddingLeft: '0px !important' }}>
                    <CustomInput
                      label="Meta title"
                      control={control}
                      errors={errors}
                      name="meta_title"
                    />
                    <Typography
                      sx={{ color: 'red !important', fontSize: '12px' }}>
                      {errors?.meta_title?.message}
                    </Typography>
                  </Grid>

                  <Grid item sm={6} sx={{ paddingTop: '6px !important' }}>
                    <Tags
                      addTags={addTags}
                      errors={errors?.meta_key?.message}
                      metaData={
                        updateBusiness?.updateBusiness?.data?.meta_keyword
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    sm={6}
                    sx={{ paddingLeft: '0px !important' }}
                    className={classes.descriptionField}>
                    <CustomTextarea
                      label="Meta Description"
                      control={control}
                      errors={errors}
                      name="meta_description"
                    />
                    <Typography
                      sx={{ color: 'red !important', fontSize: '12px' }}>
                      {errors?.meta_description?.message}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={2}>
                  {buttonName === 'Add' ? (
                    <Box
                      marginTop={2.5}
                      marginLeft={0}
                      sx={{
                        margiLeft: '0px',
                        marginTop: '0px',
                        display: 'flex',
                      }}>
                      <Button
                        sx={{ marginRight: '10px', fontSize: '14px' }}
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit">
                        {!updateBusiness?.isLoading ? (
                          `${buttonName}`
                        ) : (
                          <CircularProgress size={30} sx={{ color: 'white' }} />
                        )}
                      </Button>
                      <Button
                        className={classes.delete}
                        variant="contained"
                        fullWidth
                        size="large"
                        onClick={() => setToggle((prev) => !prev)}>
                        Cancel
                      </Button>
                    </Box>
                  ) : (
                    <Box
                      marginTop={2.5}
                      marginLeft={0}
                      sx={{
                        margiLeft: '0px',
                        marginTop: '0px',
                        display: 'flex',
                      }}>
                      <Button
                        sx={{ marginRight: '10px', fontSize: '14px' }}
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit">
                        {!updateBusiness?.putUpdateLoading ? (
                          `${buttonName}`
                        ) : (
                          <CircularProgress size={30} sx={{ color: 'white' }} />
                        )}
                      </Button>
                      <Button
                        className={classes.delete}
                        variant="contained"
                        fullWidth
                        size="large"
                        onClick={handleCancelButton}>
                        Cancel
                      </Button>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </form>
          </div>
        </Paper>
      )}
      <ViewBusiness setToggle={setToggle} setButtonName={setButtonName} />
    </>
  );
}

export default CategoryBusiness;
