import React from "react";
import { Switch, TableCell, TableRow, Tooltip } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { postBlogStatus } from "./redux/Action";

const CustomTableRow = ({
  index,
  columns,
  row,
  handleEdit,
  handleModal,
  editbtn,
  deletebtn,
}) => {
  const dispatch = useDispatch();

  const getSwitchValue = (e, value, slug) => {
    dispatch(
      postBlogStatus(slug, { _method: "patch", value: value === true ? 1 : 0 })
    );
  };

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
      <TableCell>{index + 1}</TableCell>
      {columns.map((column, ind) => {
        if (column.id === "status") {
          return (
            <TableCell key={column.id} align={column.align}>
              {row[column.id]?.title}
            </TableCell>
          );
        }
        const value = row[column.id];
        return columns?.length !== ind + 1 && column.id !== "owner" ? (
          <TableCell key={column.id} align={column.align}>
            {value}
          </TableCell>
        ) : column.id === "owner" ? (
          <TableCell align={column.align} key={column.id}>
            {value?.name}
          </TableCell>
        ) : (
          <TableCell
            align={column.align}
            key={column.id}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Tooltip arrow title={`${row?.status?.title}`}>
              <Switch
                defaultChecked={row?.status?.title === "active" ? 1 : 0}
                inputProps={{ "aria-label": "ant design" }}
                onChange={(e, value) => getSwitchValue(e, value, row?.slug)}
              />
            </Tooltip>
            <Tooltip arrow title="Edit">
              <span>
                <Link to={`${row?.slug}`}>
                  <EditIcon
                    className={editbtn}
                    onClick={() => handleEdit(row?.slug)}
                  />
                </Link>
              </span>
            </Tooltip>
            <Tooltip arrow title="Delete">
              <span>
                <DeleteIcon
                  className={deletebtn}
                  onClick={() => handleModal(row?.slug)}
                />
              </span>
            </Tooltip>

            <a href={`https://nepalina.com/blogdetails/${row?.slug}`}>
              <Tooltip arrow title="View details">
                <RemoveRedEyeIcon
                  sx={{ cursor: "pointer", color: "#6d9ecd" }}
                />
              </Tooltip>
            </a>
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default CustomTableRow;
