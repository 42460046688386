export const POST_SITE_SETTINGS_BEGIN = "POST_SITE_SETTINGS_BEGIN";
export const POST_SITE_SETTINGS_SUCCESS = "POST_SITE_SETTINGS_SUCCESS";
export const POST_SITE_SETTINGS_FAIL = "POST_SITE_SETTINGS_FAIL";

export const POST_CHANGE_PASSWORD_BEGIN = "POST_CHANGE_PASSWORD_BEGIN";
export const POST_CHANGE_PASSWORD_SUCCESS = "POST_CHANGE_PASSWORD_SUCCESS";
export const POST_CHANGE_PASSWORD_FAIL = "POST_CHANGE_PASSWORD_FAIL";

export const GET_SITE_SETTINGS_BEGIN = "GET_SITE_SETTINGS_BEGIN";
export const GET_SITE_SETTINGS_SUCCESS = "GET_SITE_SETTINGS_SUCCESS";
export const GET_SITE_SETTINGS_FAIL = "GET_SITE_SETTINGS_FAIL";

// --------------------------------------------------------------------

export const POST_FALLBACK_IMAGE_BEGIN = "POST_FALLBACK_IMAGE_BEGIN";
export const POST_FALLBACK_IMAGE_SUCCESS = "POST_FALLBACK_IMAGE_SUCCESS";
export const POST_FALLBACK_IMAGE_FAIL = "POST_FALLBACK_IMAGE_FAIL";

export const GET_FALLBACK_IMAGE_BEGIN = "GET_FALLBACK_IMAGE_BEGIN";
export const GET_FALLBACK_IMAGE_SUCCESS = "GET_FALLBACK_IMAGE_SUCCESS";
export const GET_FALLBACK_IMAGE_FAIL = "GET_FALLBACK_IMAGE_FAIL";

export const GET_FALLBACK_IMAGE_BY_KEY_BEGIN =
  "GET_FALLBACK_IMAGE_BY_KEY_BEGIN";
export const GET_FALLBACK_IMAGE_BY_KEY_SUCCESS =
  "GET_FALLBACK_IMAGE_BY_KEY_SUCCESS";
export const GET_FALLBACK_IMAGE_BY_KEY_FAIL = "GET_FALLBACK_IMAGE_BY_KEY_FAIL";

export const DELETE_FALLBACK_IMAGE_BEGIN = "DELETE_FALLBACK_IMAGE_BEGIN";
export const DELETE_FALLBACK_IMAGE_SUCCESS = "DELETE_FALLBACK_IMAGE_SUCCESS";
export const DELETE_FALLBACK_IMAGE_FAIL = "DELETE_FALLBACK_IMAGEFAILN";

///////////////////////////////////////////////////////////////////////////////////

export const GET_BANNER_BEGIN = "GET_BANNER_BEGIN";
export const GET_BANNER_SUCCESS = "GET_BANNER_SUCCESS";
export const GET_BANNER_FAIL = "GET_BANNER_FAIL";

export const POST_BANNER_BEGIN = "POST_BANNER_BEGIN";
export const POST_BANNER_SUCCESS = "POST_BANNER_SUCCESS";
export const POST_BANNER_FAIL = "POST_BANNER_FAIL";

export const DELETE_BANNER_BEGIN = "DELETE_BANNER_BEGIN";
export const DELETE_BANNER_SUCCESS = "DELETE_BANNER_SUCCESS";
export const DELETE_BANNER_FAIL = "DELETE_BANNER_FAIL";

export const GET_LOOKIN_FOR_BEGIN = "GET_LOOKIN_FOR_BEGIN";
export const GET_LOOKIN_FOR_SUCCESS = "GET_LOOKIN_FOR_SUCCESS";
export const GET_LOOKIN_FOR_FAIL = "GET_LOOKIN_FOR_FAIL";

export const POST_LOOKING_FOR_BEGIN = "POST_LOOKING_FOR_BEGIN";
export const POST_LOOKING_FOR_SUCCESS = "POST_LOOKING_FOR_SUCCESS";
export const POST_LOOKING_FOR_FAIL = "POST_LOOKING_FOR_FAIL";
export const RESET_LOOKING_POST_STATUS = "RESET_LOOKING_POST_STATUS";

export const DELETE_LOOKING_FOR_BEGIN = "DELETE_LOOKING_FOR_BEGIN";
export const DELETE_LOOKING_FOR_SUCCESS = "DELETE_LOOKING_FOR_SUCCESS";
export const DELETE_LOOKING_FOR_FAIL = "DELETE_LOOKING_FOR_FAIL";
