import {
  deleteBusinessReviewsApi,
  getBusinessReviewsApi,
} from "../../../api/userReviews/UserReviewsApi";
import * as actions from "./Type";

export const getBusinessReviews = () => (dispatch) => {
  dispatch({ type: actions.GET_USER_BUSINESS_REVIEWS_BEGIN });
  getBusinessReviewsApi()
    .then((res) => {
      dispatch({
        type: actions.GET_USER_BUSINESS_REVIEWS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_USER_BUSINESS_REVIEWS_FAIL,
      });
    });
};

export const deleteBusinessReviews = (id, handleClose) => (dispatch) => {
  dispatch({ type: actions.DELETE_REVIEW_BEGIN });
  deleteBusinessReviewsApi(id)
    .then((res) => {
      dispatch({
        type: actions.DELETE_REVIEW_SUCCESS,
        payload: res.id,
      });
      dispatch(getBusinessReviews());
      handleClose && handleClose();
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.DELETE_REVIEW_FAIL,
      });
    });
};
