import * as actions from "./Type";

const initialState = {
  userLoading: false,
  statusLoading: false,
  users: [],
  individualUser: [],
  individualUserLoading: false,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_USERS_BEGIN:
      return {
        ...state,
        userLoading: true,
      };

    case actions.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        userLoading: false,
      };

    case actions.GET_USERS_FAIL:
      return {
        ...state,
        userLoading: true,
      };

    case actions.POST_USER_STATUS_BEGIN:
      return {
        ...state,
        statusLoading: true,
      };

    case actions.POST_USER_STATUS_SUCCESS:
      return {
        ...state,
        statusLoading: false,
      };

    case actions.POST_USER_STATUS_FAIL:
      return {
        ...state,
        statusLoading: true,
      };

    case actions.GET_USER_CLIENT_LIST_BEGIN:
      return {
        ...state,
        individualUserLoading: true,
      };

    case actions.GET_USER_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        individualUserLoading: false,
        individualUser: action.payload,
      };

    case actions.GET_USER_CLIENT_LIST_FAIL:
      return {
        ...state,
        individualUserLoading: true,
      };

    default:
      return state;
  }
};
