import {
  Box,
  Button,
  Modal,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { postJobStatus } from "../../job/redux/Action";
import { postRentalStatus } from "../../rental/redux/Action";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0),
  },
  tableRoot: {
    width: "100%",
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    "& .MuiTableCell-stickyHeader": {
      background: theme.palette.background.dark,
      //   color: theme.palette.primary.dark,
    },
    "& .MuiTableBody-root": {
      "& tr:nth-of-type(odd)": {
        background: theme.palette.background.main,
      },
    },
  },
  delete: {
    color: "#ff6363",
    cursor: "pointer",
    "&:hover": {
      color: "red",
    },
  },
  container: {
    maxHeight: 550,
    borderRadius: "5px",
  },
  button: {
    "& .button:hover": {
      background: "red",
    },
  },
}));
const columns = [
  {
    id: "fullname",
    label: "Name",
    minWidth: 50,
  },

  {
    id: "contact_number",
    label: "Phone Number",
    minWidth: 50,
  },
  {
    id: "resume",
    label: "Resume",
    minWidth: 50,
  },
];

const CustomJobRow = ({ index, columns, row, type }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  console.log("columns", columns, row);
  const getSwitchValue = (e, value, slug) => {
    type === "job" &&
      dispatch(
        postJobStatus(slug, { _method: "patch", value: value === true ? 1 : 0 })
      );
    type === "house" &&
      dispatch(
        postRentalStatus(slug, {
          _method: "patch",
          value: value === true ? 1 : 0,
        })
      );
  };
  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
        <TableCell>{index + 1}</TableCell>
        {columns.map((column, ind) => {
          const value = row[column.id];
          return columns?.length !== ind + 1 && column.id === "inquiry" ? (
            <TableCell
              key={column.id}
              align={column.align}
              onClick={() => setOpen(true)}
            >
              <div className={classes.button}>
                <Button
                  className="button"
                  sx={{ background: "hsl(348deg 83% 47%)", color: "#fff" }}
                >
                  Inquiry{`(${row?.inquiry?.length})`}
                </Button>
              </div>
            </TableCell>
          ) : column.id === "active" ? (
            <TableCell align={column.align} key={column.id}>
              {row?.active?.title}
            </TableCell>
          ) : column.id !== "action" ? (
            <TableCell key={column.id} align={column.align}>
              {value}
            </TableCell>
          ) : (
            <TableCell
              key={column.id}
              align={column.align}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Tooltip arrow title={`Change Status`}>
                <Switch
                  defaultChecked={row?.status?.title === "active" ? 1 : 0}
                  inputProps={{
                    "aria-label": "ant design",
                  }}
                  onChange={(e, value) => getSwitchValue(e, value, row?.slug)}
                  //   onChange={(e, value) =>
                  //     getSwitchValue(
                  //       e,
                  //       value,
                  //       row?.id
                  //     )
                  //   }
                />
              </Tooltip>
              {/* <a
            href={`${frontUrl}/user/house/${row?.profile?.slug}`}
            target="_blank"
            rel="noreferrer"
          > */}
              {/* <Tooltip arrow title="View profile">
                <RemoveRedEyeIcon
                  sx={{
                    cursor: "pointer",
                    color: "#6d9ecd",
                    marginLeft: "20px",
                  }}
                />
              </Tooltip> */}
              {/* </a> */}
            </TableCell>
          );
        })}
      </TableRow>
      <CustomModal
        open={open}
        handleClose={handleClose}
        inquiry={row?.inquiry}
      />
    </>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  p: 3,
};

const CustomModal = ({ open, handleClose, inquiry }) => {
  const classes = useStyles();
  const clonedInquiry = inquiry?.map((data) => {
    return { ...data, resume: data?.resume?.original_url };
  });
  console.log(clonedInquiry);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper elevation={2}>
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ paddingBottom: "20px" }}
          >
            Jobs
          </Typography>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Paper className={classes.tableRoot}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>S.No.</TableCell>
                      {columns.map((column) => (
                        <TableCell
                          sx={{ fontWeight: "bold" }}
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {clonedInquiry != ""
                      ? (clonedInquiry || [])
                          //   .slice(
                          //     page * rowsPerPage,
                          //     page * rowsPerPage + rowsPerPage
                          //   )
                          .map((row, index) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.code}
                              >
                                <TableCell>{index + 1}</TableCell>
                                {columns.map((column, ind) => {
                                  const value = row[column.id];
                                  return column?.id === "resume" ? (
                                    <TableCell>
                                      <a
                                        href={row?.resume}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <i
                                          className="bi bi-file-earmark"
                                          style={{ marginRight: "8px" }}
                                        ></i>
                                        Resume.pdf
                                      </a>
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })
                      : false}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </Box>
      </Paper>
    </Modal>
  );
};

export default CustomJobRow;
