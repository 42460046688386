import React from "react";
import ViewUsers from "./ViewUsers";

const UserPage = () => {
  return (
    <>
      <ViewUsers />
    </>
  );
};

export default UserPage;
