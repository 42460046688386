import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { formatDate } from "../../utils/TimestamptoDate";
import { Box } from "@mui/material";
import { logout } from "../../components/auth/redux/Auth.action";
import { unstable_HistoryRouter } from "react-router-dom";
import { getFallbackImage } from "../../components/setting/redux/Action";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    color: "#848484",
    fontWeight: "lighter",
    boxShadow: "0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: `${theme.palette.primary.main} !important`,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    color: theme.palette.primary.main,
    fontSize: "24px",
    fontWeight: "lighter",
    minWidth: "150px",
  },
  logout: {
    color: `${theme.palette.primary.main} !important`,
  },

  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    "& .MuiIconButton-root": {
      padding: `${theme.spacing(0)}px ${theme.spacing(2.5)}px`,
      "&:hover": {
        backgroundColor: "transparent",
        color: "#8f88cc",
      },
    },

    fontSize: "16px",
    "& .MuiSvgIcon-root": {
      fontSize: "22px",
    },
  },

  profileIcon: {
    "&  .MuiSvgIcon-root": {
      fontSize: "40px",
      borderRadius: "50%",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function Navbar({ setOpen, open }) {
  const classes = useStyles(open);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const handleHistory = () => {
    navigate("/");
  };

  const handleLogout = () => {
    dispatch(logout(handleHistory));
  };

  useEffect(() => {
    dispatch(getFallbackImage());
  }, []);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };
  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        boxShadow: "0px 2px 10px #d7d7d7",
      }}
    >
      <Toolbar sx={{ background: "white" }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <img
              src="/images/nepalinalogo.png"
              style={{ height: "60px", width: "70px" }}
            />
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ color: (theme) => theme.palette.primary.main }}
            >
              Dashboard
            </Typography>
          </Box>
          <Box
            display="flex"
            sx={{
              color: (theme) => theme.palette.primary.main,
              cursor: "pointer",
            }}
          >
            <ExitToAppIcon />
            <Typography onClick={handleLogout}> Logout</Typography>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
