/* Third party libraries */
import { useSelector } from 'react-redux';

const HasPermission = ({ children, of, arrayOf = [] }) => {
  const { singleAdminPermission } = useSelector(
    (state) => state.rolePermission
  );

  const permissionList = singleAdminPermission?.map((data) => data?.name);
  if (permissionList?.includes(of)) {
    return children;
  }

  return false;
};

export default HasPermission;
