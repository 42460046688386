import { returnErrors } from "../../redux/Error/Error.action";
import { axiosInstance } from "../../utils/axiosInstance";
import { handleError, handleResponse } from "../apiResponse";

export function postBusinessApi(data) {
  return axiosInstance()
    .post("admin/business-category", data)
    .then(handleResponse);
  // .catch(handleError);
}

export function getBusinessApi(page, rowsPerPage) {
  return axiosInstance()
    .get("admin/business-category", {
      params: { page: page + 1, limit: rowsPerPage },
    })
    .catch(handleError);
}

export function getBusinessUpdateApi(slug) {
  return axiosInstance()
    .get(`/api/business-category/${slug}`)
    .catch(handleError);
}

export function putBusinessUpdateApi(slug, data) {
  return axiosInstance()
    .post(`/admin/business-category/${slug}`, data)
    .then(handleResponse)
    .catch(handleError);
}

export function deleteBusinessApi(slug) {
  return axiosInstance()
    .delete(`/admin/business-category/${slug}`)
    .then(handleResponse)
    .catch(handleError);
}

export function getAllBusinessApi(page, rows) {
  const limit = rows ? `&limit=${rows}` : "";
  const active = rows ? `?page=${page + 1}` : "";
  return axiosInstance()
    .get(`/admin/business${active}${limit}`)
    .catch(handleError);
}

export function putBusinessStatusApi(slug, data) {
  return axiosInstance()
    .post(`/admin/business/${slug}/change-active`, data)
    .then(handleResponse)
    .catch(handleError);
}

export function deleteBusinessListApi(slug) {
  return axiosInstance()
    .delete(`/admin/business/${slug}`)
    .then(handleResponse)
    .catch(handleError);
}
