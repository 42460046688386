import {
  Button,
  CircularProgress,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import DeleteOutlineIcon from "@mui/icons-material/Delete";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  p: 3,
};
const style1 = {
  position: "absolute",
  top: "41.2%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "#DB143C",
  borderRadius: "50%",
  zIndex: 4,
  fontSize: "1.8rem",
};

const style2 = {
  background: "rgb(78 78 78 / 87%)",
  width: 50,
  height: 50,
  position: "absolute",
  top: "38%",
  left: "48.6%",
  zIndex: 3,
  borderRadius: "50%",
};

const CustomModal = ({
  open,
  handleClose,
  handleDelete,
  deleteStyle,
  loading,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper elevation={2}>
        <Box sx={style}>
          <Typography
            sx={{ marginTop: "20px", textAlign: "center" }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Are you sure you want to delete?
          </Typography>
          <Box display="flex" justifyContent="center">
            <Button
              variant="contained"
              className={deleteStyle}
              onClick={handleDelete}
              sx={{
                bgcolor: "#ff000094 !important",
                marginRight: "10px",
                marginTop: "20px",
                fontSize: "12px",
              }}
            >
              {!loading ? (
                "YES"
              ) : (
                <CircularProgress size={20} sx={{ color: "white" }} />
              )}
            </Button>
            <Button
              variant="contained"
              onClick={() => handleClose(true)}
              sx={{
                marginLeft: "10px",
                marginTop: "20px",
                fontSize: "12px",
              }}
            >
              NO
            </Button>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default CustomModal;
