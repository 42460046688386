import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import InsideHeader from "../../layout/InsideHeader";
import { Breadcrumbs, Skeleton } from "@mui/material";
import { getNewsLetter } from "./redux/Action";
import { formatDate } from "../../utils/TimestamptoDate";

const columns = [
  {
    id: "email",
    label: "Email",
    minWidth: 100,
  },
  {
    id: "created_at",
    label: "Created_at",
    minWidth: 20,
    align: "center",
  },
  // {
  //     id: "action",
  //     label: "Action",
  //     minWidth: 100,
  //     align: "center",
  // },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  p: 3,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    "& .MuiTableCell-stickyHeader": {
      background: theme.palette.background.dark,
    },
    "& .MuiTableBody-root": {
      "& tr:nth-of-type(odd)": {
        background: theme.palette.background.main,
      },
    },
  },
  delete: {
    color: "#ff6363",
    cursor: "pointer",
    marginLeft: "10px",
    "&:hover": {
      color: "red",
    },
  },
  modalDelete: {
    color: "#ff6363",
    cursor: "pointer",
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: "#DB143C !important",
    },
  },
  edit: {
    color: "#15c388",
    cursor: "pointer",
    "&:hover": {
      color: "green",
    },
  },
  container: {
    maxHeight: 550,
    borderRadius: "5px",
  },
}));

function NewsLetter() {
  const dispatch = useDispatch();

  const newsLetter = useSelector((state) => state.contacts);

  const classes = useStyles();
  const rows = newsLetter?.newsLetter || [];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [id, setId] = useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleModal = (id) => {
    setId(id);
    handleOpen();
  };
  const handleDelete = () => {
    id && handleClose();
  };

  useEffect(() => {
    dispatch(getNewsLetter());
  }, []);

  const skeleton = () => (
    <>
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
    </>
  );

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          background: "#f8f8f8",
          marginBottom: "15px",
        }}
      >
        <InsideHeader title="NewsLetter Subscribed Users">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" to="/">
              NewsLetter Subscribed Users
            </Link>
            <Typography color="textPrimary">List</Typography>
          </Breadcrumbs>
        </InsideHeader>
      </Paper>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>S.No.</TableCell>
                {columns.map((column) => (
                  <TableCell
                    sx={{ fontWeight: "bold" }}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {!newsLetter?.isLoading && (
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        <TableCell>{index + 1}</TableCell>
                        {columns.map((column, ind) => {
                          const value = row[column.id];
                          return columns?.length !== ind &&
                            column.id !== "created_at" ? (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          ) : column.id === "created_at" ? (
                            <TableCell align={column.align} key={column.id}>
                              {formatDate(value)}
                            </TableCell>
                          ) : (
                            <TableCell align={column.align} key={column.id}>
                              <span>
                                <DeleteIcon
                                  className={classes.delete}
                                  onClick={() => handleModal(row?.id)}
                                />
                              </span>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {newsLetter?.isLoading && skeleton()}
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Paper elevation={2}>
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Are you sure you want to remove this email?
                </Typography>
                <Button
                  variant="contained"
                  className={classes.modalDelete}
                  onClick={handleDelete}
                  sx={{
                    bgcolor: "#ff000094 !important",
                    marginRight: "10px",
                    marginTop: "10px",
                    fontSize: "12px",
                  }}
                >
                  YES
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleClose(true)}
                  sx={{
                    marginLeft: "10px",
                    marginTop: "10px",
                    fontSize: "12px",
                  }}
                >
                  NO
                </Button>
              </Box>
            </Paper>
          </Modal>
        </div>
      </Paper>
    </>
  );
}

export default NewsLetter;
