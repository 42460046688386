import * as actions from "./Loading.type";
const initialState = {
  isLoading: false,
};

export const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOADING_START:
      return {
        isLoading: true,
      };
    case actions.LOADING_STOP:
      return {
        isLoading: false,
      };
    default:
      return state;
  }
};
