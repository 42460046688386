import { Breadcrumbs, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import InsideHeader from '../../layout/InsideHeader'
import { Paper } from "@mui/material";
import ViewReviews from './ViewReviews';

const UserReviewsPage = () => {

    return (
        <>
            <Paper elevation={0} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', background: '#f8f8f8', marginBottom: '15px' }}>
                <InsideHeader title="Reviews">
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Link color="inherit" to="/">
                            User Reviews
                        </Link>
                        <Typography color="textPrimary">Table</Typography>
                    </Breadcrumbs>
                </InsideHeader>

            </Paper>
            <ViewReviews />
        </>
    )
}

export default UserReviewsPage