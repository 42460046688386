import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  deletePromotedContent,
  getPromotedContent,
  getPromotedContentUpdate,
  postPromotedContent,
  postPromotedContentStatus,
  updatePromotedContent,
} from "./redux/Action";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";

import { Link } from "react-router-dom";
import { Modal, Skeleton, Switch, Tooltip, Typography } from "@mui/material";
import CustomModal from "../common/customModal/CustomModal";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { Box } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
import { getPromotedContentUpdateApi } from "../../api/adsManagement/AdsManagementApi";

const descriptionModalstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
};

const columns = [
  {
    id: "title",
    label: "Title",
    minWidth: 100,
  },

  {
    id: "description",
    label: "Description",
    minWidth: 150,
    align: "left",
  },
  {
    id: "active",
    label: "Status",
    minWidth: 20,
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 100,
    align: "center",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    "& .MuiTableCell-stickyHeader": {
      background: theme.palette.background.dark,
      //   color: theme.palette.primary.dark,
    },
    "& .MuiTableBody-root": {
      "& tr:nth-of-type(odd)": {
        background: theme.palette.background.main,
      },
    },
  },
  delete: {
    color: "#ff6363",
    cursor: "pointer",
    marginLeft: "10px",
    "&:hover": {
      color: "red",
    },
  },
  modalDelete: {
    color: "white",
    cursor: "pointer",
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: "#DB143C !important",
    },
  },
  edit: {
    color: "#15c388",
    cursor: "pointer",
    "&:hover": {
      color: "green",
    },
  },
  container: {
    maxHeight: 550,
    borderRadius: "5px",
  },
}));

function ViewPromotedContent({ setToggle, setButtonName }) {
  const dispatch = useDispatch();
  const ads = useSelector((state) => state.ads);

  const classes = useStyles();
  const rows = ads?.promotedContent || [];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [slug, setSlug] = useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [readMore, setReadMore] = useState();
  const [desOpen, setDesOpen] = React.useState(false);
  const handleDesOpen = () => setDesOpen(true);
  const handleDesClose = () => setDesOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  console.log("ads21", ads);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleModal = (slug) => {
    setSlug(slug);
    handleOpen();
  };
  const handleDelete = () => {
    slug && dispatch(deletePromotedContent(slug, handleClose));
  };

  const getSwitchValue = (e, value, id) => {
    dispatch(
      postPromotedContentStatus(id, {
        _method: "patch",
        value: value === true ? 1 : 0,
      })
    );
  };

  const handleEdit = (slug) => {
    console.log("slug", slug);
    setButtonName("Update");
    setToggle(true);
    dispatch(getPromotedContentUpdate(slug));
  };

  const handleDescription = (slug) => {
    handleDesOpen();
    const desData = ads?.promotedContent?.filter((item) => item?.slug === slug);

    setReadMore(desData?.[0]?.description);
  };

  useEffect(() => {
    dispatch(getPromotedContent());
  }, []);

  const skeleton = () => (
    <>
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
    </>
  );

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow sx={{ height: "12px !important" }}>
              <TableCell sx={{ fontWeight: "bold" }}>S.No.</TableCell>
              {columns.map((column) => (
                <TableCell
                  sx={{ fontWeight: "bold" }}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {!ads?.contentLoading && (
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      <TableCell>{index + 1}</TableCell>
                      {columns?.map((column, ind) => {
                        if (column?.id === "active") {
                          console.log("roe", row[column.id]);
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              sx={{ fontWeight: "500" }}
                            >
                              {row[column.id]?.title === "active"
                                ? "Active"
                                : "Inactive"}
                            </TableCell>
                          );
                        }
                        if (column.id === "description") {
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              sx={{ fontWeight: "500", display: "flex" }}
                            >
                              {`${row[column.id].slice(0, 30)}...`}
                              <Tooltip arrow title="read more">
                                <ReadMoreIcon
                                  onClick={() => handleDescription(row?.slug)}
                                  sx={{
                                    fontSize: "19px",
                                    color: "#317dc5",
                                    alignSelf: "flex-end",
                                    cursor: "pointer",
                                  }}
                                />
                              </Tooltip>
                            </TableCell>
                          );
                        }
                        const value = row[column.id];
                        return columns?.length !== ind + 1 ? (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{ fontWeight: "500" }}
                          >
                            {value}
                          </TableCell>
                        ) : (
                          <TableCell
                            align={column.align}
                            key={column.id}
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <Tooltip arrow title={`${row?.active?.title}`}>
                              <Switch
                                defaultChecked={
                                  row?.active?.title === "active" ? 1 : 0
                                }
                                inputProps={{ "aria-label": "ant design" }}
                                onChange={(e, value) =>
                                  getSwitchValue(e, value, row?.slug)
                                }
                              />
                            </Tooltip>
                            <Tooltip title="Edit" arrow>
                              <span>
                                {/* <Link to={`promotedContent/${row?.slug}`}> */}
                                <EditIcon
                                  className={classes.edit}
                                  onClick={() => handleEdit(row?.slug)}
                                />
                                {/* </Link> */}
                              </span>
                            </Tooltip>
                            <Tooltip title="Delete" arrow>
                              <span>
                                <DeleteIcon
                                  className={classes.delete}
                                  onClick={() => handleModal(row?.slug)}
                                />
                              </span>
                            </Tooltip>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {ads?.contentLoading && skeleton()}

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <CustomModal
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        deleteStyle={classes.modalDelete}
        loading={ads?.deleteLoading}
      />

      <Modal
        open={desOpen}
        onClose={handleDesClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper elevation={2}>
          <Box sx={descriptionModalstyle}>
            <Box
              sx={{
                height: "30px",
                width: "100%",
                background: "#C0DDF9",
                borderRadius: "4px",
              }}
            >
              <Tooltip arrow title="close">
                <CancelIcon
                  sx={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    color: "#e16c6c !important",
                    cursor: "pointer",
                    fontSize: "18px",
                    transition: "all ease 0.5s",

                    "&:hover": {
                      color: "red !important",
                      transition: "all ease 0.5s",
                    },
                  }}
                  onClick={() => handleDesClose()}
                />
              </Tooltip>
            </Box>

            <Box sx={{ padding: "5px 10px" }}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                sx={{
                  color: "#6e6eb3",
                }}
              >
                Description
              </Typography>
              <Typography
                sx={{
                  width: "100%",
                  wordWrap: "break-word",
                  whiteSpace: "pre-wrap",
                }}
              >
                {readMore}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Modal>
    </Paper>
  );
}

export default ViewPromotedContent;
