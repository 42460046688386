import React from "react";
import { Switch, TableCell, TableRow, Tooltip } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useDispatch } from "react-redux";
import { postUserStatus } from "./redux/Action";
import { useNavigate } from "react-router-dom";

const CustomTableRow = ({ index, columns, row, print }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getSwitchValue = (e, value, id) => {
    dispatch(
      postUserStatus(id, { _method: "patch", value: value === true ? 1 : 0 })
    );
  };

  const frontUrl = process.env.REACT_APP_FRONTEND_URL;

  const handleRedirect = (id) => {
    navigate(`/users/${id}`);
  };

  // console.log("row", row);

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
      <TableCell>{index + 1}</TableCell>
      {columns.map((column, ind) => {
        const value =
          column.id === "looking_for"
            ? row?.profile?.looking_for.map((item) => item.title).join(", ")
            : column.isInProfile
            ? row?.profile?.[column.id]
            : row[column.id];
        return columns?.length !== ind + 1 && column.id !== "active" ? (
          <TableCell
            key={column.id}
            align={column.align}
            onClick={() => handleRedirect(row?.id)}
            sx={{ cursor: "pointer" }}
          >
            {value}
          </TableCell>
        ) : column.id === "active" ? (
          <TableCell align={column.align} key={column.id}>
            {row?.active?.title}
          </TableCell>
        ) : (
          <TableCell
            key={column.id}
            align={column.align}
            sx={{
              display:
                column.id === "action" && print === true ? "none" : "flex",
              alignItems: "center",
              justifyContent: "center",
              "@media print": {
                display: "none",
              },
            }}
          >
            <Tooltip arrow title={`${row?.active?.title}`}>
              <Switch
                defaultChecked={row?.active?.title === "active" ? 1 : 0}
                inputProps={{ "aria-label": "ant design" }}
                onChange={(e, value) => getSwitchValue(e, value, row?.id)}
              />
            </Tooltip>
            <a
              href={`${frontUrl}/user/house/${row?.profile?.slug}`}
              target="_blank"
              rel="noreferrer"
            >
              <Tooltip arrow title="View profile">
                <RemoveRedEyeIcon
                  sx={{
                    cursor: "pointer",
                    color: "#6d9ecd",
                    marginLeft: "20px",
                  }}
                />
              </Tooltip>
            </a>
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default CustomTableRow;
