import {
  deleteBannerApi,
  deleteFallbackImageApi,
  deleteLookingForApi,
  getBannerApi,
  getFallbackImageApi,
  getFallbackImageByKeyApi,
  getLookingForApi,
  getSiteSettingsApi,
  postBannerApi,
  postChangePasswordApi,
  postFallbackImageApi,
  postLookingForApi,
  postSiteSettingsApi,
  updateLookingForApi,
} from "../../../api/setting/SettingApi";
import * as actions from "./Type";

export const postSiteSetting = (data) => (dispatch) => {
  dispatch({ type: actions.POST_SITE_SETTINGS_BEGIN });
  postSiteSettingsApi(data)
    .then((res) => {
      dispatch({
        type: actions.POST_SITE_SETTINGS_SUCCESS,
        payload: res.data,
      });

      dispatch(getSiteSetting());
    })

    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.POST_SITE_SETTINGS_FAIL,
      });
    });
};

export const postChangePassword = (data) => (dispatch) => {
  dispatch({ type: actions.POST_CHANGE_PASSWORD_BEGIN });
  postChangePasswordApi(data)
    .then((res) => {
      dispatch({
        type: actions.POST_CHANGE_PASSWORD_SUCCESS,
        payload: res.data,
      });
    })

    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.POST_CHANGE_PASSWORD_FAIL,
      });
    });
};

export const getSiteSetting = () => (dispatch) => {
  dispatch({ type: actions.GET_SITE_SETTINGS_BEGIN });
  getSiteSettingsApi()
    .then((res) => {
      dispatch({
        type: actions.GET_SITE_SETTINGS_SUCCESS,
        payload: res.data,
      });
    })

    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_SITE_SETTINGS_FAIL,
      });
    });
};

////////////////////////////////////////////////////////////////////////////

export const postFallbackImage = (data) => (dispatch) => {
  dispatch({ type: actions.POST_FALLBACK_IMAGE_BEGIN });
  postFallbackImageApi(data)
    .then((res) => {
      dispatch({
        type: actions.POST_FALLBACK_IMAGE_SUCCESS,
        payload: res.data,
      });
      dispatch(getFallbackImage());
    })

    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.POST_FALLBACK_IMAGE_FAIL,
      });
    });
};

export const getFallbackImage = () => (dispatch) => {
  dispatch({ type: actions.GET_FALLBACK_IMAGE_BEGIN });
  getFallbackImageApi()
    .then((res) => {
      dispatch({
        type: actions.GET_FALLBACK_IMAGE_SUCCESS,
        payload: res.data,
      });
    })

    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_FALLBACK_IMAGE_FAIL,
      });
    });
};

export const getFallbackImageByKey = (key) => (dispatch) => {
  dispatch({ type: actions.GET_FALLBACK_IMAGE_BY_KEY_BEGIN });
  getFallbackImageByKeyApi(key)
    .then((res) => {
      dispatch({
        type: actions.GET_FALLBACK_IMAGE_BY_KEY_SUCCESS,
        payload: res.data,
      });
    })

    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_FALLBACK_IMAGE_BY_KEY_FAIL,
      });
    });
};

export const deleteFallbackImage = (key) => (dispatch) => {
  dispatch({ type: actions.DELETE_FALLBACK_IMAGE_BEGIN });
  deleteFallbackImageApi(key)
    .then((res) => {
      dispatch({
        type: actions.DELETE_FALLBACK_IMAGE_SUCCESS,
        payload: res.data,
      });
      dispatch(getFallbackImage());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.DELETE_FALLBACK_IMAGE_FAIL,
      });
    });
};

//////////////////////////////////////////////////////////////////////

export const postBanner = (data) => (dispatch) => {
  dispatch({ type: actions.POST_BANNER_BEGIN });
  postBannerApi(data)
    .then((res) => {
      dispatch({
        type: actions.POST_BANNER_SUCCESS,
        payload: res.data,
      });

      dispatch(getBanner());
    })

    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.POST_BANNER_FAIL,
      });
    });
};

export const getBanner = () => (dispatch) => {
  dispatch({ type: actions.GET_BANNER_BEGIN });
  getBannerApi()
    .then((res) => {
      dispatch({
        type: actions.GET_BANNER_SUCCESS,
        payload: res.data,
      });
    })

    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_BANNER_FAIL,
      });
    });
};

export const deleteBanner = (key, reset) => (dispatch) => {
  dispatch({ type: actions.DELETE_BANNER_BEGIN });
  deleteBannerApi(key)
    .then((res) => {
      dispatch({
        type: actions.DELETE_BANNER_SUCCESS,
        payload: res.data,
      });
      reset();
      dispatch(getBanner());
    })

    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.DELETE_BANNER_FAIL,
      });
    });
};

export const postLookingFor = (data) => (dispatch) => {
  dispatch({ type: actions.POST_LOOKING_FOR_BEGIN });
  postLookingForApi(data)
    .then((res) => {
      dispatch({
        type: actions.POST_LOOKING_FOR_SUCCESS,
        payload: res.data,
      });
    })

    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.POST_LOOKING_FOR_FAIL,
      });
    });
};

export const updateLookingFor = (data) => (dispatch) => {
  dispatch({ type: actions.POST_LOOKING_FOR_BEGIN });
  updateLookingForApi(data)
    .then((res) => {
      dispatch({
        type: actions.POST_LOOKING_FOR_SUCCESS,
        payload: res.data,
      });
    })

    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.POST_LOOKING_FOR_FAIL,
      });
    });
};

export const getLookingFor = () => (dispatch) => {
  dispatch({ type: actions.GET_LOOKIN_FOR_BEGIN });
  getLookingForApi()
    .then((res) => {
      dispatch({
        type: actions.GET_LOOKIN_FOR_SUCCESS,
        payload: res.data,
      });
    })

    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_LOOKIN_FOR_FAIL,
      });
    });
};

export const deleteLookingFor = (id, setOpen) => (dispatch) => {
  dispatch({ type: actions.DELETE_LOOKING_FOR_BEGIN });
  deleteLookingForApi(id)
    .then((res) => {
      dispatch({
        type: actions.DELETE_LOOKING_FOR_SUCCESS,
        payload: id,
      });
      setOpen(false);
    })

    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.DELETE_LOOKING_FOR_FAIL,
      });
    });
};
