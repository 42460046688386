import * as actions from "./Modal.type";
const initialState = {
  modalSet: null,
};

export const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_MODAL:
      return {
        modalSet: action.payload,
      };
 
    default:
      return state;
  }
};
