export const GET_QUICK_BEGIN = "GET_QUICK_BEGIN";
export const GET_QUICK_SUCCESS = "GET_QUICK_SUCCESS";
export const GET_QUICK_FAIL = "GET_QUICK_FAIL";

export const QUICK_DELETE_BEGIN = "QUICK_DELETE_BEGIN";
export const QUICK_DELETE_SUCCESS = "QUICK_DELETE_SUCCESS";
export const QUICK_DELETE_FAIL = "QUICK_DELETE_FAIL";

export const POST_QUICK_STATUS_BEGIN = "POST_QUICK_STATUS_BEGIN";
export const POST_QUICK_STATUS_SUCCESS = "POST_QUICK_STATUS_SUCCESS";
export const POST_QUICK_STATUS_FAIL = "POST_QUICK_STATUS_FAIL";

export const CLEAN_UPDATE_QUICK = "CLEAN_UPDATE_QUICK";
