import AddBoxIcon from "@mui/icons-material/AddBox";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BedroomParentIcon from "@mui/icons-material/BedroomParent";
import BusinessIcon from "@mui/icons-material/Business";
import CampaignIcon from "@mui/icons-material/Campaign";
import CategoryIcon from "@mui/icons-material/Category";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import ContactsIcon from "@mui/icons-material/Contacts";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FormatShapesIcon from "@mui/icons-material/FormatShapes";
import HomeIcon from "@mui/icons-material/Home";
import ImageIcon from "@mui/icons-material/Image";
import ListIcon from "@mui/icons-material/List";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import PasswordIcon from "@mui/icons-material/Password";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import WebIcon from "@mui/icons-material/Web";
import WorkIcon from "@mui/icons-material/Work";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { Link } from "react-router-dom";
import HasPermission from "../utils/HasPermission";

const useStyles = makeStyles(() => ({
  dropButton: {
    padding: "0px !important",
    width: "100%",

    "& .MuiListItemButton-root": {
      padding: "0px !important",
    },

    "& .MuiCollapse-wrapperInner": {
      "& .MuiListItemButton-root": {
        paddingLeft: "30px !important",
      },
    },

    "& .MuiTypography-root": {
      padding: "8px 0",
    },

    "& .MuiButtonBase-root": {
      padding: "8px 0",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    },
    "& a": {
      display: "flex",
      alignItems: "center",
      color: "#fff",
      textDecoration: "none",
    },
  },

  // dropHead: {
  //     '& .MuiListItemText-primary': {
  //         fontWeight: 'bold'
  //     }
  // }
}));

function SidebarDropdown(props) {
  const classes = useStyles();
  console.log("props", props.optionTwo);
  return (
    <List className={classes.dropButton}>
      <ListItemButton
        onClick={() => props.handleClick(props.name)}
        sx={{ padding: "8px 0" }}
      >
        {props.optionOne == "Rental" ? (
          <ApartmentIcon sx={{ color: "#fff" }} />
        ) : props.optionOne == "Job" ? (
          <WorkIcon sx={{ color: "#fff" }} />
        ) : props.optionOne == "Business" ? (
          <BusinessIcon sx={{ color: "#fff" }} />
        ) : props.optionOne == "Settings" ? (
          <SettingsIcon sx={{ color: "#fff" }} />
        ) : props.optionOne == "Ads Management" ? (
          <FormatShapesIcon sx={{ color: "#fff" }} />
        ) : props.optionOne == "Contact" ? (
          <ContactsIcon sx={{ color: "#fff" }} />
        ) : props.optionOne == "Blogs" ? (
          <WysiwygIcon sx={{ color: "#fff" }} />
        ) : props.optionOne == "Role" ? (
          <PersonIcon sx={{ color: "#fff" }} />
        ) : (
          <InboxIcon sx={{ color: "#fff" }} />
        )}
        <ListItemText
          className={classes.dropHead}
          primary={props.optionOne}
          sx={{
            pl: 0,
            paddingLeft: "17px",
            m: 0,
            color: "#fff",
            fontWeight: "bold !important",
          }}
        />
        {props.openedDropdown === props.name && props.open ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )}
      </ListItemButton>
      <Collapse
        in={props.openedDropdown === props.name && props.open}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          <HasPermission of={props.showOptionTwo}>
            <Link to={`/${props.navPath}/${props.firstPathId}`}>
              <ListItemButton sx={{ pl: 0 }}>
                {props.optionTwo == "Category" ? (
                  // <HasPermission of={props.showCategoryIcon}>
                  <CategoryIcon sx={{ color: "#fff" }} />
                ) : props.optionTwo == "SaleCategory" ? (
                  // <HasPermission of={props.showCategoryIcon}>
                  <CategoryIcon sx={{ color: "#fff" }} />
                ) : // </HasPermission>
                  props.optionTwo == "Site Settings" ? (
                    <WebIcon sx={{ color: "#fff" }} />
                  ) : props.optionTwo == "Home Page" ? (
                    <HomeIcon sx={{ color: "#fff" }} />
                  ) : props.optionTwo == "Contact Us" ? (
                    <ContactPageIcon sx={{ color: "#fff" }} />
                  ) : props.optionTwo == "Post Blog" ? (
                    <AddBoxIcon sx={{ color: "#fff" }} />
                  ) : (
                    <InboxIcon sx={{ color: "#fff" }} />
                  )}
                <ListItemText
                  primary={props.optionTwo}
                  sx={{ paddingLeft: "17px", m: 0, color: "#fff" }}
                />
              </ListItemButton>
            </Link>
          </HasPermission>
        </List>
        {props.feature ? (
          <List component="div" disablePadding>
            <Link to={`/${props.navPath}/features`}>
              <ListItemButton sx={{ pl: 0 }}>
                <HasPermission of={props.showFeatureIcon}>
                  <StarBorderIcon />
                </HasPermission>
                <ListItemText
                  primary={props.feature}
                  sx={{ paddingLeft: "17px", m: 0, color: "#fff" }}
                />
              </ListItemButton>
            </Link>
          </List>
        ) : null}
        <List component="div" disablePadding>
          <HasPermission of={props.showOptionThree}>
            <Link to={`/${props.navPath}/${props.secondPathId}`}>
              <ListItemButton sx={{ pl: 0 }}>
                {props.optionThree === "Amenities" ? (
                  <BedroomParentIcon sx={{ color: "#fff" }} />
                ) : props.optionThree == "Banners" ? (
                  <ImageIcon sx={{ color: "#fff" }} />
                ) : props.optionThree == "Jobs Page" ? (
                  <WorkIcon sx={{ color: "#fff" }} />
                ) : props.optionThree == "List" ? (
                  <ListIcon sx={{ color: "#fff" }} />
                ) : props.optionThree == "Blog Lists" ? (
                  <ListIcon sx={{ color: "#fff" }} />
                ) : props.optionThree == "Newsletter" ? (
                  <NewspaperIcon sx={{ color: "#fff" }} />
                ) : (
                  // <InboxIcon sx={{ color: '#fff' }} />
                  false
                )}
                <ListItemText
                  primary={props.optionThree}
                  sx={{ paddingLeft: "17px", m: 0, color: "#fff" }}
                />
              </ListItemButton>
            </Link>
          </HasPermission>
        </List>
        {props.thirdPathId ? (
          <List component="div" disablePadding>
            <HasPermission of={props.showOptionFour}>
              <Link to={`/${props.navPath}/${props.thirdPathId}`}>
                <ListItemButton sx={{ pl: 0 }}>
                  {props.optionFour == "List" ? (
                    <ListIcon sx={{ color: "#fff" }} />
                  ) : props.optionFour == "Change Password" ? (
                    <PasswordIcon sx={{ color: "#fff" }} />
                  ) : props.optionFour == "Rentals Page" ? (
                    <ApartmentIcon sx={{ color: "#fff" }} />
                  ) : (
                    <InboxIcon sx={{ color: "#fff" }} />
                  )}
                  <ListItemText
                    primary={props.optionFour}
                    sx={{ paddingLeft: "17px", m: 0, color: "#fff" }}
                  />
                </ListItemButton>
              </Link>
            </HasPermission>
          </List>
        ) : null}
        {props.fourthPathId ? (
          <List component="div" disablePadding>
            <HasPermission of={props.showOptionFive}>
              <Link to={`/${props.navPath}/${props.fourthPathId}`}>
                <ListItemButton sx={{ pl: 0 }}>
                  {props.optionFive == "Business Page" ? (
                    <BusinessIcon sx={{ color: "#fff" }} />
                  ) : props.optionFive == "Fallback Image" ? (
                    <WallpaperIcon sx={{ color: "#fff" }} />
                  ) : (
                    <InboxIcon sx={{ color: "#fff" }} />
                  )}

                  <ListItemText
                    primary={props.optionFive}
                    sx={{ paddingLeft: "17px", m: 0, color: "#fff" }}
                  />
                </ListItemButton>
              </Link>
            </HasPermission>
          </List>
        ) : null}
        {props.tenPathId ? (
          <List component="div" disablePadding>
            <HasPermission of={props.showOptionTen}>
              <Link to={`/${props.navPath}/${props.tenPathId}`}>
                <ListItemButton sx={{ pl: 0 }}>
                  {props.optionTen == "Sale Page" ? (
                    <AttachMoneyIcon sx={{ color: "#fff" }} />
                  ) : props.optionTen == "Fallback Image" ? (
                    <WallpaperIcon sx={{ color: "#fff" }} />
                  ) : (
                    <InboxIcon sx={{ color: "#fff" }} />
                  )}

                  <ListItemText
                    primary={props.optionTen}
                    sx={{ paddingLeft: "17px", m: 0, color: "#fff" }}
                  />
                </ListItemButton>
              </Link>
            </HasPermission>
          </List>
        ) : null}

        {props.seventhPathId ? (
          <List component="div" disablePadding>
            <HasPermission of={props.showOptionSeven}>
              <Link to={`/${props.navPath}/${props.seventhPathId}`}>
                <ListItemButton sx={{ pl: 0 }}>
                  {props.optionSeven == "Business Page" ? (
                    <BusinessIcon sx={{ color: "#fff" }} />
                  ) : props.optionSeven == "Fallback Image" ? (
                    <WallpaperIcon sx={{ color: "#fff" }} />
                  ) : props.optionSeven == "Looking For" ? (
                    <BusinessIcon sx={{ color: "#fff" }} />
                  ) : (
                    <InboxIcon sx={{ color: "#fff" }} />
                  )}

                  <ListItemText
                    primary={props.optionSeven}
                    sx={{ paddingLeft: "17px", m: 0, color: "#fff" }}
                  />
                </ListItemButton>
              </Link>
            </HasPermission>
          </List>
        ) : null}
        {props.eightPathId ? (
          <List component="div" disablePadding>
            <HasPermission of={props.showOptionEight}>
              <Link to={`/${props.navPath}/${props.eightPathId}`}>
                <ListItemButton sx={{ pl: 0 }}>
                  {props.optionEight == "Quick Post Page" ? (
                    <BusinessIcon sx={{ color: "#fff" }} />
                  ) : props.optionEight == "Fallback Image" ? (
                    <WallpaperIcon sx={{ color: "#fff" }} />
                  ) : (
                    <InboxIcon sx={{ color: "#fff" }} />
                  )}

                  <ListItemText
                    primary={props.optionEight}
                    sx={{ paddingLeft: "17px", m: 0, color: "#fff" }}
                  />
                </ListItemButton>
              </Link>
            </HasPermission>
          </List>
        ) : null}
        {props.fifthPathId ? (
          <List component="div" dispablePadding>
            <HasPermission of={props.showOptionSix}>
              <Link to={`/${props.navPath}/${props.fifthPathId}`}>
                <ListItemButton sx={{ pl: 0 }}>
                  {props.optionSix == "Promoted Content" ? (
                    <CampaignIcon sx={{ color: "#fff" }} />
                  ) : props.optionSix == "Reward" ? (
                    <CampaignIcon sx={{ color: "#fff" }} />
                  ) : (
                    <InboxIcon sx={{ color: "#fff" }} />
                  )}
                  <ListItemText
                    primary={props.optionSix}
                    sx={{ paddingLeft: "17px", m: 0, color: "#fff" }}
                  />
                </ListItemButton>
              </Link>
            </HasPermission>
          </List>
        ) : null}
        {props.ninthPathId ? (
          <List component="div" disablePadding>
            <HasPermission of={props.showOptionNinth}>
              <Link to={`/adsManagement/categoryPromotional`}>
                <ListItemButton sx={{ pl: 0 }}>
                  {props.optionNinth == "Quick Post Page" ? (
                    <BusinessIcon sx={{ color: "#fff" }} />
                  ) : props.optionNinth == "Fallback Image" ? (
                    <WallpaperIcon sx={{ color: "#fff" }} />
                  ) : (
                    <InboxIcon sx={{ color: "#fff" }} />
                  )}

                  <ListItemText
                    primary={props.optionNinth}
                    sx={{ paddingLeft: "17px", m: 0, color: "#fff" }}
                  />
                </ListItemButton>
              </Link>
            </HasPermission>
          </List>
        ) : null}
      </Collapse>
    </List>
  );
}

export default SidebarDropdown;
