import * as actions from "./Type";

const initialState = {
  isLoading: false,
  fallbackImageLoading: false,
  deleteLoading: false,
  bannerLoading: false,
  passwordChanging: false,
  lookingForLoading: false,
  lookingForPostStatus: "idle",
  fallbackImages: [],
  siteData: [],
  newsLetter: [],
  banner: [],
  fallbackImageByKey: [],
  lookingForData: [],
};

export const siteSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.POST_SITE_SETTINGS_BEGIN:
      return {
        ...state,
        isLoading: true,
      };
    case actions.POST_SITE_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actions.POST_SITE_SETTINGS_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case actions.POST_CHANGE_PASSWORD_BEGIN:
      return {
        ...state,
        passwordChanging: true,
      };
    case actions.POST_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordChanging: false,
      };

    case actions.POST_CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        passwordChanging: false,
      };

    case actions.GET_SITE_SETTINGS_BEGIN:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_SITE_SETTINGS_SUCCESS:
      return {
        ...state,
        siteData: action.payload.data,
        isLoading: false,
      };

    case actions.GET_SITE_SETTINGS_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    // case actions.GET_SINGLE_NEWSLETTER_BEGIN:
    //     return {
    //         ...state,
    //         isLoading: true
    //     }

    // case actions.GET_SINGLE_NEWSLETTER_SUCCESS:
    //     return {
    //         ...state,
    //         singleNewsLetter: action.payload.data
    //     }

    // case actions.GET_SINGLE_NEWSLETTER_SUCCESS:
    //     return {
    //         ...state,
    //         isLoading: false
    //     }

    case actions.POST_FALLBACK_IMAGE_BEGIN:
      return {
        ...state,
        fallbackImageLoading: true,
      };
    case actions.POST_FALLBACK_IMAGE_SUCCESS:
      return {
        ...state,
        fallbackImageLoading: false,
      };

    case actions.POST_FALLBACK_IMAGE_FAIL:
      return {
        ...state,
        fallbackImageLoading: false,
      };

    case actions.GET_FALLBACK_IMAGE_BEGIN:
      return {
        ...state,
        fallbackImageLoading: true,
      };
    case actions.GET_FALLBACK_IMAGE_SUCCESS:
      return {
        ...state,
        fallbackImageLoading: false,
        fallbackImages: action.payload.data,
      };

    case actions.GET_FALLBACK_IMAGE_FAIL:
      return {
        ...state,
        fallbackImageLoading: false,
      };

    case actions.GET_FALLBACK_IMAGE_BY_KEY_BEGIN:
      return {
        ...state,
        fallbackImageLoading: true,
      };
    case actions.GET_FALLBACK_IMAGE_BY_KEY_SUCCESS:
      return {
        ...state,
        fallbackImageLoading: false,
        fallbackImageByKey: action.payload.data,
      };

    case actions.GET_FALLBACK_IMAGE_BY_KEY_FAIL:
      return {
        ...state,
        fallbackImageLoading: false,
      };

    case actions.DELETE_FALLBACK_IMAGE_BEGIN:
      return {
        ...state,
        deleteLoading: true,
      };

    case actions.DELETE_FALLBACK_IMAGE_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
      };

    case actions.DELETE_FALLBACK_IMAGE_FAIL:
      return {
        ...state,
        deleteLoading: false,
      };

    ///////////////////////////////////////////////////

    case actions.POST_BANNER_BEGIN:
      return {
        ...state,
        bannerLoading: true,
      };

    case actions.POST_BANNER_SUCCESS:
      return {
        ...state,
        bannerLoading: false,
      };

    case actions.POST_BANNER_FAIL:
      return {
        ...state,
        bannerLoading: false,
      };

    case actions.GET_BANNER_BEGIN:
      return {
        ...state,
        bannerLoading: true,
      };

    case actions.GET_BANNER_SUCCESS:
      return {
        ...state,
        bannerLoading: false,
        banner: action.payload.data,
      };

    case actions.GET_BANNER_FAIL:
      return {
        ...state,
        bannerLoading: false,
      };

    case actions.DELETE_BANNER_BEGIN:
      return {
        ...state,
        bannerLoading: true,
      };

    case actions.DELETE_BANNER_SUCCESS:
      return {
        ...state,
        bannerLoading: false,
      };

    case actions.DELETE_BANNER_FAIL:
      return {
        ...state,
        bannerLoading: false,
      };

    case actions.GET_LOOKIN_FOR_BEGIN:
      return { ...state, lookingForLoading: true };

    case actions.GET_LOOKIN_FOR_SUCCESS:
      return {
        ...state,
        lookingForLoading: false,
        lookingForData: action.payload.data,
      };

    case actions.GET_LOOKIN_FOR_FAIL:
      return { ...state, lookingForLoading: false };

    case actions.POST_LOOKING_FOR_BEGIN:
      return { ...state, lookingForLoading: true };

    case actions.POST_LOOKING_FOR_SUCCESS:
      return {
        ...state,
        lookingForLoading: false,
        lookingForPostStatus: "completed",
      };

    case actions.POST_LOOKING_FOR_FAIL:
      return {
        ...state,
        lookingForLoading: false,
        lookingForPostStatus: "failed",
      };

    case actions.RESET_LOOKING_POST_STATUS:
      return { ...state, lookingForPostStatus: "idle" };

    case actions.DELETE_LOOKING_FOR_BEGIN:
      return { ...state, deleteLoading: true };
    case actions.DELETE_LOOKING_FOR_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        lookingForData: state.lookingForData.filter(
          (item) => item.id !== action.payload
        ),
      };
    case actions.DELETE_LOOKING_FOR_FAIL:
      return { ...state, deleteLoading: false };

    default:
      return state;
  }
};
