import React from 'react';
import RolePage from '../../components/role/Index';

const Role = () => {
  return (
    <>
      <RolePage />
    </>
  );
};

export default Role;
