export const GET_NEWSLETTER_BEGIN = 'GET_NEWSLETTER_BEGIN'
export const GET_NEWSLETTER_SUCCESS = 'GET_NEWSLETTER_SUCCESS'
export const GET_NEWSLETTER_FAIL = 'GET_NEWSLETTER_FAIL'


export const GET_CONTACT_BEGIN = 'GET_CONTACT_BEGIN'
export const GET_CONTACT_SUCCESS = 'GET_CONTACT_SUCCESS'
export const GET_CONTACT_FAIL = 'GET_CONTACT_FAIL'

export const DELETE_CONTACT_BEGIN = 'DELETE_CONTACT_BEGIN'
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS'
export const DELETE_CONTACT_FAIL = 'DELETE_CONTACT_FAIL'

// export const GET_SINGLE_NEWSLETTER_BEGIN = 'GET_SINGLE_NEWSLETTER_BEGIN'
// export const GET_SINGLE_NEWSLETTER_SUCCESS = 'GET_SINGLE_NEWSLETTER_SUCCESS'
// export const GET_SINGLE_NEWSLETTER_FAIL = 'GET_SINGLE_NEWSLETTER_FAIL'