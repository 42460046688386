import { Breadcrumbs, Button, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import InsideHeader from '../../layout/InsideHeader'
import { useForm } from 'react-hook-form';
import { serializer } from '../../utils/helper';
import { makeStyles } from '@mui/styles';

import { Box } from '@mui/system';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import CustomInputPassword from '../common/customInput/CustomInputPassword';
import { postChangePassword } from './redux/Action';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        "& .uploadAndEdit": {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'

        }
    },
    about: {
        "& .MuiOutlinedInput-root": {
            paddingLeft: '0px !important',
            paddingRight: '0px !important',
        }

    },
}));

const ChangePassword = () => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const passwordData = useSelector((state) => state.siteSetting)

    console.log('Password Data ===>', passwordData)

    const defaultValues = {
        old_password: '',
        password: '',
        password_confirmation: ''
    };

    const schema = yup.object({
        old_password: yup.string().required('Old password is required'),
        password: yup.string().min(8, 'Your password should have minimum length of 8.').max(20, 'Your password length should not be greater than 20.').required('Enter new Password'),
        password_confirmation: yup.string().oneOf([yup.ref('password'), null], 'Incorrect password !! Confirm password must match with new password.').required('Confirm password is required.'),
    }).required();

    const {
        handleSubmit,
        control,
        reset,
        watch,
        setValue,
        formState: { errors },
    } = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema) });

    const onSubmit = (values) => {
        const formData = new FormData();
        serializer(formData, values)
        dispatch(postChangePassword(formData))
    };

    const checkKeyDown = (e) => {
        if (e.code === "Enter") e.preventDefault();
    };

    return (
        <>
            <Paper elevation={0} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', background: '#f8f8f8', marginBottom: '15px' }}>
                <InsideHeader title="Change Password">
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Link color="inherit" to="/">
                            Setting
                        </Link>
                        <Typography color="textPrimary">Change Password</Typography>
                    </Breadcrumbs>
                </InsideHeader>
            </Paper>


            <Paper elevation={1} className={classes.paper} sx={{ padding: '25px', marginBottom: '30px' }}>
                <div className={classes.root}>
                    <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <CustomInputPassword
                                    label="Old Password"
                                    control={control}
                                    errors={errors}
                                    name="old_password"
                                />
                                <Typography sx={{ color: 'red !important', fontSize: '12px' }}>{errors?.old_password?.message}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <CustomInputPassword
                                    label="New Password"
                                    control={control}
                                    errors={errors}
                                    name="password"
                                />
                                <Typography sx={{ color: 'red !important', fontSize: '12px' }}>{errors?.password?.message}</Typography>

                            </Grid>
                            <Grid item sm={12}>
                                <CustomInputPassword
                                    label="Confirm Password"
                                    control={control}
                                    errors={errors}
                                    name="password_confirmation"
                                />
                                <Typography sx={{ color: 'red !important', fontSize: '12px' }}>{errors?.password_confirmation?.message}</Typography>
                            </Grid>
                            <Grid item sm={2}>
                                <Box marginTop={2.5} marginLeft={0} sx={{ margiLeft: '0px', marginTop: '0px' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        type="submit"
                                    >
                                        {!passwordData?.passwordChanging ? 'Submit' : <CircularProgress size={25} sx={{ color: 'white' }} />}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Paper>


        </>
    )
}

export default ChangePassword