import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { getallBusiness } from './redux/Action'


const SingleBusinessPage = () => {
    const { id } = useParams()

    const dispatch = useDispatch()
    const allBusinesses = useSelector((state) => state?.businesses)
    const singleBusiness = allBusinesses?.allBusinesses?.filter((item) => {
        return (
            item?.slug === id
        )
    })

    useEffect(() => {
        dispatch(getallBusiness())
    }, [])

    return (
        <div>{singleBusiness?.[0]?.slug}</div>
    )
}

export default SingleBusinessPage