import React from 'react';
import { useParams } from 'react-router-dom';
import CategoryJob from './CategoryRole';
import ListJob from './ListRole';
import PageNotFound from '../../pages/PageNotFound/Index';

const RolePage = () => {
  const { roleId } = useParams();
  return (
    <>
      {roleId == 'category' || !roleId ? (
        <CategoryJob />
      ) : roleId === 'lists' ? (
        <ListJob />
      ) : (
        <PageNotFound />
      )}
    </>
  );
};

export default RolePage;
