import { combineReducers } from "redux";
import { adsReducer } from "../components/adsManagement/redux/Reducer";
import { authReducer } from "../components/auth/redux/Auth.reducer";
import { blogReducer } from "../components/blogs/redux/Reducer";
import { businessReducer } from "../components/business/redux/Reducer";
import { spinnerReducer } from "../components/common/spinner/redux/Reducer";
import { contactReducer } from "../components/contact/redux/Reducer";
import { dashboardReducer } from "../components/dashboard/redux/Reducer";
import { jobReducer } from "../components/job/redux/Reducer";
import { quickPostReducer } from "../components/quickpost/redux/Reducer";
import { rentalReducer } from "../components/rental/redux/Reducer";
import { rolePermissionReducer } from "../components/role/redux/Reducer";
import { saleReducer } from "../components/sale/redux/Reducer";
import { siteSettingReducer } from "../components/setting/redux/Reducer";
import { reviewsReducer } from "../components/userReviews/redux/Reducer";
import { userReducer } from "../components/users/redux/Reducer";
import { errorReducer } from "./Error/Error.reducer";
import { loadingReducer } from "./Loading/Loading.reducer";
import { modalReducer } from "./Modal/Modal.reducer";
import { successReducer } from "./Success/Success.reducer";

export const rootReducer = combineReducers({
  error: errorReducer,
  auth: authReducer,
  success: successReducer,
  modal: modalReducer,
  loading: loadingReducer,
  spinner: spinnerReducer,
  rentals: rentalReducer,
  jobs: jobReducer,
  businesses: businessReducer,
  ads: adsReducer,
  siteSetting: siteSettingReducer,
  blogs: blogReducer,
  users: userReducer,
  contacts: contactReducer,
  dashboard: dashboardReducer,
  reviews: reviewsReducer,
  rolePermission: rolePermissionReducer,
  quickPost: quickPostReducer,
  sale: saleReducer,
});
