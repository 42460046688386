import { loginApi } from '../../../api/auth/AuthApi';
import {
  getPermission,
  getSingleAdminPermission,
} from '../../role/redux/Action';
import * as actions from './Auth.type';

// Login

export const login = (data) => (dispatch) => {
  loginApi(data)
    .then((res) => {
      console.log(res);
      // res?.data?.user?.roles?.[0]?.name !== 'superadmin' &&
      dispatch(getSingleAdminPermission(res?.data?.user?.id));
      dispatch(getPermission());
      dispatch({
        type: actions.LOGIN_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);

      dispatch({
        type: actions.LOGIN_FAIL,
      });
    });
};

// Logout
export const logout = (handleHistory) => (dispatch) => {
  dispatch({
    type: actions.LOGOUT_SUCCESS,
  });
  handleHistory && handleHistory();
};

//auth error
export const authError = () => (dispatch) => {
  dispatch({
    type: actions.AUTH_ERROR,
  });
};
