import { CircularProgress } from '@mui/material';
import React from 'react';

function Loader() {
    return (
        <CircularProgress sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }} />
    );
}

export default Loader;