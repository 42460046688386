import * as actions from "./Type";

const initialState = {
  isLoading: false,
  deleteLoading: false,
  statusLoading: false,
  quick_posts: [],
};

export const quickPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_QUICK_BEGIN:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_QUICK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        quick_posts: action.payload,
      };
    case actions.GET_QUICK_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case actions.QUICK_DELETE_BEGIN:
      return {
        ...state,
        deleteLoading: true,
      };

    case actions.QUICK_DELETE_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
      };
    case actions.QUICK_DELETE_FAIL:
      return {
        ...state,
        deleteLoading: false,
      };

    case actions.POST_QUICK_STATUS_BEGIN:
      return {
        ...state,
        statusLoading: true,
      };

    case actions.POST_QUICK_STATUS_SUCCESS:
      return {
        ...state,
        statusLoading: false,
      };

    case actions.POST_QUICK_STATUS_FAIL:
      return {
        ...state,
        statusLoading: false,
      };

    default:
      return state;
  }
};
