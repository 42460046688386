import React from "react";
import SalePage from "../../components/sale/Index";

const Sale = () => {
  return (
    <>
      <SalePage />
    </>
  );
};

export default Sale;
