export const CREATE_ADMIN_POST_BEGIN = "CREATE_ADMIN_POST_BEGIN";
export const CREATE_ADMIN_POST_FAIL = "CREATE_ADMIN_POST_FAIL";
export const CREATE_ADMIN_POST_SUCCESS = "CREATE_ADMIN_POST_SUCCESS";

export const GET_PERMISSION_BEGIN = "GET_PERMISSION_BEGIN";
export const GET_PERMISSION_SUCCESS = "GET_PERMISSION_SUCCESS";
export const GET_PERMISSION_FAIL = "GET_PERMISSION_FAIL";

export const GET_ALL_ADMIN_BEGIN = "GET_ALL_ADMIN_BEGIN";
export const GET_ALL_ADMIN_SUCCESS = "GET_ALL_ADMIN_SUCCESS";
export const GET_ALL_ADMIN_FAIL = "GET_ALL_ADMIN_FAIL";

export const GET_JOB_UPDATE_BEGIN = "GET_JOB_UPDATE_BEGIN";
export const GET_JOB_UPDATE_SUCCESS = "GET_JOB_UPDATE_SUCCESS";
export const GET_JOB_UPDATE_FAIL = "GET_JOB_UPDATE_FAIL";

export const PUT_JOB_UPDATE_BEGIN = "PUT_JOB_UPDATE_BEGIN";
export const PUT_JOB_UPDATE_SUCCESS = "PUT_JOB_UPDATE_SUCCESS";
export const PUT_JOB_UPDATE_FAIL = "PUT_JOB_UPDATE_FAIL";

export const JOB_DELETE_BEGIN = "JOB_DELETE_BEGIN";
export const JOB_DELETE_SUCCESS = "JOB_DELETE_SUCCESS";
export const JOB_DELETE_FAIL = "JOB_DELETE_FAIL";

export const GET_ALL_JOB_BEGIN = "GET_ALL_JOB_BEGIN";
export const GET_ALL_JOB_SUCCESS = "GET_ALL_JOB_SUCCESS";
export const GET_ALL_JOB_FAIL = "GET_ALL_JOB_FAIL";

export const POST_JOB_STATUS_BEGIN = "POST_JOB_STATUS_BEGIN";
export const POST_JOB_STATUS_SUCCESS = "POST_JOB_STATUS_SUCCESS";
export const POST_JOB_STATUS_FAIL = "POST_JOB_STATUS_FAIL";

export const CLEAN_JOB_CATEGORY_UPDATE = "CLEAN_JOB_CATEGORY_UPDATE";

export const GET_JOB_BEGIN = "GET_JOB_BEGIN";
export const GET_JOB_SUCCESS = "GET_JOB_SUCCESS";
export const GET_JOB_FAIL = "GET_JOB_FAIL";

export const JOB_POST_BEGIN = "JOB_POST_BEGIN";
export const JOB_POST_SUCCESS = "JOB_POST_SUCCESS";
export const JOB_POST_FAIL = "JOB_POST_FAIL";

export const GET_SINGLE_ADMIN_PERMISSION_BEGIN =
  "GET_SINGLE_ADMIN_PERMISSION_BEGIN";
export const GET_SINGLE_ADMIN_PERMISSION_SUCCESS =
  "GET_SINGLE_ADMIN_PERMISSION_SUCCESS";
export const GET_SINGLE_ADMIN_PERMISSION_FAIL =
  "GET_SINGLE_ADMIN_PERMISSION_FAIL";

export const GET_OTHER_ADMIN_PERMISSION_BEGIN =
  "GET_OTHER_ADMIN_PERMISSION_BEGIN";
export const GET_OTHER_ADMIN_PERMISSION_SUCCESS =
  "GET_OTHER_ADMIN_PERMISSION_SUCCESS";
export const GET_OTHER_ADMIN_PERMISSION_FAIL =
  "GET_SINGLE_ADMIN_PERMISSION_FAIL";
