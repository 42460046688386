import * as actions from "./Type";

const initialState = {
  isLoading: false,
  jobLoading: false,
  updateLoading: false,
  deleteLoading: false,
  allJobLoading: false,
  statusLoading: false,
  jobs: [],
  updateJobs: {},
  allJobs: [],
  deleteListLoading: false,
};

export const jobReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.JOB_POST_BEGIN:
      return {
        ...state,
        isLoading: true,
      };

    case actions.JOB_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actions.JOB_POST_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case actions.GET_JOB_BEGIN:
      return {
        ...state,
        jobLoading: true,
      };

    case actions.GET_JOB_SUCCESS:
      return {
        ...state,
        jobLoading: false,
        jobs: action.payload.data,
      };

    case actions.GET_JOB_FAIL:
      return {
        ...state,
        jobLoading: false,
      };

    case actions.GET_JOB_UPDATE_BEGIN:
      return {
        ...state,
        updateLoading: true,
      };

    case actions.GET_JOB_UPDATE_SUCCESS:
      return {
        ...state,
        updateJobs: action.payload.data,
        updateLoading: false,
      };

    case actions.GET_JOB_UPDATE_FAIL:
      return {
        ...state,
        updateLoading: false,
      };

    case actions.PUT_JOB_UPDATE_BEGIN:
      return {
        ...state,
        updateLoading: true,
      };

    case actions.PUT_JOB_UPDATE_SUCCESS:
      return {
        ...state,
        updateJobs: action.payload.data,
        updateLoading: false,
      };

    case actions.PUT_JOB_UPDATE_FAIL:
      return {
        ...state,
        updateLoading: false,
      };

    case actions.JOB_DELETE_BEGIN:
      return {
        ...state,
        deleteLoading: true,
      };

    case actions.JOB_DELETE_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
      };

    case actions.JOB_DELETE_FAIL:
      return {
        ...state,
        deleteLoading: false,
      };

    case actions.GET_ALL_JOB_BEGIN:
      return {
        ...state,
        allJobLoading: true,
      };

    case actions.GET_ALL_JOB_SUCCESS:
      return {
        ...state,
        allJobLoading: false,
        allJobs: action.payload,
      };

    case actions.GET_ALL_JOB_FAIL:
      return {
        ...state,
        allJobLoading: false,
      };

    case actions.POST_JOB_STATUS_BEGIN:
      return {
        ...state,
        statusLoading: true,
      };

    case actions.POST_JOB_STATUS_SUCCESS:
      return {
        ...state,
        statusLoading: false,
      };

    case actions.POST_JOB_STATUS_FAIL:
      return {
        ...state,
        statusLoading: false,
      };

    case actions.CLEAN_JOB_CATEGORY_UPDATE:
      return {
        ...state,
        updateJobs: [],
      };

    case actions.JOB_LIST_DELETE_SUCCESS:
    case actions.JOB_LIST_DELETE_FAIL:
      return {
        ...state,
        deleteListLoading: false,
      };

    default:
      return state;
  }
};
