import * as actions from "./Error.type";

const inititalState = {
  message: "",
  status: null,
};

export const errorReducer = (state = inititalState, action) => {
  switch (action.type) {
    case actions.GET_ERRORS:
      return {
        message: action.payload?.message,
        status: action.payload.status,
      };

    case actions.CLEAR_ERRORS:
      return {
        message: "",
        status: null,
      };

    default:
      return state;
  }
};
