import { axiosInstance } from "../../utils/axiosInstance";
import { handleError, handleResponse } from "../apiResponse";

export function getQuickApi(page, limit) {
  return axiosInstance()
    .get(`/admin/all-quickpost`, { params: { limit: limit, page: page + 1 } })
    .catch(handleError);
}

export function deleteQuickApi(id) {
  return axiosInstance()
    .delete(`/admin/quickpost/${id}`)
    .then(handleResponse)
    .catch(handleError);
}

export function postQuickStatusApi(slug, status) {
  return axiosInstance()
    .post(`/admin/quickpost/block`, { slug: slug, status: status })
    .then(handleResponse)
    .catch(handleError);
}
