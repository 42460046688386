import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import InsideHeader from "../../layout/InsideHeader";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllRental } from "./redux/Action";
import TablePagination from "@mui/material/TablePagination";
import Search from "../common/SearchBar";
import CustomTableRow from "./CustomTableRow";

const columns = [
  {
    id: "title",
    label: "Title",
    minWidth: 100,
  },
  {
    id: "category",
    label: "Category",
    minWidth: 20,
    align: "center",
  },
  {
    id: "type",
    label: "Type",
    minWidth: 20,
    align: "center",
  },
  {
    id: "name",
    label: "Name",
    minWidth: 20,
    align: "center",
  },
  {
    id: "created_at",
    label: "Created At",
    minWidth: 20,
    align: "center",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 20,
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    minWidth: "20",
    align: "center",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    "& .MuiTableCell-stickyHeader": {
      background: theme.palette.background.dark,
      //   color: theme.palette.primary.dark,
    },
    "& .MuiTableBody-root": {
      "& tr:nth-of-type(odd)": {
        background: theme.palette.background.main,
      },
    },
  },
  delete: {
    color: "#ff6363",
    cursor: "pointer",
    marginLeft: "10px",
    "&:hover": {
      color: "red",
    },
  },
  modalDelete: {
    color: "#ff6363",
    cursor: "pointer",
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: "#DB143C !important",
    },
  },
  edit: {
    color: "#15c388",
    cursor: "pointer",
    "&:hover": {
      color: "green",
    },
  },
  container: {
    maxHeight: 550,
    borderRadius: "5px",
  },
}));

function ListRental() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const allRental = useSelector((state) => state.rentals);

  const rows =
    allRental?.allRental?.data?.map((data) => {
      return { ...data, name: data?.owner?.name };
    }) || [];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [search, setSearch] = useState();
  const [newRows, setNewRows] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    dispatch(getAllRental(page, rowsPerPage));
  }, [page, rowsPerPage]);

  useEffect(() => {
    const newData = rows?.filter((item) => {
      return (
        item?.title?.toLowerCase().includes(search?.toLowerCase()) ||
        item?.category?.title?.toLowerCase().includes(search?.toLowerCase())
      );
    });
    setNewRows(newData);
  }, [search]);

  const skeleton = () => (
    <>
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
    </>
  );

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          background: "#f8f8f8",
          marginBottom: "15px",
        }}
      >
        <InsideHeader title="Rental">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Rental
            </Link>
            <Typography color="textPrimary">List</Typography>
          </Breadcrumbs>
        </InsideHeader>
        <Search setSearch={setSearch} />
      </Paper>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>S.No.</TableCell>
                  {columns.map((column) => (
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {!allRental?.allRentalLoading && (
                <TableBody>
                  {newRows != ""
                    ? (newRows || []).map((row, index) => {
                        return (
                          <CustomTableRow
                            page={page}
                            rowsPerPage={rowsPerPage}
                            key={index}
                            index={index}
                            columns={columns}
                            row={row}
                            classes={classes}
                          />
                        );
                      })
                    : rows.map((row, index) => {
                        return (
                          <CustomTableRow
                            page={page}
                            rowsPerPage={rowsPerPage}
                            index={index}
                            columns={columns}
                            row={row}
                          />
                        );
                      })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {allRental.allRentalLoading && skeleton()}
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={allRental?.allRental?.meta?.total ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  );
}

export default ListRental;
