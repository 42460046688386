import * as actions from "./Type";

import {
  deleteRentalAmenityApi,
  deleteRentalApi,
  deleteRentalFeatureApi,
  deleteRentalListApi,
  getAllRentalApi,
  getRentalAmenitiesApi,
  getRentalApi,
  getRentalFeatureApi,
  getupdateRentalAmenityApi,
  getupdateRentalApi,
  getupdateRentalFeatureApi,
  postRentalAmenitiesApi,
  postRentalApi,
  postRentalFeatureApi,
  postRentalStatusApi,
  putupdateRentalAmenitiyApi,
  putupdateRentalApi,
  putupdateRentalFeatureApi,
} from "../../../api/rental/RentalApi";

export const postRental =
  (data, handleToggle, reset, handleError) => (dispatch) => {
    dispatch({ type: actions.RENTAL_POST_BEGIN });
    postRentalApi(data)
      .then((res) => {
        dispatch({
          type: actions.RENTAL_POST_SUCCESS,
          payload: res.data,
        });
        handleToggle(false);
        reset();
        dispatch(getRental());
      })
      .catch((err) => {
        console.log(err, "err");
        handleError && handleError(err.response.data?.errors);
        dispatch({
          type: actions.RENTAL_POST_FAIL,
        });
      });
  };

export const getRental = (page, rowsPerPage) => (dispatch) => {
  dispatch({ type: actions.GET_RENTAL_BEGIN });
  getRentalApi(page, rowsPerPage)
    .then((res) => {
      dispatch({
        type: actions.GET_RENTAL_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_RENTAL_FAIL,
      });
    });
};

export const getUpdateRental = (slug) => (dispatch) => {
  dispatch({ type: actions.GET_RENTAL_UPDATE_BEGIN });
  getupdateRentalApi(slug)
    .then((res) => {
      dispatch({
        type: actions.GET_RENTAL_UPDATE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_RENTAL_UPDATE_FAIL,
      });
    });
};

export const putUpdateRental =
  (slug, data, handleToggle, reset, handleError) => (dispatch) => {
    dispatch({ type: actions.PUT_RENTAL_UPDATE_BEGIN });
    putupdateRentalApi(slug, data)
      .then((res) => {
        dispatch({
          type: actions.PUT_RENTAL_UPDATE_SUCCESS,
          payload: res.data,
        });
        handleToggle(false);
        reset();
        dispatch(getRental());
        dispatch({ type: actions.CLEAN_CATEGORY_UPDATE });
      })
      .catch((err) => {
        console.log(err, "err");
        handleError && handleError(err.response.data?.errors);
        dispatch({
          type: actions.PUT_RENTAL_UPDATE_FAIL,
        });
      });
  };

export const deleteRental = (slug, handleClose) => (dispatch) => {
  dispatch({ type: actions.RENTAL_DELETE_BEGIN });
  deleteRentalApi(slug)
    .then((res) => {
      dispatch({
        type: actions.RENTAL_DELETE_SUCCESS,
        payload: res.id,
      });
      handleClose();
      dispatch(getRental());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.RENTAL_DELETE_FAIL,
      });
      handleClose();
      dispatch(getRental());
    });
};

export const postRentalFeature =
  (data, handleToggle, reset, handleError) => (dispatch) => {
    dispatch({ type: actions.POST_RENTAL_FEATURE_BEGIN });
    postRentalFeatureApi(data)
      .then((res) => {
        dispatch({
          type: actions.POST_RENTAL_FEATURE_SUCCESS,
          payload: res.data,
        });
        handleToggle(false);
        reset();
        dispatch(getRentalFeature());
      })
      .catch((err) => {
        console.log(err, "err");
        handleError && handleError(err.response.data?.errors);

        dispatch({
          type: actions.POST_RENTAL_FEATURE_FAIL,
        });
        dispatch(getRentalFeature());
      });
  };

export const getRentalFeature = () => (dispatch) => {
  dispatch({ type: actions.GET_RENTAL_FEATURE_BEGIN });
  getRentalFeatureApi()
    .then((res) => {
      dispatch({
        type: actions.GET_RENTAL_FEATURE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_RENTAL_FEATURE_FAIL,
      });
    });
};

export const getUpdateRentalFeature = (id) => (dispatch) => {
  dispatch({ type: actions.GET_RENTAL_FEATURE_UPDATE_BEGIN });
  getupdateRentalFeatureApi(id)
    .then((res) => {
      dispatch({
        type: actions.GET_RENTAL_FEATURE_UPDATE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_RENTAL_FEATURE_UPDATE_FAIL,
      });
    });
};

export const putUpdateRentalFeature =
  (id, data, handleToggle, reset, handleError) => (dispatch) => {
    dispatch({ type: actions.PUT_RENTAL_FEATURE_UPDATE_BEGIN });
    putupdateRentalFeatureApi(id, data)
      .then((res) => {
        dispatch({
          type: actions.PUT_RENTAL_FEATURE_UPDATE_SUCCESS,
          payload: res.data,
        });
        handleToggle(false);
        reset();
        dispatch(getRentalFeature());
        dispatch({ type: actions.CLEAN_FEATURE_UPDATE });
      })
      .catch((err) => {
        console.log(err, "err");
        handleError && handleError(err.response.data?.errors);

        dispatch({
          type: actions.PUT_RENTAL_FEATURE_UPDATE_FAIL,
        });
      });
  };

export const deleteRentalFeature = (id, handleClose) => (dispatch) => {
  dispatch({ type: actions.RENTAL_FEATURE_DELETE_BEGIN });
  deleteRentalFeatureApi(id)
    .then((res) => {
      dispatch({
        type: actions.RENTAL_FEATURE_DELETE_SUCCESS,
        payload: res.id,
      });
      handleClose();
      dispatch(getRentalFeature());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.RENTAL_FEATURE_DELETE_FAIL,
      });
      handleClose();
      dispatch(getRentalFeature());
    });
};

export const postRentalAmenities =
  (data, handleToggle, reset, handleError) => (dispatch) => {
    dispatch({ type: actions.POST_RENTAL_AMENITIES_BEGIN });
    postRentalAmenitiesApi(data)
      .then((res) => {
        dispatch({
          type: actions.POST_RENTAL_AMENITIES_SUCCESS,
          payload: res.data,
        });
        handleToggle(false);
        reset();
        dispatch(getRentalAmenities());
      })
      .catch((err) => {
        console.log(err, "err");
        handleError && handleError(err.response.data?.errors);
        dispatch({
          type: actions.POST_RENTAL_AMENITIES_FAIL,
        });
        dispatch(getRentalAmenities());
      });
  };

export const getRentalAmenities = () => (dispatch) => {
  dispatch({ type: actions.GET_RENTAL_AMENITIES_BEGIN });
  getRentalAmenitiesApi()
    .then((res) => {
      dispatch({
        type: actions.GET_RENTAL_AMENITIES_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_RENTAL_AMENITIES_FAIL,
      });
    });
};

export const getUpdateRentalAmenity = (id) => (dispatch) => {
  dispatch({ type: actions.GET_RENTAL_AMENITY_UPDATE_BEGIN });
  getupdateRentalAmenityApi(id)
    .then((res) => {
      dispatch({
        type: actions.GET_RENTAL_AMENITY_UPDATE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_RENTAL_AMENITY_UPDATE_FAIL,
      });
    });
};

export const putUpdateRentalAmenity =
  (id, data, handleToggle, reset, handleError) => (dispatch) => {
    dispatch({ type: actions.PUT_RENTAL_AMENITY_UPDATE_BEGIN });
    putupdateRentalAmenitiyApi(id, data)
      .then((res) => {
        dispatch({
          type: actions.PUT_RENTAL_AMENITY_UPDATE_SUCCESS,
          payload: res.data,
        });
        handleToggle(false);
        reset();
        dispatch(getRentalAmenities());
        dispatch({ type: actions.CLEAN_AMENITIES_UPDATE });
      })
      .catch((err) => {
        console.log(err, "err");
        handleError && handleError(err.response.data?.errors);

        dispatch({
          type: actions.PUT_RENTAL_AMENITY_UPDATE_FAIL,
        });
      });
  };

export const deleteRentalAmenity = (id, handleClose) => (dispatch) => {
  dispatch({ type: actions.RENTAL_AMENITY_DELETE_BEGIN });
  deleteRentalAmenityApi(id)
    .then((res) => {
      dispatch({
        type: actions.RENTAL_AMENITY_DELETE_SUCCESS,
        payload: res.id,
      });
      handleClose();
      dispatch(getRentalAmenities());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.RENTAL_AMENITY_DELETE_FAIL,
      });
      handleClose();
      dispatch(getRentalAmenities());
    });
};

// ----------------------------------------------------------------------------

export const getAllRental = (page, rowsPerPage) => (dispatch) => {
  dispatch({ type: actions.GET_ALL_RENTAL_BEGIN });
  getAllRentalApi(page, rowsPerPage)
    .then((res) => {
      dispatch({
        type: actions.GET_ALL_RENTAL_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_ALL_RENTAL_FAIL,
      });
    });
};

export const postRentalStatus = (slug, data) => (dispatch) => {
  dispatch({ type: actions.POST_RENTAL_STATUS_BEGIN });
  postRentalStatusApi(slug, data)
    .then((res) => {
      dispatch({
        type: actions.POST_RENTAL_STATUS_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllRental());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.POST_RENTAL_STATUS_FAIL,
      });
    });
};

// delete rental list
export const deleteRentalList = (slug, handleClose) => (dispatch) => {
  dispatch({ type: actions.RENTAL_LIST_DELETE_BEGIN });
  deleteRentalListApi(slug)
    .then((res) => {
      dispatch({
        type: actions.RENTAL_LIST_DELETE_SUCCESS,
        payload: res.id,
      });
      handleClose();
      dispatch(getAllRental(0, 10));
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.RENTAL_LIST_DELETE_FAIL,
      });
      handleClose();
    });
};
