import React from "react";
import { useParams } from "react-router-dom";
import PageNotFound from "../../pages/PageNotFound/Index";
import BlogPageAds from "./BlogPageAds";
import BusinessPageAds from "./BusinessPageAds";
import CategoryPromotion from "./CategoryPromotion";
import HomePageAds from "./HomePageAds";
import JobsPageAds from "./JobsPageAds";
import PromotedContent from "./PromotedContent";
import QuickPostPageAds from "./QuickPost";
import RentalsPageAds from "./RentalsPageAds";
import SalePageAds from "./SalePageAds";

const AdsManagementPage = () => {
  const { adsManagementId } = useParams();
  console.log("id", adsManagementId);
  return (
    <>
      {adsManagementId === "homepage" || !adsManagementId ? (
        <HomePageAds />
      ) : adsManagementId === "jobspage" ? (
        <JobsPageAds />
      ) : adsManagementId === "rentalsPage" ? (
        <RentalsPageAds />
      ) : adsManagementId === "salePage" ? (
        <SalePageAds />
      ) : adsManagementId === "businessPage" ? (
        <BusinessPageAds />
      ) : adsManagementId === "blogPage" ? (
        <BlogPageAds />
      ) : adsManagementId === "quickPostPage" ? (
        <QuickPostPageAds />
      ) : adsManagementId === "promotedContent" ? (
        <PromotedContent />
      ) : adsManagementId === "categoryPromotional" ? (
        <CategoryPromotion />
      ) : (
        <PageNotFound />
      )}
    </>
  );
};

export default AdsManagementPage;
