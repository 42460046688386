import React from "react";
import CategoryRental from "./CategoryRental";
import { useParams } from "react-router-dom";
import FeatureRental from "./FeatureRental";
import ListRental from "./ListRental";
import Amenities from "./Amenities";
import PageNotFound from "../../pages/PageNotFound/Index";

const RentalPage = () => {
  const { rentalId } = useParams();
  return (
    <>
      {rentalId === "category" || !rentalId ? (
        <CategoryRental />
      ) : rentalId === "features" ? (
        <FeatureRental />
      ) : rentalId === "lists" ? (
        <ListRental />
      ) : rentalId === "amenities" ? (
        <Amenities />
      ) : (
        <PageNotFound />
      )}
    </>
  );
};

export default RentalPage;
