import * as actions from './Type'


const initialState = {
    isLoading: false,
    deleteLoading: false,
    updateLoading: false,
    statusLoading: false,
    blogs: [],
    updateBlog: []
};

export const blogReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.BLOG_POST_BEGIN:
            return {
                ...state,
                isLoading: true,
            };

        case actions.BLOG_POST_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case actions.BLOG_POST_FAIL:
            return {
                ...state,
                isLoading: false
            }

        case actions.GET_BLOG_BEGIN:
            return {
                ...state,
                isLoading: true,
            };

        case actions.GET_BLOG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                blogs: action.payload.data
            };
        case actions.GET_BLOG_FAIL:
            return {
                ...state,
                isLoading: false
            }

        case actions.BLOG_DELETE_BEGIN:
            return {
                ...state,
                deleteLoading: true,
            };

        case actions.BLOG_DELETE_SUCCESS:
            return {
                ...state,
                deleteLoading: false,
            };
        case actions.BLOG_DELETE_FAIL:
            return {
                ...state,
                deleteLoading: false
            }

        case actions.GET_BLOG_UPDATE_BEGIN:
            return {
                ...state,
                updateLoading: true,
            }

        case actions.GET_BLOG_UPDATE_SUCCESS:
            return {
                ...state,
                updateBlog: action.payload,
                updateLoading: false,
            }

        case actions.GET_BLOG_UPDATE_FAIL:
            return {
                ...state,
                updateLoading: false
            }

        case actions.PUT_BLOG_UPDATE_BEGIN:
            return {
                ...state,
                updateLoading: true,
            }

        case actions.PUT_BLOG_UPDATE_SUCCESS:
            return {
                ...state,
                updateBlog: action.payload,
                updateLoading: false,
            }

        case actions.PUT_BLOG_UPDATE_FAIL:
            return {
                ...state,
                updateLoading: false
            }

        case actions.POST_BLOG_STATUS_BEGIN:
            return {
                ...state,
                statusLoading: true,
            }

        case actions.POST_BLOG_STATUS_SUCCESS:
            return {
                ...state,
                statusLoading: false,
            }

        case actions.POST_BLOG_STATUS_FAIL:
            return {
                ...state,
                statusLoading: false
            }


        case actions.CLEAN_UPDATE_BLOG:
            return {
                ...state,
                updateBlog: []
            }

        default:
            return state
    }
}