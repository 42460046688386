export const DASHBOARD = "dashboard";
export const USER = "user";
export const CREATE_ADMIN = "create-admin";
export const RENTAL_CATEGORY = "rental-category";
export const RENTAL_FEATURE = "rental-feature";
export const RENTAL_AMENITY = "rental-amenity";
export const RENTAL = "rental";
export const SALE_CATEGORY = "rental-category";
export const SALE_FEATURE = "rental-feature";
export const SALE_AMENITY = "rental-amenity";
export const SALE = "sale";
export const JOB = "job";
export const REWARD = "reward";
export const LOOKING_FOR="looking"
export const JOB_CATEGORY = "job-category";
export const BUSINESS_CATEGORY = "business-category";
export const JOB_LIST = "job-list";
export const BUSINESS_LIST = "business-list";
export const RENTAL_LIST = "rental-list";
export const SALE_LIST = "rental-list";

export const BUSINESS = "business";
export const BLOG = "blog";
export const QUICK_POST = "quick-post";
export const SITE_SETTING = "site-setting";
export const BANNER = "banner";
export const CHANGE_PASSWORD = "change-password";
export const CHANGE_ADMIN_PASSWORD = "change-admin-password";
export const FALLBACK_IMAGE = "fallback-image";
export const ADS_MANAGEMENT = "ads-management";
export const HOME_ADS = "ad-home-page";
export const JOB_ADS = "ad-job-page";
export const RENTAL_ADS = "ad-rental-page";
export const BUSINESS_ADS = "ad-business-page";
export const PROMOTED_CONTENT_ADS = "ad-promoted-content";
export const BLOG_ADS = "ad-blog-page";
export const CATEGORY_PROMOTIONAL_ADS = "promoted-post";

export const QUICK_POST_ADS = "ad-quick-post-page";
export const PROMOTED_CONTENT = "promoted-content";
export const CONTACT_US = "contact-us";
export const NEWSLETTER = "newsletter";
export const USER_REVIEW = "user-review";
export const PERMISSION = "permission";
export const COUNTRY = "country";
