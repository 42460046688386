import React from 'react'
import RentalPage from '../../components/rental/Index'

const Rental = () => {
    return (
        <>
            <RentalPage />
        </>
    )
}

export default Rental
