import React from "react";
import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { useStyles } from "./styles";
import Chart from "./Chart";

const DashboardBox = (props) => {
  const { title, icons, active, inactive, total, isLoading } = props;

  const classes = useStyles();

  console.log("Icons0--->", icons);

  return (
    <>
      <Paper className={classes.root}>
        {!isLoading && (
          <div className={classes.content}>
            <div className={classes.details}>
              <Typography variant="h4">
                {title} {isLoading}
              </Typography>
              <Typography variant="h5">{total}</Typography>
              {title === "Houses" ||
              title === "Jobs" ||
              title === "Business" ||
              title === "Users" ? (
                <Box>
                  <Typography>Active: {active}</Typography>
                  <Typography>Inactive: {inactive}</Typography>
                </Box>
              ) : null}
            </div>
          </div>
        )}
        {isLoading && <CircularProgress />}
      </Paper>
      <Grid
        item
        sm={12}
        sx={{
          height: "200px",
          width: "200px",
          marginTop: "30px",
        }}
        align="center"
      >
        <Chart active={active} inactive={inactive} />
      </Grid>
    </>
  );
};

export default DashboardBox;
