import { axiosInstance } from "../../utils/axiosInstance";
import { handleError, handleResponse } from "../apiResponse";

export function getUsersApi(id) {
  return axiosInstance().get(`/admin/client-list`).catch(handleError);
}

export function postUserStatusApi(id, data) {
  return axiosInstance()
    .post(`/admin/user/${id}/change-active`, data)
    .then(handleResponse)
    .catch(handleError);
}

export function getUserClientListApi(id) {
  return axiosInstance()
    .get(`/api/client-list/${id}`)
    .then()
    .catch(handleError);
}
