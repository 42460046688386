import { Breadcrumbs, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import InsideHeader from "../../layout/InsideHeader";
import CommonAdCard from "./CommonAdCard";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { getAds, getAdsCategory } from "./redux/Action";
import Loader from "../../pages/Home/Loader";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BusinessPageAds = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ads = useSelector((state) => state.ads);
  const adsCategory = useSelector((state) => state.adsCategory);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getAdsCategory());
    dispatch(getAds());
  }, []);

  return ads === "null" || ads?.isLoading ? (
    <Loader />
  ) : (
    <>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          background: "#f8f8f8",
          marginBottom: "15px",
        }}
      >
        <InsideHeader title="Business Page Ads">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Ads Management
            </Link>
            <Typography color="textPrimary">Business Page ads</Typography>
          </Breadcrumbs>
        </InsideHeader>
      </Paper>

      <Paper
        elevation={1}
        className={classes.paper}
        sx={{ padding: "10px 20px", marginBottom: "30px" }}
      >
        <div className={classes.root}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  sx={{ fontSize: "small", fontWeight: "600" }}
                  label="Business Listing Page Ads"
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{ fontSize: "small", fontWeight: "600" }}
                  label="Business Listing Archive Ads"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Grid container spacing={4}>
                <Grid item sm={6}>
                  <CommonAdCard
                    category="Business Listing"
                    title="Top Ad"
                    height="179px"
                    data={ads?.ads?.find(
                      (item) =>
                        item.ad_category === "Business Listing" &&
                        item.title === "Top Ad"
                    )}
                  />
                </Grid>
                <Grid item sm={6}>
                  <CommonAdCard
                    category="Business Listing"
                    title="Between Ad"
                    height="179px"
                    data={ads?.ads?.find(
                      (item) =>
                        item.ad_category === "Business Listing" &&
                        item.title === "Between Ad"
                    )}
                  />
                </Grid>
                <Grid item sm={6}>
                  <CommonAdCard
                    category="Business Listing"
                    title="Below Ads-1"
                    height="179px"
                    data={ads?.ads?.find(
                      (item) =>
                        item.ad_category === "Business Listing" &&
                        item.title === "Below Ads-1"
                    )}
                  />
                </Grid>
                <Grid item sm={6}>
                  <CommonAdCard
                    category="Business Listing"
                    title="Below Ads-2"
                    height="179px"
                    data={ads?.ads?.find(
                      (item) =>
                        item.ad_category === "Business Listing" &&
                        item.title === "Below Ads-2"
                    )}
                  />
                </Grid>
                <Grid item sm={6}>
                  <CommonAdCard
                    category="Business Listing"
                    title="Sidebar Ads-1"
                    height="179px"
                    data={ads?.ads?.find(
                      (item) =>
                        item.ad_category === "Business Listing" &&
                        item.title === "Sidebar Ads-1"
                    )}
                  />
                </Grid>
                <Grid item sm={6}>
                  <CommonAdCard
                    category="Business Listing"
                    title="Sidebar Ads-2"
                    height="179px"
                    data={ads?.ads?.find(
                      (item) =>
                        item.ad_category === "Business Listing" &&
                        item.title === "Sidebar Ads-2"
                    )}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid container spacing={4}>
                <Grid item sm={6}>
                  <CommonAdCard
                    category="Business Listing Archive"
                    title="Top Ad"
                    height="179px"
                    data={ads?.ads?.find(
                      (item) =>
                        item.ad_category === "Business Listing Archive" &&
                        item.title === "Top Ad"
                    )}
                  />
                </Grid>
                <Grid item sm={6}>
                  <CommonAdCard
                    category="Business Listing Archive"
                    title="Between Ad"
                    height="179px"
                    data={ads?.ads?.find(
                      (item) =>
                        item.ad_category === "Business Listing Archive" &&
                        item.title === "Between Ad"
                    )}
                  />
                </Grid>

                <Grid item sm={6}>
                  <CommonAdCard
                    category="Business Listing Archive"
                    title="Below Ad"
                    height="179px"
                    data={ads?.ads?.find(
                      (item) =>
                        item.ad_category === "Business Listing Archive" &&
                        item.title === "Below Ad"
                    )}
                  />
                </Grid>

                <Grid item sm={4}>
                  <CommonAdCard
                    category="Business Listing Archive"
                    title="Side Ads-1"
                    height="179px"
                    data={ads?.ads?.find(
                      (item) =>
                        item.ad_category === "Business Listing Archive" &&
                        item.title === "Side Ads-1"
                    )}
                  />
                </Grid>
              </Grid>
            </TabPanel>
          </Box>
        </div>
      </Paper>
    </>
  );
};

export default BusinessPageAds;
