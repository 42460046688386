import * as actions from "./Type";

import {
  deleteSaleCategoryApi,
  deleteSaleFeatureApi,
  deleteSaleListApi,
  getAllSaleApi,
  getSaleCategoryApi,
  getSaleCategoryBySlugApi,
  getSaleFeatureApi,
  getSingleSaleFeatureApi,
  postSaleCategoryApi,
  postSaleFeatureApi,
  updateSaleCategoryApi,
  updateSaleFeatureApi,
  updateSaleStatusApi,
} from "../../../api/sale/SaleApi";

// sale category
export const postSaleCategory =
  (data, handleToggle, reset, handleError) => (dispatch) => {
    dispatch({ type: actions.SALE_POST_BEGIN });
    postSaleCategoryApi(data)
      .then((res) => {
        dispatch({
          type: actions.SALE_POST_SUCCESS,
          payload: res.data,
        });
        handleToggle(false);
        reset();
        dispatch(getSaleCategory());
      })
      .catch((err) => {
        console.log(err, "err");
        handleError && handleError(err.response.data?.errors);
        dispatch({
          type: actions.SALE_POST_FAIL,
        });
      });
  };

export const getSaleCategory = (page, rowsPerPage) => (dispatch) => {
  dispatch({ type: actions.GET_SALE_BEGIN });
  getSaleCategoryApi(page, rowsPerPage)
    .then((res) => {
      dispatch({
        type: actions.GET_SALE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_SALE_FAIL,
      });
    });
};

export const getSaleCategoryBySlug = (slug) => (dispatch) => {
  dispatch({ type: actions.GET_SALE_UPDATE_BEGIN });
  getSaleCategoryBySlugApi(slug)
    .then((res) => {
      dispatch({
        type: actions.GET_SALE_UPDATE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_SALE_UPDATE_FAIL,
      });
    });
};

export const updateSaleCategory =
  (slug, data, handleToggle, reset, handleError) => (dispatch) => {
    dispatch({ type: actions.PUT_SALE_UPDATE_BEGIN });
    updateSaleCategoryApi(slug, data)
      .then((res) => {
        dispatch({
          type: actions.PUT_SALE_UPDATE_SUCCESS,
          payload: res.data,
        });
        handleToggle(false);
        reset();
        dispatch(getSaleCategory());
        dispatch({ type: actions.CLEAN_CATEGORY_UPDATE });
      })
      .catch((err) => {
        console.log(err, "err");
        handleError && handleError(err.response.data?.errors);
        dispatch({
          type: actions.PUT_SALE_UPDATE_FAIL,
        });
      });
  };

export const deleteSaleCategory = (slug, handleClose) => (dispatch) => {
  dispatch({ type: actions.SALE_DELETE_BEGIN });
  deleteSaleCategoryApi(slug)
    .then((res) => {
      dispatch({
        type: actions.SALE_DELETE_SUCCESS,
        payload: res.id,
      });
      handleClose();
      dispatch(getSaleCategory());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.SALE_DELETE_FAIL,
      });
      handleClose();
    });
};

// sale feature
export const postSaleFeature =
  (data, handleToggle, reset, handleError) => (dispatch) => {
    dispatch({ type: actions.POST_SALE_FEATURE_BEGIN });
    postSaleFeatureApi(data)
      .then((res) => {
        dispatch({
          type: actions.POST_SALE_FEATURE_SUCCESS,
          payload: res.data,
        });
        handleToggle(false);
        reset();
        dispatch(getSaleFeature());
      })
      .catch((err) => {
        handleError && handleError(err.response.data?.errors);
        dispatch({
          type: actions.POST_SALE_FEATURE_FAIL,
        });
      });
  };

export const getSaleFeature = () => (dispatch) => {
  dispatch({ type: actions.GET_SALE_FEATURE_BEGIN });
  getSaleFeatureApi()
    .then((res) => {
      dispatch({
        type: actions.GET_SALE_FEATURE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_SALE_FEATURE_FAIL,
      });
    });
};

export const getSingleSaleFeature = (id) => (dispatch) => {
  dispatch({ type: actions.GET_SALE_FEATURE_UPDATE_BEGIN });
  getSingleSaleFeatureApi(id)
    .then((res) => {
      dispatch({
        type: actions.GET_SALE_FEATURE_UPDATE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_SALE_FEATURE_UPDATE_FAIL,
      });
    });
};

export const updateSaleFeature =
  (id, data, handleToggle, reset, handleError) => (dispatch) => {
    dispatch({ type: actions.PUT_SALE_FEATURE_UPDATE_BEGIN });
    updateSaleFeatureApi(id, data)
      .then((res) => {
        dispatch({
          type: actions.PUT_SALE_FEATURE_UPDATE_SUCCESS,
          payload: res.data,
        });
        handleToggle(false);
        reset();
        dispatch(getSaleFeature());
        dispatch({ type: actions.CLEAN_FEATURE_UPDATE });
      })
      .catch((err) => {
        console.log(err, "err");
        handleError && handleError(err.response.data?.errors);

        dispatch({
          type: actions.PUT_SALE_FEATURE_UPDATE_FAIL,
        });
      });
  };

export const deleteSaleFeature = (id, handleClose) => (dispatch) => {
  dispatch({ type: actions.SALE_FEATURE_DELETE_BEGIN });
  deleteSaleFeatureApi(id)
    .then((res) => {
      dispatch({
        type: actions.SALE_FEATURE_DELETE_SUCCESS,
        payload: res.id,
      });
      handleClose();
      dispatch(getSaleFeature());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.SALE_FEATURE_DELETE_FAIL,
      });
      handleClose();
      dispatch(getSaleFeature());
    });
};

// ----------------- get Sale list

export const getAllSale = (page, rowsPerPage) => (dispatch) => {
  dispatch({ type: actions.GET_ALL_SALE_BEGIN });
  getAllSaleApi(page, rowsPerPage)
    .then((res) => {
      dispatch({
        type: actions.GET_ALL_SALE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_ALL_SALE_FAIL,
      });
    });
};

export const updateSaleStatus = (slug, data) => (dispatch) => {
  dispatch({ type: actions.POST_SALE_STATUS_BEGIN });
  updateSaleStatusApi(slug, data)
    .then((res) => {
      dispatch({
        type: actions.POST_SALE_STATUS_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllSale());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.POST_SALE_STATUS_FAIL,
      });
    });
};

// delete rental list
export const deleteSaleList = (slug, handleClose) => (dispatch) => {
  dispatch({ type: actions.SALE_LIST_DELETE_BEGIN });
  deleteSaleListApi(slug)
    .then((res) => {
      dispatch({
        type: actions.SALE_LIST_DELETE_SUCCESS,
        payload: res.id,
      });
      handleClose();
      dispatch(getAllSale(0, 10));
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.SALE_LIST_DELETE_FAIL,
      });
      handleClose();
    });
};
