import {
  deleteQuickApi,
  getQuickApi,
  postQuickStatusApi,
} from "../../../api/quick/QuicksApi";
import * as actions from "./Type";

export const getQuick = (page, rowsPerPage) => (dispatch) => {
  dispatch({ type: actions.GET_QUICK_BEGIN });
  getQuickApi(page, rowsPerPage)
    .then((res) => {
      dispatch({
        type: actions.GET_QUICK_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_QUICK_FAIL,
      });
    });
};

export const deleteQuick = (id, handleClose) => (dispatch) => {
  dispatch({ type: actions.QUICK_DELETE_BEGIN });
  deleteQuickApi(id)
    .then((res) => {
      dispatch({
        type: actions.QUICK_DELETE_SUCCESS,
        payload: res.id,
      });
      handleClose();
      dispatch(getQuick());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.QUICK_DELETE_FAIL,
      });
    });
};

export const postQuickStatus = (slug, status) => (dispatch) => {
  dispatch({ type: actions.POST_QUICK_STATUS_BEGIN });
  postQuickStatusApi(slug, status)
    .then((res) => {
      dispatch({
        type: actions.POST_QUICK_STATUS_SUCCESS,
        payload: res.data,
      });
      dispatch(getQuick());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.POST_QUICK_STATUS_FAIL,
      });
    });
};
