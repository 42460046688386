import {
  Box,
  Breadcrumbs,
  Button,
  Modal,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";

import { Link } from "react-router-dom";
import InsideHeader from "../../layout/InsideHeader";
import { useDispatch, useSelector } from "react-redux";
import { deleteContact, getContact } from "./redux/Action";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomModal from "../common/customModal/CustomModal";
import { formatDate } from "../../utils/TimestamptoDate";

const columns = [
  {
    id: "name",
    label: "Name",
    minWidth: 100,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 20,
    align: "center",
  },
  {
    id: "phone_number",
    label: "Phone Number",
    minWidth: 20,
    align: "center",
  },
  {
    id: "message",
    label: "Messages",
    minWidth: 20,
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 20,
    align: "center",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    "& .MuiTableCell-stickyHeader": {
      background: theme.palette.background.dark,
    },
    "& .MuiTableBody-root": {
      "& tr:nth-of-type(odd)": {
        background: theme.palette.background.main,
      },
    },
  },
  delete: {
    color: "#ff6363",
    cursor: "pointer",
    marginLeft: "10px",
    "&:hover": {
      color: "red",
    },
  },
  modalDelete: {
    color: "#ff6363",
    cursor: "pointer",
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: "#DB143C !important",
    },
  },
  edit: {
    color: "#15c388",
    cursor: "pointer",
    "&:hover": {
      color: "green",
    },
  },
  container: {
    maxHeight: 550,
    borderRadius: "5px",
  },
}));

const ContactUs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [slug, setSlug] = useState();
  const [open, setOpen] = useState(false);
  const [descriptionOpen, setDiscriptionOpen] = useState(false);

  const handleDescription = () => {
    setDiscriptionOpen((prev) => !prev);
  };

  const contact = useSelector((state) => state.contacts);

  console.log("Contact is ", contact);
  const rows = contact?.contact?.data || [];

  console.log("Rows in contact us page are ", rows);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    dispatch(getContact());
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleModal = (id) => {
    // setSlug(id);
    handleOpen();
  };

  const handleDelete = () => {
    //   slug && dispatch(deleteRental(slug, handleClose));
    dispatch(deleteContact(slug));
    handleClose();
  };

  const skeleton = () => (
    <>
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
    </>
  );

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          background: "#f8f8f8",
          marginBottom: "15px",
        }}
      >
        <InsideHeader title="Contact Us">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Contact
            </Link>
            <Typography color="textPrimary">Contact Us</Typography>
          </Breadcrumbs>
        </InsideHeader>
      </Paper>

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>S.No.</TableCell>
                {columns.map((column) => (
                  <TableCell
                    sx={{ fontWeight: "bold" }}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {!contact?.isLoading && (
              <TableBody>
                {rows
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell>{index + 1}</TableCell>
                        {columns.map((column, ind) => {
                          const value = row[column.id];
                          const id = row?.id;

                          const title = row?.phone;
                          return columns?.length !== ind + 1 &&
                            column?.id !== "message" ? (
                            <TableCell key={column.id} align={column.align}>
                              {value || title}
                            </TableCell>
                          ) : column?.id == "message" ? (
                            <TableCell key={column.id} align={column.align}>
                              <Button
                                variant="contained"
                                onClick={handleDescription}
                              >
                                {" "}
                                Show Message
                              </Button>
                              <CustomDescriptionModal
                                open={descriptionOpen}
                                data={row}
                                handleClose={handleDescription}
                              />
                            </TableCell>
                          ) : (
                            <TableCell
                              align={column.align}
                              key={column.id}
                              sx={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <Tooltip title="Delete" arrow>
                                <span>
                                  <DeleteIcon
                                    className={classes.delete}
                                    onClick={() => {
                                      handleModal();
                                      setSlug(id);
                                    }}
                                  />
                                </span>
                              </Tooltip>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {contact.isLoading && skeleton()}

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <CustomModal
          open={open}
          handleClose={handleClose}
          handleDelete={handleDelete}
          deleteStyle={classes.modalDelete}
          loading={contact?.isLoading}
        />
      </Paper>
    </>
  );
};

export default ContactUs;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  backgroundColor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CustomDescriptionModal = ({ open, handleClose, data }) => {
  console.log(data, "formData");
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <h5 style={{ textAlign: "center", marginBottom: "none" }}>Message</h5>
        <h5 style={{ marginTop: "0", marginBottom: "0" }}>
          Name: {data?.name}
        </h5>
        <h5 style={{ marginTop: "0", marginBottom: "0" }}>
          Email: {data?.email}
        </h5>
        <h5 style={{ marginTop: "0", marginBottom: "0" }}>
          Phone Number: {data?.phone}
        </h5>
        <h5 style={{ marginTop: "0", marginBottom: "0" }}>
          Date: {formatDate(data?.created_at, "YYYY-MM-DD HH:MM")}
        </h5>
        <p>{data?.message}</p>
        <Box textAlign="center">
          <Button variant="contained" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
