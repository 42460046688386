import {
  Breadcrumbs,
  Button,
  Grid,
  Modal,
  Paper,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import InsideHeader from '../../layout/InsideHeader';
import { makeStyles } from '@mui/styles';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../pages/Home/Loader';
import CustomTableRow from './CustomTableRow';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { getUserClientList } from './redux/Action';
import CustomJobRow from './IndividualUserDetail/CustomJobRow';
import CustomBusinessRow from './IndividualUserDetail/CustomBusinessRow';
import CustomBlogRow from './IndividualUserDetail/CustomBlogRow';
import CustomRentalRow from './IndividualUserDetail/CustomRentalRow';
import CustomQuickPostRow from './IndividualUserDetail/CustomQuickPostRow';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0),
  },
  tableRoot: {
    width: '100%',
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    '& .MuiTableCell-stickyHeader': {
      background: theme.palette.background.dark,
      //   color: theme.palette.primary.dark,
    },
    '& .MuiTableBody-root': {
      '& tr:nth-of-type(odd)': {
        background: theme.palette.background.main,
      },
    },
  },
  delete: {
    color: '#ff6363',
    cursor: 'pointer',
    '&:hover': {
      color: 'red',
    },
  },
  container: {
    maxHeight: 550,
    borderRadius: '5px',
  },
}));

const columns = [
  {
    id: 'name',
    label: 'Name',
    minWidth: 50,
  },

  {
    id: 'email',
    label: 'Email ',
    minWidth: 50,
  },
  {
    id: 'inquiry',
    label: 'Inquiry',
    minWidth: 50,
  },

  {
    id: 'action',
    label: 'Action',
    minWidth: 100,
    align: 'center',
  },
];

const jobColumns = [
  {
    id: 'title',
    label: 'Title',
    minWidth: 50,
  },

  // {
  //   id: "salary",
  //   label: "Salary ",
  //   minWidth: 50,
  // },
  {
    id: 'inquiry',
    label: 'Inquiry',
    minWidth: 50,
  },

  {
    id: 'action',
    label: 'Action',
    minWidth: 100,
    align: 'center',
  },
];

const businessColumns = [
  {
    id: 'title',
    label: 'Title',
    minWidth: 50,
  },

  {
    id: 'review',
    label: 'Review',
    minWidth: 50,
  },

  {
    id: 'action',
    label: 'Action',
    minWidth: 100,
    align: 'center',
  },
];
const rentalColumns = [
  {
    id: 'title',
    label: 'Title',
    minWidth: 50,
  },

  {
    id: 'description',
    label: 'Description',
    minWidth: 50,
  },

  {
    id: 'action',
    label: 'Action',
    minWidth: 100,
    align: 'center',
  },
];

const blogColumns = [
  {
    id: 'title',
    label: 'Title',
    minWidth: 50,
  },

  {
    id: 'created_at',
    label: 'Created At',
    minWidth: 150,
  },
  {
    id: 'description',
    label: 'Description',
    minWidth: 200,
  },

  {
    id: 'action',
    label: 'Action',
    minWidth: 100,
    align: 'center',
  },
];

const quickPostColumns = [
  {
    id: 'title',
    label: 'Title',
    minWidth: 50,
  },

  {
    id: 'created_at',
    label: 'Created At',
    minWidth: 150,
  },
  {
    id: 'description',
    label: 'Description',
    minWidth: 200,
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const HomePageAds = () => {
  const classes = useStyles();
  const { id } = useParams();

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const ads = useSelector((state) => state.ads);

  const [value, setValue] = React.useState(0);
  const { individualUser, individualUserLoading } = useSelector(
    (state) => state.users
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => setOpen(false);
  console.log('id', id, individualUser, individualUserLoading);
  useEffect(() => {
    id && dispatch(getUserClientList(id));
  }, []);
  return individualUserLoading ? (
    <>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          background: '#f8f8f8',
          marginBottom: '15px',
        }}>
        <InsideHeader title="Detail">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" to="/users">
              User
            </Link>
            <Typography color="textPrimary">Detail</Typography>
          </Breadcrumbs>
        </InsideHeader>
      </Paper>
      <Paper
        elevation={1}
        className={classes.paper}
        sx={{ padding: '10px 20px', marginBottom: '30px' }}>
        <div className={classes.root}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example">
                <Tab
                  sx={{ fontSize: 'small', fontWeight: '600' }}
                  label="Jobs"
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{ fontSize: 'small', fontWeight: '600' }}
                  label="Business"
                  {...a11yProps(1)}
                />
                <Tab
                  sx={{ fontSize: 'small', fontWeight: '600' }}
                  label="Rental"
                  {...a11yProps(2)}
                />
                <Tab
                  sx={{ fontSize: 'small', fontWeight: '600' }}
                  label="Blog"
                  {...a11yProps(3)}
                />
                <Tab
                  sx={{ fontSize: 'small', fontWeight: '600' }}
                  label="Quick Post"
                  {...a11yProps(4)}
                />
              </Tabs>
            </Box>

            <Box>
              <Skeleton animation="wave" height={60} />
              <Skeleton animation="wave" height={60} />
              <Skeleton animation="wave" height={60} />
              <Skeleton animation="wave" height={60} />
            </Box>
          </Box>
        </div>
      </Paper>
    </>
  ) : (
    <>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          background: '#f8f8f8',
          marginBottom: '15px',
        }}>
        <InsideHeader title="Detail">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" to="/users">
              User
            </Link>
            <Typography color="textPrimary">Detail</Typography>
          </Breadcrumbs>
        </InsideHeader>
      </Paper>

      <Paper
        elevation={1}
        className={classes.paper}
        sx={{ padding: '10px 20px', marginBottom: '30px' }}>
        <div className={classes.root}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example">
                <Tab
                  sx={{ fontSize: 'small', fontWeight: '600' }}
                  label="Jobs"
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{ fontSize: 'small', fontWeight: '600' }}
                  label="Business"
                  {...a11yProps(1)}
                />
                <Tab
                  sx={{ fontSize: 'small', fontWeight: '600' }}
                  label="Rental"
                  {...a11yProps(2)}
                />
                <Tab
                  sx={{ fontSize: 'small', fontWeight: '600' }}
                  label="Blog"
                  {...a11yProps(3)}
                />
                <Tab
                  sx={{ fontSize: 'small', fontWeight: '600' }}
                  label="Quick Post"
                  {...a11yProps(4)}
                />
              </Tabs>
            </Box>
            <Grid container spacing={4}>
              <Grid item sm={12}>
                <TabPanel value={value} index={0}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Paper className={classes.tableRoot}>
                      <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold' }}>
                                S.No.
                              </TableCell>
                              {jobColumns.map((column) => (
                                <TableCell
                                  sx={{ fontWeight: 'bold' }}
                                  key={column.id}
                                  align={column.align}
                                  style={{ minWidth: column.minWidth }}>
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {individualUser?.data?.job?.job != ''
                              ? (individualUser?.data?.job?.job || [])
                                  //   .slice(
                                  //     page * rowsPerPage,
                                  //     page * rowsPerPage + rowsPerPage
                                  //   )
                                  .map((row, index) => {
                                    return (
                                      <CustomJobRow
                                        index={index}
                                        columns={jobColumns}
                                        row={row}
                                        type="job"
                                      />
                                    );
                                  })
                              : false}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {/* {users.userLoading && skeleton()} */}
                      {/* <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
                    </Paper>
                  </div>
                </TabPanel>
              </Grid>
            </Grid>
            <TabPanel value={value} index={1}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Paper className={classes.tableRoot}>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 'bold' }}>
                            S.No.
                          </TableCell>
                          {businessColumns.map((column) => (
                            <TableCell
                              sx={{ fontWeight: 'bold' }}
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}>
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {individualUser?.data?.business?.business != ''
                          ? (individualUser?.data?.business?.business || [])
                              //   .slice(
                              //     page * rowsPerPage,
                              //     page * rowsPerPage + rowsPerPage
                              //   )
                              .map((row, index) => {
                                return (
                                  <CustomBusinessRow
                                    index={index}
                                    columns={businessColumns}
                                    row={row}
                                  />
                                  // <TableRow
                                  //   hover
                                  //   role="checkbox"
                                  //   tabIndex={-1}
                                  //   key={row.code}
                                  // >
                                  //   <TableCell>{index + 1}</TableCell>
                                  //   {columns.map((column, ind) => {
                                  //     const value = row[column.id];
                                  //     return columns?.length !== ind + 1 &&
                                  //       column.id === "inquiry" ? (
                                  //       <TableCell
                                  //         key={column.id}
                                  //         align={column.align}
                                  //         onClick={() => setOpen(true)}
                                  //       >
                                  //         {value}
                                  //       </TableCell>
                                  //     ) : column.id === "active" ? (
                                  //       <TableCell
                                  //         align={column.align}
                                  //         key={column.id}
                                  //       >
                                  //         {row?.active?.title}
                                  //       </TableCell>
                                  //     ) : column.id !== "action" ? (
                                  //       <TableCell
                                  //         key={column.id}
                                  //         align={column.align}
                                  //       >
                                  //         {value}
                                  //       </TableCell>
                                  //     ) : (
                                  //       <TableCell
                                  //         key={column.id}
                                  //         align={column.align}
                                  //         sx={{
                                  //           display: "flex",
                                  //           alignItems: "center",
                                  //           justifyContent: "center",
                                  //         }}
                                  //       >
                                  //         {/* <a
                                  //           href={`${frontUrl}/user/house/${row?.profile?.slug}`}
                                  //           target="_blank"
                                  //           rel="noreferrer"
                                  //         > */}
                                  //         <Tooltip
                                  //           arrow
                                  //           title="View profile"
                                  //         >
                                  //           <RemoveRedEyeIcon
                                  //             sx={{
                                  //               cursor: "pointer",
                                  //               color: "#6d9ecd",
                                  //               marginLeft: "20px",
                                  //             }}
                                  //           />
                                  //         </Tooltip>
                                  //         {/* </a> */}
                                  //       </TableCell>
                                  //     );
                                  //   })}
                                  // </TableRow>
                                );
                              })
                          : false}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* {users.userLoading && skeleton()} */}
                  {/* <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
                </Paper>
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Paper className={classes.tableRoot}>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 'bold' }}>
                            S.No.
                          </TableCell>
                          {rentalColumns.map((column) => (
                            <TableCell
                              sx={{ fontWeight: 'bold' }}
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}>
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {individualUser?.data?.house?.house != ''
                          ? (individualUser?.data?.house?.house || [])
                              //   .slice(
                              //     page * rowsPerPage,
                              //     page * rowsPerPage + rowsPerPage
                              //   )
                              .map((row, index) => {
                                return (
                                  <CustomRentalRow
                                    index={index}
                                    columns={rentalColumns}
                                    row={row}
                                    type="house"
                                  />
                                );
                              })
                          : false}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* {users.userLoading && skeleton()} */}
                  {/* <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
                </Paper>
              </div>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Paper className={classes.tableRoot}>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 'bold' }}>
                            S.No.
                          </TableCell>
                          {blogColumns.map((column) => (
                            <TableCell
                              sx={{ fontWeight: 'bold' }}
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}>
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {individualUser?.data?.blog != ''
                          ? (individualUser?.data?.blog || [])
                              //   .slice(
                              //     page * rowsPerPage,
                              //     page * rowsPerPage + rowsPerPage
                              //   )
                              .map((row, index) => {
                                return (
                                  <CustomBlogRow
                                    index={index}
                                    columns={blogColumns}
                                    row={row}
                                  />
                                );
                              })
                          : false}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* {users.userLoading && skeleton()} */}
                  {/* <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
                </Paper>
              </div>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Paper className={classes.tableRoot}>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 'bold' }}>
                            S.No.
                          </TableCell>
                          {quickPostColumns.map((column) => (
                            <TableCell
                              sx={{ fontWeight: 'bold' }}
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}>
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {individualUser?.data?.quick_post != ''
                          ? (individualUser?.data?.quick_post || [])
                              //   .slice(
                              //     page * rowsPerPage,
                              //     page * rowsPerPage + rowsPerPage
                              //   )
                              .map((row, index) => {
                                return (
                                  <CustomQuickPostRow
                                    index={index}
                                    columns={quickPostColumns}
                                    row={row}
                                  />
                                );
                              })
                          : false}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* {users.userLoading && skeleton()} */}
                  {/* <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
                </Paper>
              </div>
            </TabPanel>
          </Box>
        </div>
      </Paper>
    </>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '5px',
  p: 3,
};

export default HomePageAds;
