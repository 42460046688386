import React from "react";
import { TableCell, TableRow, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const CustomTableRow = ({
  index,
  columns,
  row,
  setToggle,
  setValue,
  setFocus,
  setButtonName,
  setOpen,
  setCurrentId,
}) => {
  const handleEdit = (row) => {
    // console.log("row", row);
    setValue("title", row?.title);
    setValue("description", row?.description);
    setValue("id", row?.id);
    setFocus("title");
    setButtonName("Update");
    setToggle(true);
  };

  const handleModal = (id) => {
    setCurrentId(id);
    setOpen(true);
  };

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
      <TableCell>{index + 1}</TableCell>
      {columns.map((column, ind) => {
        const value = row[column.id];
        return columns?.length !== ind + 1 && column.id !== "active" ? (
          <TableCell
            key={column.id}
            align={column.align}
            sx={{ cursor: "pointer" }}
          >
            {value}
          </TableCell>
        ) : column.id === "active" ? (
          <TableCell align={column.align} key={column.id}>
            {row?.active?.title}
          </TableCell>
        ) : (
          <TableCell
            key={column.id}
            align={column.align}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "25px",
            }}
          >
            <Tooltip title="Edit" arrow>
              <span>
                <EditIcon
                  sx={{ color: "#15c388", cursor: "pointer" }}
                  onClick={() => handleEdit(row)}
                />
              </span>
            </Tooltip>
            <Tooltip title="Delete" arrow>
              <span>
                <DeleteIcon
                  sx={{ color: "#ff6363", cursor: "pointer" }}
                  onClick={() => handleModal(row?.id)}
                />
              </span>
            </Tooltip>
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default CustomTableRow;
