export const BLOG_POST_BEGIN = 'BLOG_POST_BEGIN';
export const BLOG_POST_FAIL = 'BLOG_POST_FAIL';
export const BLOG_POST_SUCCESS = 'BLOG_POST_SUCCESS';

export const GET_BLOG_BEGIN = 'GET_BLOG_BEGIN';
export const GET_BLOG_SUCCESS = 'GET_BLOG_SUCCESS';
export const GET_BLOG_FAIL = 'GET_BLOG_FAIL';

export const GET_BLOG_UPDATE_BEGIN = 'GET_BLOG_UPDATE_BEGIN'
export const GET_BLOG_UPDATE_SUCCESS = 'GET_BLOG_UPDATE_SUCCESS'
export const GET_BLOG_UPDATE_FAIL = 'GET_BLOG_UPDATE_FAIL'

export const PUT_BLOG_UPDATE_BEGIN = 'PUT_BLOG_UPDATE_BEGIN'
export const PUT_BLOG_UPDATE_SUCCESS = 'PUT_BLOG_UPDATE_SUCCESS'
export const PUT_BLOG_UPDATE_FAIL = 'PUT_BLOG_UPDATE_FAIL'

export const BLOG_DELETE_BEGIN = 'BLOG_DELETE_BEGIN'
export const BLOG_DELETE_SUCCESS = 'BLOG_DELETE_SUCCESS'
export const BLOG_DELETE_FAIL = 'BLOG_DELETE_FAIL'

export const POST_BLOG_STATUS_BEGIN = 'POST_BLOG_STATUS_BEGIN'
export const POST_BLOG_STATUS_SUCCESS = 'POST_BLOG_STATUS_SUCCESS'
export const POST_BLOG_STATUS_FAIL = 'POST_BLOG_STATUS_FAIL'

export const CLEAN_UPDATE_BLOG = 'CLEAN_UPDATE_BLOG' 