import React from "react";
import { Provider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";

import App from "./App";
import Themes from "./themes";
import Store from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";

import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={Store}>
    <ThemeProvider theme={Themes.default}>
      <CssBaseline />
      <App tab="home" />
    </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
