import React from "react";
import DashboardPage from "../../components/dashboard/Dashboard";
import HasPermission from "../../utils/HasPermission";
import * as permissionList from "../../utils/PermissionList";

function Dashboard() {
  return (
    <HasPermission of={permissionList.DASHBOARD}>
      <DashboardPage />
    </HasPermission>
  );
}

export default Dashboard;
