import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  deleteRentalAmenity,
  getRentalAmenities,
  getUpdateRentalAmenity,
} from './redux/Action';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';

import { Link } from 'react-router-dom';
import { Skeleton, Tooltip } from '@mui/material';
import CustomModal from '../common/customModal/CustomModal';

const columns = [
  {
    id: 'title',
    label: 'Title',
    minWidth: 200,
  },

  {
    id: 'slug',
    label: 'Slug ',
    minWidth: 200,
  },
  {
    id: 'action',
    label: 'Action ',
    minWidth: 30,
    align: 'center',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    '& .MuiTableCell-stickyHeader': {
      background: theme.palette.background.dark,
      //   color: theme.palette.primary.dark,
    },
    '& .MuiTableBody-root': {
      '& tr:nth-of-type(odd)': {
        background: theme.palette.background.main,
      },
    },
  },
  delete: {
    color: '#ff6363',
    cursor: 'pointer',
    '&:hover': {
      color: 'red',
    },
  },
  modalDelete: {
    color: 'white',
    cursor: 'pointer',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: '#DB143C !important',
    },
  },
  edit: {
    color: '#15c388',
    cursor: 'pointer',
    '&:hover': {
      color: 'green',
    },
  },
  container: {
    maxHeight: 550,
    borderRadius: '5px',
  },
}));

function ViewAmenities({ setToggle, setButtonName }) {
  const dispatch = useDispatch();
  const amenities = useSelector((state) => state.rentals);
  const classes = useStyles();
  const rows = amenities?.amenities || [];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [id, setId] = useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleModal = (id) => {
    setId(id);
    handleOpen();
  };

  const handleDelete = () => {
    id && dispatch(deleteRentalAmenity(id, handleClose));
  };

  const handleEdit = (id) => {
    setButtonName('Update');
    setToggle(true);
    dispatch(getUpdateRentalAmenity(id));
  };

  useEffect(() => {
    dispatch(getRentalAmenities());
  }, []);

  const skeleton = () => (
    <>
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
    </>
  );

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>S.No.</TableCell>
              {columns.map((column) => (
                <TableCell
                  sx={{ fontWeight: 'bold' }}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!amenities?.amenitiesLoading && (
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}>
                      <TableCell>{index + 1}</TableCell>
                      {columns.map((column, ind) => {
                        const value = row[column.id];
                        return columns?.length !== ind + 1 ? (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        ) : (
                          <TableCell
                            align={column.align}
                            key={column.id}
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-around',
                            }}>
                            <Tooltip arrow title="Edit">
                              <span>
                                <Link to={`amenities/${row?.id}`}>
                                  <EditIcon
                                    className={classes.edit}
                                    onClick={() => handleEdit(row?.id)}
                                  />
                                </Link>
                              </span>
                            </Tooltip>

                            <Tooltip arrow title="Delete">
                              <span>
                                <DeleteIcon
                                  className={classes.delete}
                                  onClick={() => handleModal(row?.id)}
                                />
                              </span>
                            </Tooltip>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {amenities?.amenitiesLoading && skeleton()}
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <CustomModal
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        deleteStyle={classes.modalDelete}
        loading={amenities?.amenitiesDeleting}
      />
    </Paper>
  );
}

export default ViewAmenities;
