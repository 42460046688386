import * as actions from "./Type";

const initialState = {
  isLoading: false,
  updateLoading: false,
  deleteLoading: false,
  rentalLoding: false,
  featureLoading: false,
  amenitiesLoading: false,
  allRentalLoading: false,
  amenitiesDeleting: false,
  statusLoading: false,
  rentals: [],
  updateRentals: {},
  updateRentalFeatures: [],
  updateRentalAmenity: [],
  id: [],
  rentalFeatures: [],
  amenities: [],
  allRental: [],
  deleteListLoading: false,
};

export const rentalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.RENTAL_POST_BEGIN:
      return {
        ...state,
        isLoading: true,
      };

    case actions.RENTAL_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actions.RENTAL_POST_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case actions.GET_RENTAL_UPDATE_BEGIN:
      return {
        ...state,
        updateLoading: true,
      };

    case actions.GET_RENTAL_UPDATE_SUCCESS:
      return {
        ...state,
        updateRentals: action.payload.data,
        updateLoading: false,
      };

    case actions.GET_RENTAL_UPDATE_FAIL:
      return {
        ...state,
        updateLoading: false,
      };

    case actions.PUT_RENTAL_UPDATE_BEGIN:
      return {
        ...state,
        updateLoading: true,
      };

    case actions.PUT_RENTAL_UPDATE_SUCCESS:
      return {
        ...state,
        updateRentals: action.payload.data,
        updateLoading: false,
      };

    case actions.PUT_RENTAL_UPDATE_FAIL:
      return {
        ...state,
        updateLoading: false,
      };

    case actions.RENTAL_DELETE_BEGIN:
      return {
        ...state,
        deleteLoading: true,
      };

    case actions.RENTAL_DELETE_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
      };

    case actions.RENTAL_DELETE_FAIL:
      return {
        ...state,
        deleteLoading: false,
      };

    case actions.GET_RENTAL_BEGIN:
      return {
        ...state,
        rentalLoding: true,
      };

    case actions.GET_RENTAL_SUCCESS:
      return {
        ...state,
        rentalLoding: false,
        rentals: action.payload.data,
      };

    case actions.GET_RENTAL_FAIL:
      return {
        ...state,
        rentalLoding: false,
      };

    case actions.POST_RENTAL_FEATURE_BEGIN:
      return {
        ...state,
        isLoading: true,
      };

    case actions.POST_RENTAL_FEATURE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actions.POST_RENTAL_FEATURE_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case actions.GET_RENTAL_FEATURE_BEGIN:
      return {
        ...state,
        featureLoading: true,
      };

    case actions.GET_RENTAL_FEATURE_SUCCESS:
      return {
        ...state,
        featureLoading: false,
        rentalFeatures: action.payload.data,
      };

    case actions.GET_RENTAL_FEATURE_FAIL:
      return {
        ...state,
        featureLoading: false,
      };

    case actions.GET_RENTAL_FEATURE_UPDATE_BEGIN:
      return {
        ...state,
        featureLoading: true,
      };

    case actions.GET_RENTAL_FEATURE_UPDATE_SUCCESS:
      return {
        ...state,
        updateRentalFeatures: action.payload.data,
        featureLoading: false,
      };

    case actions.GET_RENTAL_FEATURE_UPDATE_FAIL:
      return {
        ...state,
        featureLoading: false,
      };

    case actions.PUT_RENTAL_FEATURE_UPDATE_BEGIN:
      return {
        ...state,
        featureLoading: true,
      };

    case actions.PUT_RENTAL_FEATURE_UPDATE_SUCCESS:
      return {
        ...state,
        updateRentalFeatures: action.payload.data,
        featureLoading: false,
      };

    case actions.PUT_RENTAL_FEATURE_UPDATE_FAIL:
      return {
        ...state,
        featureLoading: false,
      };

    case actions.RENTAL_FEATURE_DELETE_BEGIN:
      return {
        ...state,
        featureLoading: true,
      };

    case actions.RENTAL_FEATURE_DELETE_SUCCESS:
      return {
        ...state,
        featureLoading: false,
      };

    case actions.RENTAL_FEATURE_DELETE_FAIL:
      return {
        ...state,
        featureLoading: false,
      };

    case actions.POST_RENTAL_AMENITIES_BEGIN:
      return {
        ...state,
        amenitiesLoading: true,
      };

    case actions.POST_RENTAL_AMENITIES_SUCCESS:
      return {
        ...state,
        amenitiesLoading: false,
      };

    case actions.POST_RENTAL_AMENITIES_FAIL:
      return {
        ...state,
        amenitiesLoading: false,
      };

    case actions.GET_RENTAL_AMENITIES_BEGIN:
      return {
        ...state,
        amenitiesLoading: true,
      };

    case actions.GET_RENTAL_AMENITIES_SUCCESS:
      return {
        ...state,
        amenitiesLoading: false,
        amenities: action.payload.data,
      };

    case actions.GET_RENTAL_AMENITIES_FAIL:
      return {
        ...state,
        amenitiesLoading: false,
      };

    case actions.GET_RENTAL_AMENITY_UPDATE_BEGIN:
      return {
        ...state,
        amenitiesLoading: true,
      };

    case actions.GET_RENTAL_AMENITY_UPDATE_SUCCESS:
      return {
        ...state,
        updateRentalAmenity: action.payload.data,
        amenitiesLoading: false,
      };

    case actions.GET_RENTAL_AMENITY_UPDATE_FAIL:
      return {
        ...state,
        amenitiesLoading: false,
      };

    case actions.PUT_RENTAL_AMENITY_UPDATE_BEGIN:
      return {
        ...state,
        amenitiesLoading: true,
      };

    case actions.PUT_RENTAL_AMENITY_UPDATE_SUCCESS:
      return {
        ...state,
        updateRentalAmenity: action.payload.data,
        amenitiesLoading: false,
      };

    case actions.PUT_RENTAL_AMENITY_UPDATE_FAIL:
      return {
        ...state,
        amenitiesLoading: false,
      };

    case actions.RENTAL_AMENITY_DELETE_BEGIN:
      return {
        ...state,
        amenitiesDeleting: true,
      };

    case actions.RENTAL_AMENITY_DELETE_SUCCESS:
      return {
        ...state,
        amenitiesDeleting: false,
      };

    case actions.RENTAL_AMENITY_DELETE_FAIL:
      return {
        ...state,
        amenitiesDeleting: false,
      };

    case actions.GET_ALL_RENTAL_BEGIN:
      return {
        ...state,
        allRentalLoading: true,
      };

    case actions.GET_ALL_RENTAL_SUCCESS:
      return {
        ...state,
        allRental: action.payload,
        allRentalLoading: false,
      };
    case actions.GET_ALL_RENTAL_FAIL:
      return {
        ...state,
        allRentalLoading: false,
      };

    case actions.POST_RENTAL_STATUS_BEGIN:
      return {
        ...state,
        statusLoading: true,
      };

    case actions.POST_RENTAL_STATUS_SUCCESS:
      return {
        ...state,
        statusLoading: false,
      };
    case actions.POST_RENTAL_STATUS_FAIL:
      return {
        ...state,
        statusLoading: false,
      };

    case actions.CLEAN_CATEGORY_UPDATE:
      return {
        ...state,
        updateRentals: [],
      };

    case actions.CLEAN_FEATURE_UPDATE:
      return {
        ...state,
        updateRentalFeatures: [],
      };

    case actions.CLEAN_AMENITIES_UPDATE:
      return {
        ...state,
        updateRentalAmenity: [],
      };

    case actions.RENTAL_LIST_DELETE_BEGIN:
      return {
        ...state,
        deleteListLoading: true,
      };

    case actions.RENTAL_LIST_DELETE_SUCCESS:
    case actions.RENTAL_LIST_DELETE_FAIL:
      return {
        ...state,
        deleteListLoading: false,
      };

    default:
      return state;
  }
};
