import * as actions from './Type'

const initialState = {
    isLoading: false,
    businessReviews: []
}

export const reviewsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_USER_BUSINESS_REVIEWS_BEGIN:
            return {
                ...state,
                isLoading: true
            };

        case actions.GET_USER_BUSINESS_REVIEWS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                businessReviews: action.payload.data
            }

        case actions.GET_USER_BUSINESS_REVIEWS_FAIL:
            return {
                ...state,
                isLoading: false
            }

        case actions.DELETE_REVIEW_BEGIN:
            return {
                ...state,
                deleteLoading: true
            };

        case actions.DELETE_REVIEW_SUCCESS:
            return {
                ...state,
                deleteLoading: false,
            }

        case actions.DELETE_REVIEW_FAIL:
            return {
                ...state,
                deleteLoading: false
            }

        default:
            return state
    }
}