import { Box, Grid, Paper } from "@mui/material";
import React, { useEffect } from "react";
import DashboardBox from "./DashboardBox";
import BusinessIcon from "@mui/icons-material/Business";
import HouseIcon from "@mui/icons-material/House";
import WorkIcon from "@mui/icons-material/Work";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import InsideHeader from "../../layout/InsideHeader";
import { getDashboardData } from "./redux/Action";
import PersonIcon from "@mui/icons-material/Person";
import { Chart } from "./Chart";

function Dashboard() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const dashboardData = useSelector((state) => state?.dashboard);

  useEffect(() => {
    dispatch(getDashboardData());
  }, []);

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          background: "#f8f8f8",
          marginBottom: "15px",
        }}
      >
        <InsideHeader title="Dashboard" />
      </Paper>

      <Box marginTop={5} sx={{ height: "calc(100vh - 150px)" }}>
        <Grid container spacing={3}>
          <Grid item sm={6} md={4} lg={3} xs={12}>
            <Link to="rental/lists" style={{ textDecoration: "none" }}>
              <DashboardBox
                title="Houses"
                active={dashboardData?.dashboardData?.count?.house?.active}
                inactive={dashboardData?.dashboardData?.count?.house?.inactive}
                total={dashboardData?.dashboardData?.count?.house?.total}
                isLoading={dashboardData?.isLoading}
                icons={<HouseIcon className={classes.icon} />}
              />
            </Link>
          </Grid>

          <Grid item sm={6} md={4} lg={3} xs={12}>
            <Link to="business/lists" style={{ textDecoration: "none" }}>
              <DashboardBox
                title="Business"
                active={dashboardData?.dashboardData?.count?.business?.active}
                inactive={
                  dashboardData?.dashboardData?.count?.business?.inactive
                }
                total={dashboardData?.dashboardData?.count?.business?.total}
                isLoading={dashboardData?.isLoading}
                icons={<BusinessIcon className={classes.icon} />}
              />
            </Link>
          </Grid>

          <Grid item sm={6} md={4} lg={3} xs={12}>
            <Link to="job/lists" style={{ textDecoration: "none" }}>
              <DashboardBox
                title="Jobs"
                active={dashboardData?.dashboardData?.count?.job?.active}
                inactive={dashboardData?.dashboardData?.count?.job?.inactive}
                total={dashboardData?.dashboardData?.count?.job?.total}
                isLoading={dashboardData?.isLoading}
                icons={<WorkIcon className={classes.icon} />}
              />
            </Link>
          </Grid>

          <Grid item sm={6} md={4} lg={3} xs={12}>
            <Link to="users" style={{ textDecoration: "none" }}>
              <DashboardBox
                title="Users"
                active={dashboardData?.dashboardData?.count?.user?.active}
                inactive={dashboardData?.dashboardData?.count?.user?.inactive}
                total={dashboardData?.dashboardData?.count?.user?.total}
                isLoading={dashboardData?.isLoading}
                icons={<PersonIcon className={classes.icon} />}
              />
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Dashboard;
