import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Chart({ active, inactive }) {
  const data = {
    labels: ["inctive", "active"],
    datasets: [
      {
        label: "# of Votes",
        data: [inactive, active],
        backgroundColor: ["rgba(245, 81, 114, 1)", "rgba(114, 134, 181, 1)"],
        borderColor: ["rgba(245, 81, 114, 1)", "rgba(114, 134, 181, 1)"],
        borderWidth: 1,
      },
    ],
  };

  return <Pie data={data} />;
}
