import * as actions from "./Type";

import {
  deleteJobApi,
  deleteJobListApi,
  getAllJobApi,
  getJobApi,
  getUpdateJobApi,
  postJobApi,
  postJobStatusApi,
  putUpdateJobApi,
} from "../../../api/job/JobApi";

export const postJob =
  (data, handleToggle, reset, handleError) => (dispatch) => {
    dispatch({ type: actions.JOB_POST_BEGIN });
    postJobApi(data)
      .then((res) => {
        dispatch({
          type: actions.JOB_POST_SUCCESS,
          payload: res.data,
        });
        handleToggle(false);
        reset();
        dispatch(getJob());
      })
      .catch((err) => {
        console.log(err, "err");
        handleError && handleError(err.response.data?.errors);

        dispatch({
          type: actions.JOB_POST_FAIL,
        });
      });
  };

export const getJob = (page, rowsPerPage) => (dispatch) => {
  dispatch({ type: actions.GET_JOB_BEGIN });
  getJobApi(page, rowsPerPage)
    .then((res) => {
      dispatch({
        type: actions.GET_JOB_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_JOB_FAIL,
      });
    });
};

export const getUpdateJob = (slug) => (dispatch) => {
  dispatch({ type: actions.GET_JOB_UPDATE_BEGIN });
  getUpdateJobApi(slug)
    .then((res) => {
      dispatch({
        type: actions.GET_JOB_UPDATE_SUCCESS,
        payload: res.data,
      });
      dispatch(getJob());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_JOB_UPDATE_SUCCESS,
      });
    });
};

export const putUpdateJob =
  (slug, data, handleToggle, reset, handleError) => (dispatch) => {
    dispatch({ type: actions.GET_JOB_UPDATE_BEGIN });
    putUpdateJobApi(slug, data)
      .then((res) => {
        dispatch({
          type: actions.GET_JOB_UPDATE_SUCCESS,
          payload: res.data,
        });
        handleToggle(false);
        reset();
        dispatch(getJob());
        dispatch({ type: actions.CLEAN_JOB_CATEGORY_UPDATE });
      })
      .catch((err) => {
        console.log(err, "err");
        handleError && handleError(err.response.data?.errors);
        dispatch({
          type: actions.GET_JOB_UPDATE_SUCCESS,
        });
      });
  };

export const deleteJob = (slug, handleClose) => (dispatch) => {
  dispatch({ type: actions.JOB_DELETE_BEGIN });
  deleteJobApi(slug)
    .then((res) => {
      dispatch({
        type: actions.JOB_DELETE_SUCCESS,
        payload: res.id,
      });
      handleClose();
      dispatch(getJob());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.JOB_DELETE_FAIL,
      });
      handleClose();
      dispatch(getJob());
    });
};

export const getAllJob = (page, rowsPerPage) => (dispatch) => {
  dispatch({ type: actions.GET_ALL_JOB_BEGIN });
  getAllJobApi(page, rowsPerPage)
    .then((res) => {
      dispatch({
        type: actions.GET_ALL_JOB_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_ALL_JOB_FAIL,
      });
    });
};

export const postJobStatus = (slug, data) => (dispatch) => {
  dispatch({ type: actions.POST_JOB_STATUS_BEGIN });
  postJobStatusApi(slug, data)
    .then((res) => {
      dispatch({
        type: actions.POST_JOB_STATUS_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllJob());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.POST_JOB_STATUS_FAIL,
      });
    });
};

// delete JOB list
export const deleteJobList = (slug, handleClose) => (dispatch) => {
  dispatch({ type: actions.JOB_LIST_DELETE_BEGIN });
  deleteJobListApi(slug)
    .then((res) => {
      dispatch({
        type: actions.JOB_LIST_DELETE_SUCCESS,
        payload: res.id,
      });
      handleClose();
      dispatch(getAllJob(0, 10));
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.JOB_LIST_DELETE_FAIL,
      });
      handleClose();
    });
};
