import { Breadcrumbs, Button, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import InsideHeader from "../../layout/InsideHeader";
import { makeStyles } from "@mui/styles";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PromotedForm from "./PromotedForm";
import { useDispatch, useSelector } from "react-redux";
import { getPromotedContent } from "./redux/Action";
import Loader from "../../pages/Home/Loader";
import ViewPromotedContent from "./ViewPromotedContent";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PromotedContent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [buttonName, setButtonName] = useState("Add");

  const handleToggle = () => {
    // dispatch({ type: actions.CLEAN_CATEGORY_UPDATE });
    setButtonName("Add");
    setToggle((prev) => !prev);
  };
  console.log("toggle", toggle);
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          background: "#f8f8f8",
          marginBottom: "15px",
        }}
      >
        <InsideHeader title="Promoted Contents">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Ads Management
            </Link>
            <Typography color="textPrimary">Promoted Contents</Typography>
          </Breadcrumbs>
        </InsideHeader>
        <Button
          sx={{ height: "40px" }}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleToggle}
        >
          Add Promoted Content
        </Button>
      </Paper>
      {toggle && (
        <Paper
          elevation={1}
          className={classes.paper}
          sx={{ padding: "30px 20px", marginBottom: "30px" }}
        >
          <div className={classes.root}>
            <Box sx={{ width: "100%" }}>
              <PromotedForm
                handleToggle={handleToggle}
                buttonName={buttonName}
                setButtonName={setButtonName}
              />
            </Box>
          </div>
        </Paper>
      )}
      <ViewPromotedContent
        setToggle={setToggle}
        setButtonName={setButtonName}
      />
    </>
  );
};

export default PromotedContent;
