import React, { useState } from "react";
import {
  Switch,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { postQuickStatus } from "./redux/Action";
import { useSelector } from "react-redux";
import ViewModal from "./ViewModal";
import { Box } from "@mui/system";

const CustomTableRow = ({
  index,
  columns,
  row,
  page,
  rowsPerPage,
  handleModal,
  deletebtn,
}) => {
  const dispatch = useDispatch();
  const { statusLoading } = useSelector((state) => state.quickPost);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen((prev) => !prev);
  };

  const getSwitchValue = (e, value, slug) => {
    const status = value ? 0 : 1;
    dispatch(postQuickStatus(slug, status));
  };

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
      <TableCell>{page * rowsPerPage + index + 1}</TableCell>
      {columns.map((column, ind) => {
        if (column.id === "status") {
          return (
            <TableCell key={column.id} align={column.align}>
              {row?.is_block ? "Inactive" : "Active"}
            </TableCell>
          );
        }
        const value = row[column.id];
        return columns?.length !== ind + 1 && column.id !== "owner" ? (
          <TableCell key={column.id} align={column.align}>
            {value}
          </TableCell>
        ) : column.id === "owner" ? (
          <TableCell align={column.align} key={column.id}>
            {row?.user?.name}
          </TableCell>
        ) : (
          <TableCell
            align={column.align}
            key={column.id}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Tooltip arrow title={`${row?.is_block ? "Deactive" : "Active"}`}>
              <Switch
                defaultChecked={!row?.is_block}
                disabled={statusLoading}
                inputProps={{ "aria-label": "ant design" }}
                onChange={(e, value) => getSwitchValue(e, value, row?.slug)}
              />
            </Tooltip>
            <Tooltip arrow title="Delete">
              <span>
                <DeleteIcon
                  className={deletebtn}
                  onClick={() => handleModal(row?.slug)}
                />
              </span>
            </Tooltip>

            <Tooltip arrow title="View details">
              <RemoveRedEyeIcon
                onClick={handleClose}
                sx={{ cursor: "pointer", color: "#6d9ecd" }}
              />
            </Tooltip>
          </TableCell>
        );
      })}
      <ViewModal open={open} handleClose={handleClose}>
        <Typography variant="h5">{row?.title}</Typography>
        <Box marginTop={2}>
          <Typography variant="body2">{row?.description}</Typography>
        </Box>
      </ViewModal>
    </TableRow>
  );
};

export default CustomTableRow;
