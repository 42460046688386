import { Box, Button, CircularProgress, Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { serializer } from "../../utils/helper";
import { CustomInput } from "../common/customInput/CustomInput";
import { CustomSelect } from "../common/customSelect/CustomSelect";
import CustomTextarea from "../common/customTextarea/CustomTextarea";
import { Breadcrumbs, Typography } from "@mui/material";
import InsideHeader from "../../layout/InsideHeader";
import UploadIcon from "@mui/icons-material/Upload";
import { CustomHiddenInput } from "../common/customInput/CustomHiddenInput";
import Tags from "../rental/Tags";
import {
  getPermission,
  postChangePassword,
  postCreateAdmin,
  postJob,
  putUpdateJob,
} from "./redux/Action";
import { Link } from "react-router-dom";
import ViewJob from "./ViewRole";
import * as actions from "./redux/Type";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),

    "& .uploadAndEdit": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },

  descriptionField: {
    "& .MuiOutlinedInput-root": {
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
    },
  },

  delete: {
    backgroundColor: "#ff6363 !important",
    color: "white",
    cursor: "pointer",
    marginLeft: "10px",
    fontSize: "14px !important",
    "&:hover": {
      backgroundColor: "#DB143C !important",
    },
  },
}));

function CategoryRole() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const updateJobs = useSelector((state) => state?.jobs);

  const [toggle, setToggle] = useState(false);
  const [singleAdmin, setSingleAdmin] = useState("");
  const [type, setType] = useState(false);
  const [buttonName, setButtonName] = useState("Add");
  const [buttonLoader, setButtonLoader] = useState(false);
  console.log("singleAdmin", singleAdmin);
  const options = [
    {
      label: "Active",
      value: 1,
    },
    {
      label: "Inactive",
      value: 0,
    },
  ];

  const schema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup
      .string()
      .email("Enter valid email")
      .required("Enter email address"),

    password: yup
      .string()
      .required()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        "Password must be Strong"
      ),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    // defaultValues: defaultValues,
  });

  useEffect(() => {
    setValue("name", singleAdmin?.name);
    setValue("email", singleAdmin?.email);
  }, [singleAdmin]);

  const upload = useRef(null);
  const image = watch("icon");

  const uploadImage = () => {
    upload.current.click();
  };

  const handleToggle = () => {
    dispatch({ type: actions.CLEAN_JOB_CATEGORY_UPDATE });
    setButtonName("Add");
    setToggle((prev) => !prev);
    reset();
  };

  const handleCancelButton = () => {
    setToggle((prev) => !prev);
    dispatch({ type: actions.CLEAN_JOB_CATEGORY_UPDATE });
  };

  const handleError = (errors) => {
    setError("email", { message: errors });
  };

  useEffect(() => {
    if (Object.keys(updateJobs?.updateJobs).length) {
      // setValue('title', updateJobs?.updateJobs?.title);
      // setValue('description', updateJobs?.updateJobs?.description);
      // setValue('status_id', updateJobs?.updateJobs?.status?.id);
      // setValue('meta_title', updateJobs?.updateJobs?.slug);
      // setValue('order_level', updateJobs?.updateJobs?.order_level);
      // setValue('meta_description', updateJobs?.updateJobs?.meta_description);
    }
  }, [updateJobs, setValue]);

  const onSubmit = (values) => {
    setButtonLoader(true);
    if (singleAdmin?.id) {
      dispatch(
        postChangePassword(
          values,
          handleToggle,
          reset,
          handleError,
          setSingleAdmin,
          setButtonLoader
        )
      );
    }
    dispatch(
      postCreateAdmin(values, handleToggle, reset, handleError, setButtonLoader)
    );
  };

  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };

  useEffect(() => {
    dispatch(getPermission());
  }, []);

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          background: "#f8f8f8",
          marginBottom: "15px",
        }}
      >
        <InsideHeader title="Role">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Role
            </Link>
            <Typography color="textPrimary">User Management</Typography>
          </Breadcrumbs>
        </InsideHeader>
        <Button
          sx={{ height: "40px" }}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleToggle}
        >
          Add
        </Button>
      </Paper>

      {toggle && (
        <Paper
          elevation={1}
          className={classes.paper}
          sx={{ padding: "25px", marginBottom: "30px" }}
        >
          <div className={classes.root}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => checkKeyDown(e)}
            >
              <Grid container spacing={2}>
                <Grid item sm={7}>
                  <CustomInput
                    label="Name"
                    control={control}
                    errors={errors}
                    name="name"
                  />
                  <Typography
                    sx={{ color: "red !important", fontSize: "12px" }}
                  >
                    {errors?.name?.message}
                  </Typography>
                </Grid>
                <Grid item sm={7} className={classes.descriptionField}>
                  <CustomInput
                    label="Email"
                    control={control}
                    errors={errors}
                    name="email"
                  />
                  <Typography
                    sx={{ color: "red !important", fontSize: "12px" }}
                  >
                    {errors?.email?.message}
                  </Typography>
                </Grid>

                <Grid item sm={7}>
                  <CustomInput
                    label="Password"
                    control={control}
                    errors={errors}
                    name="password"
                    type="password"
                  />
                  <Typography
                    sx={{ color: "red !important", fontSize: "12px" }}
                  >
                    {errors?.password?.message}
                  </Typography>
                </Grid>
                <Grid item sm={7}>
                  <CustomInput
                    label="Password Confirmation"
                    control={control}
                    errors={errors}
                    name="password_confirmation"
                    type="password"
                  />
                  <Typography
                    sx={{ color: "red !important", fontSize: "12px" }}
                  >
                    {errors?.password_confirmation?.message}
                  </Typography>
                </Grid>
                <Grid item sm={5}></Grid>

                <Grid item sm={2}>
                  {buttonName === "Add" ? (
                    <Box
                      marginTop={2.5}
                      marginLeft={0}
                      sx={{
                        margiLeft: "0px",
                        marginTop: "0px",
                        display: "flex",
                      }}
                    >
                      <Button
                        sx={{ marginRight: "10px", fontSize: "14px" }}
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                      >
                        {!updateJobs?.isLoading ? (
                          `${buttonName}`
                        ) : (
                          <CircularProgress size={30} sx={{ color: "white" }} />
                        )}
                      </Button>
                      <Button
                        className={classes.delete}
                        variant="contained"
                        fullWidth
                        size="large"
                        onClick={() => setToggle((prev) => !prev)}
                      >
                        Cancel
                      </Button>
                    </Box>
                  ) : (
                    <Box
                      marginTop={2.5}
                      marginLeft={0}
                      sx={{
                        margiLeft: "0px",
                        marginTop: "0px",
                        display: "flex",
                      }}
                    >
                      <Button
                        sx={{ marginRight: "10px", fontSize: "14px" }}
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                      >
                        {!buttonLoader ? (
                          `${buttonName}`
                        ) : (
                          <CircularProgress size={30} sx={{ color: "white" }} />
                        )}
                      </Button>
                      <Button
                        className={classes.delete}
                        variant="contained"
                        fullWidth
                        size="large"
                        onClick={handleCancelButton}
                      >
                        Cancel
                      </Button>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </form>
          </div>
        </Paper>
      )}

      <ViewJob
        setToggle={setToggle}
        setButtonName={setButtonName}
        setSingleAdmin={setSingleAdmin}
      />
    </>
  );
}

export default CategoryRole;
