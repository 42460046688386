import * as actions from "./Type";

import {
  postBusinessApi,
  getBusinessApi,
  deleteBusinessApi,
  getBusinessUpdateApi,
  putBusinessUpdateApi,
  getAllBusinessApi,
  putBusinessStatusApi,
  deleteBusinessListApi,
} from "../../../api/business/BusinessApi";

export const postBusiness =
  (data, handleToggle, reset, handleError) => (dispatch) => {
    dispatch({ type: actions.BUSINESS_POST_BEGIN });
    postBusinessApi(data)
      .then((res) => {
        dispatch({
          type: actions.BUSINESS_POST_SUCCESS,
          payload: res.data,
        });
        handleToggle(false);
        reset();
        dispatch(getBusiness());
      })
      .catch((err) => {
        console.log(err, "err");
        handleError && handleError(err.response.data?.errors);
        dispatch({
          type: actions.BUSINESS_POST_FAIL,
        });
        dispatch(getBusiness());
      });
  };

export const getBusiness = (page, rowsPerPage) => (dispatch) => {
  dispatch({ type: actions.GET_BUSINESS_BEGIN });
  getBusinessApi(page, rowsPerPage)
    .then((res) => {
      dispatch({
        type: actions.GET_BUSINESS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_BUSINESS_FAIL,
      });
    });
};

export const deleteBusiness = (slug, handleClose) => (dispatch) => {
  dispatch({ type: actions.DELETE_BUSINESS_BEGIN });
  deleteBusinessApi(slug)
    .then((res) => {
      dispatch({
        type: actions.DELETE_BUSINESS_SUCCESS,
        payload: res.id,
      });
      handleClose();
      dispatch(getBusiness());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.DELETE_BUSINESS_FAIL,
      });
      handleClose();
      dispatch(getBusiness());
    });
};

export const getBusinessUpdate = (slug) => (dispatch) => {
  dispatch({ type: actions.GET_BUSINESS_UPDATE_BEGIN });
  getBusinessUpdateApi(slug)
    .then((res) => {
      dispatch({
        type: actions.GET_BUSINESS_UPDATE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_BUSINESS_UPDATE_FAIL,
      });
    });
};

export const putBusinessUpdate =
  (slug, data, handleToggle, reset, handleError) => (dispatch) => {
    dispatch({ type: actions.PUT_BUSINESS_UPDATE_BEGIN });
    putBusinessUpdateApi(slug, data)
      .then((res) => {
        dispatch({
          type: actions.PUT_BUSINESS_UPDATE_SUCCESS,
          payload: res.data,
        });
        handleToggle(false);
        reset();
        dispatch(getBusiness());
        dispatch({ type: actions.CLEAN_BUSINESS_CATEGORY_UPDATE });
      })
      .catch((err) => {
        console.log(err, "err");
        handleError && handleError(err.response.data?.errors);
        dispatch({
          type: actions.PUT_BUSINESS_UPDATE_FAIL,
        });
      });
  };

export const getallBusiness = (page, rowsPerPage) => (dispatch) => {
  dispatch({ type: actions.GET_ALL_BUSINESS_BEGIN });
  getAllBusinessApi(page, rowsPerPage)
    .then((res) => {
      dispatch({
        type: actions.GET_ALL_BUSINESS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_ALL_BUSINESS_FAIL,
      });
    });
};

export const postBusinessStatus = (slug, data) => (dispatch) => {
  dispatch({ type: actions.POST_BUSINESS_STATUS_BEGIN });
  putBusinessStatusApi(slug, data)
    .then((res) => {
      dispatch({
        type: actions.POST_BUSINESS_STATUS_SUCCESS,
        payload: res.data,
      });

      dispatch(getallBusiness());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.POST_BUSINESS_STATUS_FAIL,
      });
    });
};

// delete business list
export const deleteBusinessList = (slug, handleClose) => (dispatch) => {
  dispatch({ type: actions.BUSINESS_LIST_DELETE_BEGIN });
  deleteBusinessListApi(slug)
    .then((res) => {
      dispatch({
        type: actions.BUSINESS_LIST_DELETE_SUCCESS,
        payload: res.id,
      });
      handleClose();
      dispatch(getallBusiness(0, 10));
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.BUSINESS_LIST_DELETE_FAIL,
      });
      handleClose();
    });
};
