import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import CustomButton from "../../common/customButton/CustomButton";
import { CustomInput } from "../../common/customInput/CustomInput";
import CustomInputPassword from "../../common/customInput/CustomInputPassword";
import { login } from "../redux/Auth.action";
import styles from "./Style";

const initialValues = {
  userName: "",
  password: "",
  x_tenantId: "",
};

const validationSchema = yup.object({
  userName: yup.string("Enter your Username").required("Username is required"),
  password: yup
    .string("Enter your password")
    .min(6, "Password should be of minimum 6 characters length")
    .required("Password is required"),
});

export default function Login() {
  const classes = styles();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const dispatch = useDispatch();
  const [errMessage, setErrMessage] = useState();
  const error = useSelector((state) => state.error);
  const auth = useSelector((state) => state.auth);

  console.log({ auth });

  const onSubmit = (values) => {
    dispatch(login(values));
  };

  const checkKeyDown = (e) => {
    // if (e.code === "Enter") e.preventDefault();
  };

  useEffect(() => {
    if (auth?.isAuthenticated) {
      window.location.reload();
    }
  }, [auth?.isAuthenticated]);

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classes.loginForm}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <CustomInput
          type="text"
          label="Email"
          name="email"
          margin="normal"
          control={control}
          errors={errors}
          fullWidth
        />
        <Box marginTop={2} marginBottom={2}>
          <CustomInputPassword
            type="password"
            label="Password"
            name="password"
            margin="normal"
            errors={errors}
            control={control}
            fullWidth
          />
        </Box>

        <div className={classes.buttonWrapper}>
          <CustomButton type="submit" fullWidth spinner={true}>
            SignIn
          </CustomButton>
        </div>
      </form>
    </>
  );
}
