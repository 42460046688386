import React from 'react';
import { useParams } from 'react-router-dom';
import PageNotFound from '../../pages/PageNotFound/Index';
import CategoryBusiness from './CategoryBusiness';
import ListBusiness from './ListBusiness';

const BusinessPage = () => {
  const { businessId } = useParams();
  return (
    <>
      {businessId === 'category' || !businessId ? (
        <CategoryBusiness />
      ) : businessId === 'lists' ? (
        <ListBusiness />
      ) : (
        <PageNotFound />
      )}
    </>
  );
};

export default BusinessPage;
