import {
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'

import { makeStyles } from "@mui/styles";
import TablePagination from "@mui/material/TablePagination";

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deleteBlog, getBlog, getBlogUpdate } from './redux/Action';
import CustomTableRow from './CustomTableRow';
import CustomModal from '../common/customModal/CustomModal';

const columns = [
    {
        id: "created_at",
        label: "Date",
        minWidth: 100,

    },
    {
        id: "title",
        label: "Title",
        minWidth: 100,

    },
    {
        id: "owner",
        label: "Posted by ",
        minWidth: 150
    },

    {
        id: "status",
        label: "Status ",
        minWidth: 150
    },

    {
        id: "action",
        label: "Action",
        minWidth: 100,
        align: "center",
    },

];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '5px',
    p: 3,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(5),
        "& .MuiTableCell-stickyHeader": {
            background: theme.palette.background.dark,
        },
        "& .MuiTableBody-root": {
            "& tr:nth-of-type(odd)": {
                background: theme.palette.background.main,
            },
        },
    },
    delete: {
        color: "#ff6363",
        cursor: "pointer",
        "&:hover": {
            color: "red",
        },
    },
    modalDelete: {
        color: "#ff6363",
        cursor: "pointer",
        marginLeft: '10px',
        "&:hover": {
            backgroundColor: "#DB143C !important",
        },
    },
    edit: {
        color: "#15c388",
        cursor: "pointer",
        "&:hover": {
            color: "green",
        },
    },
    container: {
        maxHeight: 550,
        borderRadius: "5px",
    },
}));

const ViewBlogs = ({ setToggle, setButtonName }) => {

    const dispatch = useDispatch()
    const blogs = useSelector((state) => state?.blogs)

    const classes = useStyles();

    const rows = blogs?.blogs || [];
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [id, setId] = useState()
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);


    const handleClose = () => setOpen(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleModal = (slug) => {
        setId(slug)
        handleOpen()
    }

    const handleEdit = (slug) => {
        setButtonName('Update')
        setToggle(true)
        dispatch(getBlogUpdate(slug))
    }

    const handleDelete = () => {
        id &&
            dispatch(deleteBlog(id, handleClose))
    };

    useEffect(() => {
        dispatch(getBlog())
    }, [])

    const skeleton = () => (
        <>
            <Skeleton animation="wave" height={60} />
            <Skeleton animation="wave" height={60} />
            <Skeleton animation="wave" height={60} />
            <Skeleton animation="wave" height={60} />

        </>
    )

    return (
        <>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }}>S.No.</TableCell>
                                {columns.map((column) => (
                                    <TableCell
                                        sx={{ fontWeight: 'bold' }}
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {!blogs?.isLoading &&
                            <TableBody>
                                {rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        return (
                                            <CustomTableRow setOpen={setOpen}
                                                handleClose={handleClose}
                                                handleOpen={handleOpen}
                                                handleModal={handleModal}
                                                handleEdit={handleEdit}
                                                columns={columns}
                                                row={row}
                                                index={index}
                                                editbtn={classes.edit}
                                                deletebtn={classes.delete}
                                            />
                                        );
                                    })}
                            </TableBody>}
                    </Table>
                </TableContainer>
                {blogs?.isLoading && skeleton()}
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

                <CustomModal
                    open={open}
                    handleClose={handleClose}
                    handleDelete={handleDelete}
                    deleteStyle={classes.modalDelete}
                    loading={blogs?.deleteLoading}
                />
            </Paper>
        </>
    )
}

export default ViewBlogs