import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "30px",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    color: theme.palette.secondary,
    fontWeight: 700,
  },
  value: {
    marginTop: theme.spacing.unit,
  },
  iconWrapper: {
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    display: "inline-flex",
    height: "4rem",
    justifyContent: "center",
    marginLeft: "auto",
    width: "4rem",
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: "2rem",
    height: "2rem",
    width: "2rem",
  },
  footer: {
    marginTop: theme.spacing.unit * 2,
    display: "flex",
    alignItems: "center",
  },
  differenceUp: {
    alignItems: "center",
    color: theme.palette.primary.dark,
    display: "inline-flex",
    fontWeight: 700,
  },
  differenceDown: {
    alignItems: "center",
    color: "#c40000",
    display: "inline-flex",
    fontWeight: 700,
  },
  caption: {
    marginLeft: theme.spacing.unit,
  },
}));
