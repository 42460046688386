import React from "react";
import { useSelector } from "react-redux";
import "./spinner.css";

function FullSpinner() {
	const { show } = useSelector(state => state.spinner);
	return (
		show && (
			<div className='spinner-wrapper'>
				{/* <Spinner animation='grow' /> */}
				Spinner goes here
			</div>
		)
	);
}

export default FullSpinner;
