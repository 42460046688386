export const SALE_POST_BEGIN = "SALE_POST_BEGIN";
export const SALE_POST_FAIL = "SALE_POST_FAIL";
export const SALE_POST_SUCCESS = "SALE_POST_SUCCESS";

export const GET_SALE_BEGIN = "GET_SALE_BEGIN";
export const GET_SALE_FAIL = "GET_SALE_FAIL";
export const GET_SALE_SUCCESS = "GET_SALE_SUCCESS";

export const GET_SALE_UPDATE_BEGIN = "GET_SALE_UPDATE_BEGIN";
export const GET_SALE_UPDATE_SUCCESS = "GET_SALE_UPDATE_SUCCESS";
export const GET_SALE_UPDATE_FAIL = "GET_SALE_UPDATE_FAIL";

export const PUT_SALE_UPDATE_BEGIN = "PUT_SALE_UPDATE_BEGIN";
export const PUT_SALE_UPDATE_SUCCESS = "PUT_SALE_UPDATE_SUCCESS";
export const PUT_SALE_UPDATE_FAIL = "PUT_SALE_UPDATE_FAIL";

export const SALE_DELETE_BEGIN = "SALE_DELETE_BEGIN";
export const SALE_DELETE_SUCCESS = "SALE_DELETE_SUCCESS";
export const SALE_DELETE_FAIL = "SALE_DELETE_FAIL";

export const POST_SALE_FEATURE_BEGIN = "POST_SALE_FEATURE_BEGIN";
export const POST_SALE_FEATURE_SUCCESS = "POST_SALE_FEATURE_SUCCESS";
export const POST_SALE_FEATURE_FAIL = "POST_SALE_FEATURE_FAIL";

export const GET_SALE_FEATURE_BEGIN = "GET_SALE_FEATURE_BEGIN";
export const GET_SALE_FEATURE_SUCCESS = "GET_SALE_FEATURE_SUCCESS";
export const GET_SALE_FEATURE_FAIL = "GET_SALE_FEATURE_FAIL";

export const GET_SALE_FEATURE_UPDATE_BEGIN = "GET_SALE_FEATURE_UPDATE_BEGIN";
export const GET_SALE_FEATURE_UPDATE_SUCCESS =
  "GET_SALE_FEATURE_UPDATE_SUCCESS";
export const GET_SALE_FEATURE_UPDATE_FAIL = "GET_SALE_FEATURE_UPDATE_FAIL";

export const PUT_SALE_FEATURE_UPDATE_BEGIN = "PUT_SALE_FEATURE_UPDATE_BEGIN";
export const PUT_SALE_FEATURE_UPDATE_SUCCESS =
  "PUT_SALE_FEATURE_UPDATE_SUCCESS";
export const PUT_SALE_FEATURE_UPDATE_FAIL = "PUT_SALE_FEATURE_UPDATE_FAIL";

export const SALE_FEATURE_DELETE_BEGIN = "SALE_FEATURE_DELETE_BEGIN";
export const SALE_FEATURE_DELETE_SUCCESS = "SALE_FEATURE_DELETE_SUCCESS";
export const SALE_FEATURE_DELETE_FAIL = "SALE_FEATURE_DELETE_FAIL";

// -----------------------------------------------------------------------------------------

export const GET_ALL_SALE_BEGIN = "GET_ALL_SALE_BEGIN";
export const GET_ALL_SALE_SUCCESS = "GET_ALL_SALE_SUCCESS";
export const GET_ALL_SALE_FAIL = "GET_ALL_SALE_FAIL";

export const POST_SALE_STATUS_BEGIN = "POST_SALE_STATUS_BEGIN";
export const POST_SALE_STATUS_SUCCESS = "POST_SALE_STATUS_SUCCESS";
export const POST_SALE_STATUS_FAIL = "POST_SALE_STATUS_FAIL";

export const CLEAN_CATEGORY_UPDATE = "CLEAN_CATEGORY_UPDATE";
export const CLEAN_FEATURE_UPDATE = "CLEAN_FEATURE_UPDATE";

export const SALE_LIST_DELETE_BEGIN = "SALE_LIST_DELETE_BEGIN";
export const SALE_LIST_DELETE_SUCCESS = "SALE_LIST_DELETE_SUCCESS";
export const SALE_LIST_DELETE_FAIL = "SALE_LIST_DELETE_FAIL";
