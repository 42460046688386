import {
  deleteBlogApi,
  getBlogApi,
  getUpdateBlogApi,
  postBlogApi,
  postBlogStatusApi,
  putUpdateBlogApi,
} from '../../../api/blogs/BlogsApi';
import * as actions from './Type';

export const postBlog = (data, setToggle, reset, handleError) => (dispatch) => {
  dispatch({ type: actions.BLOG_POST_BEGIN });
  console.log('Action ma vako::', data);
  postBlogApi(data)
    .then((res) => {
      dispatch({
        type: actions.BLOG_POST_SUCCESS,
        payload: res.data,
      });

      setToggle(false);
      reset();
      dispatch(getBlog());
    })
    .catch((err) => {
      console.log(err, 'err');
      handleError && handleError(err.response.data?.errors);
      dispatch({
        type: actions.BLOG_POST_FAIL,
      });
    });
};

export const getBlog = () => (dispatch) => {
  dispatch({ type: actions.GET_BLOG_BEGIN });
  getBlogApi()
    .then((res) => {
      dispatch({
        type: actions.GET_BLOG_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, 'err');
      dispatch({
        type: actions.GET_BLOG_FAIL,
      });
    });
};

export const deleteBlog = (id, handleClose) => (dispatch) => {
  dispatch({ type: actions.BLOG_DELETE_BEGIN });
  deleteBlogApi(id)
    .then((res) => {
      dispatch({
        type: actions.BLOG_DELETE_SUCCESS,
        payload: res.id,
      });
      handleClose();
      dispatch(getBlog());
    })
    .catch((err) => {
      console.log(err, 'err');
      dispatch({
        type: actions.BLOG_DELETE_BEGIN,
      });
    });
};

export const getBlogUpdate = (id) => (dispatch) => {
  dispatch({ type: actions.GET_BLOG_UPDATE_BEGIN });
  getUpdateBlogApi(id)
    .then((res) => {
      dispatch({
        type: actions.GET_BLOG_UPDATE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, 'err');
      dispatch({
        type: actions.GET_BLOG_UPDATE_FAIL,
      });
    });
};

export const putBlogUpdate =
  (id, data, setToggle, reset, handleError) => (dispatch) => {
    dispatch({ type: actions.PUT_BLOG_UPDATE_BEGIN });
    putUpdateBlogApi(id, data)
      .then((res) => {
        dispatch({
          type: actions.PUT_BLOG_UPDATE_SUCCESS,
          payload: res.data,
        });
        setToggle(false);
        reset();
        dispatch(getBlog());
        dispatch({ type: actions.CLEAN_UPDATE_BLOG });
      })
      .catch((err) => {
        console.log(err, 'err');
        handleError && handleError(err.response.data?.errors);
        dispatch({
          type: actions.PUT_BLOG_UPDATE_FAIL,
        });
      });
  };

export const postBlogStatus = (slug, data) => (dispatch) => {
  dispatch({ type: actions.POST_BLOG_STATUS_BEGIN });
  postBlogStatusApi(slug, data)
    .then((res) => {
      dispatch({
        type: actions.POST_BLOG_STATUS_SUCCESS,
        payload: res.data,
      });
      dispatch(getBlog());
    })
    .catch((err) => {
      console.log(err, 'err');
      dispatch({
        type: actions.POST_BLOG_STATUS_FAIL,
      });
    });
};
