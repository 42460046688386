import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import ViewBlogs from './ViewBlogs';
import BlogPost from './BlogPost';


const BlogsPage = () => {

    const { blogsId } = useParams()

    const [toggle, setToggle] = useState(false)
    const [buttonName, setButtonName] = useState('Post')

    return (
        <>
            <BlogPost
                setToggle={setToggle}
                toggle={toggle}
                setButtonName={setButtonName}
                buttonName={buttonName}

            />

            <ViewBlogs
                setToggle={setToggle}
                setButtonName={setButtonName}
                buttonName={buttonName}
            />

        </>
    )
}

export default BlogsPage