import React, { useState } from "react";
import QuickPost from "./QuickPost";
import ViewQuickPost from "./ViewQuickPost";

const QuickPostPage = () => {
  return (
    <>
      <QuickPost />
      <ViewQuickPost />
    </>
  );
};

export default QuickPostPage;
