import { getDashboardDataApi } from '../../../api/dashboard/DashboardApi'
import * as actions from './Type'

export const getDashboardData = () => (dispatch) => {
    dispatch({ type: actions.GET_DASHBOARD_DATA_BEGIN })
    getDashboardDataApi()
        .then((res) => {
            dispatch({
                type: actions.GET_DASHBOARD_DATA_SUCCESS,
                payload: res.data
            })
        })
        .catch((err) => {
            console.log(err, 'err')
            dispatch({
                type: actions.GET_DASHBOARD_DATA_FAIL
            })
        })
}