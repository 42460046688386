import * as actions from "./Type";

const initialState = {
  isLoading: false,
  updateLoading: false,
  deleteLoading: false,
  saleLoading: false,
  featureLoading: false,
  allSaleLoading: false,
  statusLoading: false,
  sales: [],
  updateSales: {},
  updateSaleFeatures: [],
  id: [],
  saleFeatures: [],
  allSale: [],
  deleteListLoading: false,
};

export const saleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SALE_POST_BEGIN:
      return {
        ...state,
        isLoading: true,
      };

    case actions.SALE_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actions.SALE_POST_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case actions.GET_SALE_UPDATE_BEGIN:
      return {
        ...state,
        updateLoading: true,
      };

    case actions.GET_SALE_UPDATE_SUCCESS:
      return {
        ...state,
        updateSales: action.payload.data,
        updateLoading: false,
      };

    case actions.GET_SALE_UPDATE_FAIL:
      return {
        ...state,
        updateLoading: false,
      };

    case actions.PUT_SALE_UPDATE_BEGIN:
      return {
        ...state,
        updateLoading: true,
      };

    case actions.PUT_SALE_UPDATE_SUCCESS:
      return {
        ...state,
        updateSales: action.payload.data,
        updateLoading: false,
      };

    case actions.PUT_SALE_UPDATE_FAIL:
      return {
        ...state,
        updateLoading: false,
      };

    case actions.SALE_DELETE_BEGIN:
      return {
        ...state,
        deleteLoading: true,
      };

    case actions.SALE_DELETE_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
      };

    case actions.SALE_DELETE_FAIL:
      return {
        ...state,
        deleteLoading: false,
      };

    case actions.GET_SALE_BEGIN:
      return {
        ...state,
        saleLoading: true,
      };

    case actions.GET_SALE_SUCCESS:
      return {
        ...state,
        saleLoading: false,
        sales: action.payload.data,
      };

    case actions.GET_SALE_FAIL:
      return {
        ...state,
        saleLoading: false,
      };

    case actions.POST_SALE_FEATURE_BEGIN:
      return {
        ...state,
        isLoading: true,
      };

    case actions.POST_SALE_FEATURE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actions.POST_SALE_FEATURE_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case actions.GET_SALE_FEATURE_BEGIN:
      return {
        ...state,
        featureLoading: true,
      };

    case actions.GET_SALE_FEATURE_SUCCESS:
      return {
        ...state,
        featureLoading: false,
        saleFeatures: action.payload.data,
      };

    case actions.GET_SALE_FEATURE_FAIL:
      return {
        ...state,
        featureLoading: false,
      };

    case actions.GET_SALE_FEATURE_UPDATE_BEGIN:
      return {
        ...state,
        featureLoading: true,
      };

    case actions.GET_SALE_FEATURE_UPDATE_SUCCESS:
      return {
        ...state,
        updateSaleFeatures: action.payload.data,
        featureLoading: false,
      };

    case actions.GET_SALE_FEATURE_UPDATE_FAIL:
      return {
        ...state,
        featureLoading: false,
      };

    case actions.PUT_SALE_FEATURE_UPDATE_BEGIN:
      return {
        ...state,
        featureLoading: true,
      };

    case actions.PUT_SALE_FEATURE_UPDATE_SUCCESS:
      return {
        ...state,
        updateSaleFeatures: action.payload.data,
        featureLoading: false,
      };

    case actions.PUT_SALE_FEATURE_UPDATE_FAIL:
      return {
        ...state,
        featureLoading: false,
      };

    case actions.SALE_FEATURE_DELETE_BEGIN:
      return {
        ...state,
        featureLoading: true,
      };

    case actions.SALE_FEATURE_DELETE_SUCCESS:
      return {
        ...state,
        featureLoading: false,
      };

    case actions.SALE_FEATURE_DELETE_FAIL:
      return {
        ...state,
        featureLoading: false,
      };

    case actions.GET_ALL_SALE_BEGIN:
      return {
        ...state,
        allSaleLoading: true,
      };

    case actions.GET_ALL_SALE_SUCCESS:
      return {
        ...state,
        allSale: action.payload,
        allSaleLoading: false,
      };
    case actions.GET_ALL_SALE_FAIL:
      return {
        ...state,
        allSaleLoading: false,
      };

    case actions.POST_SALE_STATUS_BEGIN:
      return {
        ...state,
        statusLoading: true,
      };

    case actions.POST_SALE_STATUS_SUCCESS:
      return {
        ...state,
        statusLoading: false,
      };
    case actions.POST_SALE_STATUS_FAIL:
      return {
        ...state,
        statusLoading: false,
      };

    case actions.CLEAN_CATEGORY_UPDATE:
      return {
        ...state,
        updateSales: [],
      };

    case actions.CLEAN_FEATURE_UPDATE:
      return {
        ...state,
        updateSaleFeatures: [],
      };

    case actions.SALE_LIST_DELETE_BEGIN:
      return {
        ...state,
        deleteListLoading: true,
      };

    case actions.SALE_LIST_DELETE_SUCCESS:
    case actions.SALE_LIST_DELETE_FAIL:
      return {
        ...state,
        deleteListLoading: false,
      };

    default:
      return state;
  }
};
