import { axiosInstance } from "../../utils/axiosInstance";
import { handleError, handleResponse } from "../apiResponse";

export function postAdsApi(data) {
  return axiosInstance().post("/admin/ad", data).then().catch();
}

export function getAdsApi() {
  return axiosInstance().get("/api/ad").catch(handleError);
}

export function getAdsCategoryApi() {
  return axiosInstance().get("/admin/ad-category").catch(handleError);
}

export function getAdsByTitleApi(category, title) {
  return axiosInstance()
    .get(`/admin/ad/${category}/${title}`)
    .catch(handleError);
}

export function postAdsUpdateApi(id, data) {
  return axiosInstance().post(`/admin/ad/${id}`, data).then().catch();
}

export function deleteAdsApi(ad_id) {
  return axiosInstance()
    .delete(`/admin/ad/${ad_id}`)
    .then(handleResponse)
    .catch(handleError);
}

export function postPromotedContentApi(data) {
  console.log(data);
  return axiosInstance()
    .post("/admin/promoted-content", data)
    .then(handleResponse)
    .catch(handleError);
}

export function getPromotedContentApi() {
  return axiosInstance().get("/admin/promoted-content").catch(handleError);
}

export function deletePromotedContentApi(slug) {
  return axiosInstance()
    .delete(`/admin/promoted-content/${slug}`)
    .then(handleResponse)
    .catch(handleError);
}

export function updatePromotedContentApi(slug, data) {
  return axiosInstance()
    .post(`/admin/promoted-content/${slug}`, data)
    .then(handleResponse)
    .catch(handleError);
}

export function postPromotedContentStatusApi(slug, data) {
  return axiosInstance()
    .post(`/admin/promoted-content/${slug}/change-active`, data)
    .then(handleResponse)
    .catch(handleError);
}

export function getPromotedContentUpdateApi(slug) {
  return axiosInstance()
    .get(`/admin/promoted-content/${slug}`)
    .catch(handleError);
}

export function getCategoryPromotionalApi(data) { 
  console.log("In get api data ",data)
  return axiosInstance()
  .get(`/admin/category/${data.category}`)
  .catch(handleError);
}

export function postCategoryPromotionalApi(data) { 
  return axiosInstance()
  .post(`/admin/promoted-post`,data)
  .catch(handleError);
}

export function getCategoryPromotionalDataApi() { 
  return axiosInstance()
  .get(`/admin/promoted-post?limit=999`)
  .catch(handleError);
}

export function deleteCategoryPromotionalDataApi(data) { 
  return axiosInstance()
  .delete(`/admin/promoted-post/${data}`)
  .catch(handleError);
}

