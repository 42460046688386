import { Box, Button, CircularProgress, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CustomInput } from '../common/customInput/CustomInput';
import UploadIcon from '@mui/icons-material/Upload';
import { Breadcrumbs, Typography } from '@mui/material';
import InsideHeader from '../../layout/InsideHeader';
import { Link } from 'react-router-dom';
import { CustomHiddenInput } from '../common/customInput/CustomHiddenInput';
import { useDispatch, useSelector } from 'react-redux';
import { postRentalFeature, putUpdateRentalFeature } from './redux/Action';
import { serializer } from '../../utils/helper';
import ViewRentalFeature from './ViewRentalFeature';
import * as actions from './redux/Type';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    // paddingRight: '16px',
  },
  delete: {
    backgroundColor: '#ff6363 !important',
    color: 'white',
    cursor: 'pointer',
    marginLeft: '10px',
    fontSize: '14px !important',
    '&:hover': {
      backgroundColor: '#DB143C !important',
    },
  },
}));

function FeatureRental() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const rentals = useSelector((state) => state?.rentals);
  const updateRentalFeatures = useSelector(
    (state) => state.rentals?.updateRentalFeatures
  );

  const [toggle, setToggle] = useState(false);
  const [buttonName, setButtonName] = useState('Add');

  const defaultValues = {
    title: '',
    icon: '',
  };
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
    setError,
  } = useForm({ defaultValues: defaultValues });

  const upload = useRef(null);
  const image = watch('icon');

  const uploadImage = () => {
    upload.current.click();
  };

  const handleToggle = () => {
    dispatch({ type: actions.CLEAN_FEATURE_UPDATE });
    setButtonName('Add');
    setToggle((prev) => !prev);
    reset();
  };
  const handleError = (errors) => {
    console.log(errors, 'errors');
    Object.entries(errors)?.map((item) => {
      console.log('error', item);
      setError(item[0], { message: item[1] });
    });
  };

  const handleCancelButton = () => {
    setToggle((prev) => !prev);
    dispatch({ type: actions.CLEAN_FEATURE_UPDATE });
  };

  useEffect(() => {
    setValue('title', updateRentalFeatures?.title);
  }, [updateRentalFeatures, setValue]);

  const onSubmit = (values) => {
    console.log(values);
    const formData = new FormData();

    if (updateRentalFeatures?.id) {
      serializer(formData, { ...values, _method: 'patch' });
      dispatch(
        putUpdateRentalFeature(
          updateRentalFeatures?.id,
          formData,
          handleToggle,
          reset,
          handleError
        )
      );
    } else {
      serializer(formData, values);
      dispatch(postRentalFeature(formData, handleToggle, reset, handleError));
    }
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          background: '#f8f8f8',
          marginBottom: '15px',
        }}>
        <InsideHeader title="Rental">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Rental
            </Link>
            <Typography color="textPrimary">Feature</Typography>
          </Breadcrumbs>
        </InsideHeader>

        <Button
          sx={{ height: '40px' }}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleToggle}>
          Add Feature
        </Button>
      </Paper>
      {toggle && (
        <Paper
          elevation={1}
          className={classes.paper}
          sx={{ padding: '25px', marginBottom: '30px' }}>
          <div className={classes.root}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => checkKeyDown(e)}>
              <Grid container spacing={2}>
                <Grid item sm={10}>
                  <CustomInput
                    label="Title"
                    control={control}
                    errors={errors}
                    name="title"
                  />
                  <Typography
                    sx={{ color: 'red !important', fontSize: '12px' }}>
                    {errors?.title?.message}
                  </Typography>
                </Grid>
                <Grid item sm={2} sx={{ paddingTop: '8px !important' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <div className="uploadAndEdit">
                      <label style={{ color: '#828282', fontSize: '13px' }}>
                        Upload Icon
                      </label>
                    </div>
                    <div
                      onClick={uploadImage}
                      style={{
                        height: '50px',
                        width: '50px',
                        background: '#f4f9ff',
                        border: '1px solid #e4e4e4',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        cursor: 'pointer',
                      }}>
                      <img
                        style={{ position: 'absolute', top: '0', left: '0' }}
                        src={
                          !image
                            ? updateRentalFeatures?.image?.original_url
                            : URL?.createObjectURL(image)
                        }
                        width="100%"
                        height="100%"
                        alt=""
                      />
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={uploadImage}>
                        <UploadIcon />
                      </span>
                    </div>
                    <CustomHiddenInput
                      style={{
                        display: 'none',
                      }}
                      className="uploadImage"
                      type="file"
                      control={control}
                      name="icon"
                      ref={upload}
                    />
                    <Typography
                      sx={{ color: 'red !important', fontSize: '12px' }}>
                      {errors?.icon?.message}
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={2}>
                  {buttonName === 'Add' ? (
                    <Box
                      marginTop={2.5}
                      marginLeft={0}
                      sx={{
                        margiLeft: '0px',
                        marginTop: '0px',
                        display: 'flex',
                      }}>
                      <Button
                        sx={{ marginRight: '10px', fontSize: '14px' }}
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit">
                        {!rentals?.isLoading ? (
                          `${buttonName}`
                        ) : (
                          <CircularProgress size={30} sx={{ color: 'white' }} />
                        )}
                      </Button>
                      <Button
                        className={classes.delete}
                        variant="contained"
                        fullWidth
                        size="large"
                        onClick={() => setToggle((prev) => !prev)}>
                        Cancel
                      </Button>
                    </Box>
                  ) : (
                    <Box
                      marginTop={2.5}
                      marginLeft={0}
                      sx={{
                        margiLeft: '0px',
                        marginTop: '0px',
                        display: 'flex',
                      }}>
                      <Button
                        sx={{ marginRight: '10px', fontSize: '14px' }}
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit">
                        {!rentals?.featureLoading ? (
                          `${buttonName}`
                        ) : (
                          <CircularProgress size={30} sx={{ color: 'white' }} />
                        )}
                      </Button>
                      <Button
                        className={classes.delete}
                        variant="contained"
                        fullWidth
                        size="large"
                        onClick={handleCancelButton}>
                        Cancel
                      </Button>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </form>
          </div>
        </Paper>
      )}

      <ViewRentalFeature setToggle={setToggle} setButtonName={setButtonName} />
    </>
  );
}

export default FeatureRental;
