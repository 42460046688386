import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteJob,
  getAllAdmin,
  getJob,
  getOtherAdminPermission,
  getSingleAdminPermission,
  getUpdateJob,
  updatePermission,
} from "./redux/Action";
import EditIcon from "@mui/icons-material/Edit";

import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Modal,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import CustomModal from "../common/customModal/CustomModal";
import CancelIcon from "@mui/icons-material/Cancel";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { Key } from "@mui/icons-material";
import { set, useForm } from "react-hook-form";
import { groupPermission } from "../../utils/GroupedPermission";

const descriptionModalstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,

  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
};

const columns = [
  {
    id: "name",
    label: "Name",
    minWidth: 100,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 100,
  },
  {
    id: "created_at",
    label: "Created At",
    minWidth: 100,
    align: "left",
  },

  {
    id: "action",
    label: "Action",
    minWidth: 100,
    align: "center",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    "& .MuiTableCell-stickyHeader": {
      background: theme.palette.background.dark,
      //   color: theme.palette.primary.dark,
    },
    "& .MuiTableBody-root": {
      "& tr:nth-of-type(odd)": {
        background: theme.palette.background.main,
      },
    },
  },
  delete: {
    color: "#ff6363",
    cursor: "pointer",
    "&:hover": {
      color: "red",
    },
  },
  edit: {
    color: "#15c388",
    cursor: "pointer",
    "&:hover": {
      color: "green",
    },
  },
  permission: {
    color: "#638bff",
    cursor: "pointer",
    "&:hover": {
      color: "blue",
    },
  },
  modalDelete: {
    color: "white",
    cursor: "pointer",
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: "#DB143C !important",
    },
  },
  container: {
    maxHeight: 550,
    borderRadius: "5px",
  },
}));

function ViewRole({ setToggle, setButtonName, setSingleAdmin }) {
  const classes = useStyles();

  const jobs = useSelector((state) => state?.jobs);
  const {
    adminList,
    isAdminListLoading,
    permission,
    isPermissionLoading,
    isOtherAdminPermissionLoading,
    otherAdminPermission,
  } = useSelector((state) => state?.rolePermission);
  const dispatch = useDispatch();
  const rows =
    adminList?.data?.map((data) => {
      return { ...data, status: data?.active?.title };
    }) || [];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [type, setType] = React.useState("");
  const [id, setId] = useState();
  const [open, setOpen] = React.useState(false);
  const [permissionList, setPermissionList] = React.useState(false);
  const [selectedList, setSelectedList] = useState([]);

  const handleSelected = (e, value) => {
    console.log(e, value, "formData");
  };
  const handleOpen = () => setOpen(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleModal = (id, modalType) => {
    reset();
    setId(id);
    handleOpen();
    setType(modalType);
  };

  const {
    handleSubmit,
    reset,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useForm({
    // defaultValues: defaultValues,
  });

  console.log(otherAdminPermission, watch(), "formData");
  useEffect(() => {
    Object.entries(groupPermission)?.map((list) => {
      const findData = otherAdminPermission?.find(
        (item) => item?.id == list[1]?.[0]?.id
      );
      if (findData) {
        setValue(list[0], true);
      }
    });
  }, [otherAdminPermission]);
  const handleClose = () => setOpen(false);

  const handleDelete = () => {
    id && dispatch(deleteJob(id, handleClose));
  };
  useEffect(() => {
    id && dispatch(getOtherAdminPermission(id, reset));
  }, [id]);
  const handleEdit = (row) => {
    setButtonName("Update");
    setToggle(true);
    setSingleAdmin(row);
  };

  useEffect(() => {
    dispatch(getAllAdmin());
  }, []);

  const skeleton = () => (
    <>
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
    </>
  );

  const onSubmit = (data) => {
    console.log(data, "formDataa");
    const list = Object.entries(data)?.filter((value) => value !== false);
    const flatData = list
      ?.map((item) => {
        if (item[1]) {
          const newData = groupPermission?.[`${item[0]}`];
          return newData?.map((list) => list?.id);
        }
      })
      ?.filter((list) => list)
      .flat();
    const finalData = { _method: "patch", permission: flatData };
    dispatch(updatePermission(id, finalData, handleClose, setId("")));
  };
  // useEffect(() => {
  //   if (permission.length > 0) {
  //     const list = permission?.map((data) => {
  //       const properName = data?.name?.replaceAll("-", " ");
  //       return { ...data, properName };
  //     });
  //     setPermissionList(list);
  //   }
  // }, [permission]);

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>S.No.</TableCell>
              {columns.map((column) => (
                <TableCell
                  sx={{ fontWeight: "bold" }}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!isAdminListLoading && (
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell>{index + 1}</TableCell>
                    {columns.map((column, ind) => {
                      const value = row[column.id];
                      return columns?.length !== ind + 1 ? (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      ) : (
                        <TableCell
                          align={column.align}
                          key={column.id}
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <Tooltip title="Edit" arrow>
                            <span>
                              {/* <Link to={`category/${row?.slug}`}> */}
                              <EditIcon
                                className={classes.edit}
                                onClick={() => handleEdit(row)}
                              />
                              {/* </Link> */}
                            </span>
                          </Tooltip>
                          <Tooltip title="Permission" arrow>
                            <span>
                              <Key
                                className={classes.permission}
                                onClick={() =>
                                  handleModal(row?.id, "permission")
                                }
                              />
                            </span>
                          </Tooltip>
                          <Tooltip title="Delete" arrow>
                            <span>
                              <DeleteIcon
                                className={classes.delete}
                                onClick={() => handleModal(row?.slug, "delete")}
                              />
                            </span>
                          </Tooltip>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {isAdminListLoading && skeleton()}
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {type === "delete" && (
        <CustomModal
          open={open}
          handleClose={handleClose}
          handleDelete={handleDelete}
          deleteStyle={classes.modalDelete}
          loading={jobs?.deleteLoading}
        />
      )}
      {type === "permission" && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Paper elevation={2} style={{ width: "800px" }}>
            <Box sx={descriptionModalstyle}>
              <Box
                sx={{
                  height: "30px",
                  width: "100%",
                  background: "#C0DDF9",
                  borderRadius: "4px",
                }}
              >
                <Tooltip arrow title="close">
                  <CancelIcon
                    sx={{
                      position: "absolute",
                      top: "8px",
                      right: "8px",
                      color: "#e16c6c !important",
                      cursor: "pointer",
                      fontSize: "18px",
                      transition: "all ease 0.5s",

                      "&:hover": {
                        color: "red !important",
                        transition: "all ease 0.5s",
                      },
                    }}
                    onClick={() => handleClose()}
                  />
                </Tooltip>
              </Box>

              <Box
                sx={{
                  padding: "5px 10px",
                }}
              >
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  sx={{
                    color: "#6e6eb3",
                    paddingLeft: "20px",
                  }}
                >
                  Permission
                </Typography>
                {isOtherAdminPermissionLoading ? (
                  <Box display="flex" sx={{ flexWrap: "wrap" }}>
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]?.map((data) => {
                      return (
                        <Box
                          key={data?.id}
                          display="flex"
                          columnGap={1}
                          sx={{ width: "250px", padding: "10px" }}
                        >
                          <Skeleton width={20} height={30} />
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              color: "#4b4949",
                            }}
                          >
                            <Skeleton width={100} height={30} />
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Box display="flex" sx={{ flexWrap: "wrap" }}>
                      {/* {permissionList?.map((data) => {
                        return (
                          <Box
                            key={data?.id}
                            display="flex"
                            columnGap={1}
                            sx={{ width: "250px", padding: "10px" }}
                          >
                            <input
                              type="checkbox"
                              {...register(data?.name)}
                              value={data?.id}
                            />
                            <Typography
                              sx={{
                                textTransform: "capitalize",
                                color: "#4b4949",
                              }}
                            >
                              {data?.properName}
                            </Typography>
                          </Box>
                        );
                      })} */}
                      {Object.entries(groupPermission)?.map((data) => (
                        <Box
                          key={data?.id}
                          display="flex"
                          columnGap={1}
                          sx={{ width: "250px", padding: "10px" }}
                        >
                          <input
                            type="checkbox"
                            // onChange={handleSelected}
                            {...register(data?.[0])}
                          />
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              color: "#4b4949",
                            }}
                          >
                            {data?.[0]}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                    <Box sx={{ float: "right" }}>
                      <Button
                        type="submit"
                        sx={{ height: "40px", margin: "0px 20px 20px 0px" }}
                        variant="contained"
                        color="primary"
                        size="large"
                      >
                        Submit
                      </Button>
                    </Box>
                  </form>
                )}
              </Box>
            </Box>
          </Paper>
        </Modal>
      )}
    </Paper>
  );
}

export default ViewRole;
