import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  loginForm: {
    width: "300px",
    "& .MuiTextField-root": {
      margin: `${theme.spacing(1.5)}px 0px`,
    },
  },
  buttonWrapper: {
    marginTop: theme.spacing(2),
  },
}));

export default styles;
