import { Button, Modal, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  p: 3,
};

const ViewModal = ({ open, handleClose, children }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper elevation={2}>
        <Box sx={style}>
          {children}
          <Box display="flex" justifyContent="center">
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{
                bgcolor: "#ff000094 !important",
                marginRight: "10px",
                marginTop: "20px",
                fontSize: "12px",
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default ViewModal;
