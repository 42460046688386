import moment from "moment";

export const formatDate = (timestamp, format = "YYYY-MM-DD") => {
  return moment(timestamp).format(format);
};

formatDate.defaultProps = {
  format: "LLL",
};

export const getTimeStampFormat = (date) => {
  return moment.utc(date).format();
};
