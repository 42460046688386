import {
  amber,
  blue,
  green,
  red,
  indigo,
  grey,
  orange,
} from "@mui/material/colors";

export default {
  palette: {
    primary: {
      main: "#67B3FA",
      dark: "#1c7cd5",
      light: "#CCE5FD",
      contrastText: "#fff",
      50: indigo[50],
      100: indigo[100],
      200: indigo[200],
      300: indigo[300],
      400: indigo[400],
      500: indigo[500],
      600: indigo[600],
      700: indigo[700],
      800: indigo[800],
      900: indigo[900],
      A100: indigo.A100,
      A200: indigo.A200,
      A400: indigo.A400,
      A700: indigo.A700,
    },
    secondary: {
      main: red.A700,
      light: red[700],
      dark: red[900],
    },
    info: {
      main: blue[500],
      light: blue[300],
      dark: blue[700],
    },
    success: {
      main: green[500],
      light: green[300],
      dark: green[700],
    },
    warning: {
      main: amber[500],
      light: amber[300],
      dark: amber[700],
    },
    text: {
      info: blue[700],
      success: green[700],
      warning: amber[700],
      error: red[700],
    },
    paragraph: {
      900: grey[900],
      700: grey[700],
      600: grey[600],
      300: grey[300],
      200: grey[200],
      50: grey[50],
    },
    background: {
      main: "#F4F9FF",
      dark: "#C0DDF9",
      info: blue[700],
      success: green[700],
      warning: amber[700],
      error: red[700],
    },
    orange: {
      700: orange[700],
      800: orange[800],
    },
    red: {
      700: red[700],
      800: red[800],
    },
    green: {
      700: green[700],
      800: green[800],
    },
  },
  overrides: {
    MuiButton: {
      sizeMedium: {},
      sizeLarge: {
        height: "48px",
      },
    },
    MuiTextField: {
      root: {
        marginRight: "8px",
        "&:last-child": {
          marginRight: "0",
        },
      },
    },
  },
};
