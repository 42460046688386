import * as actions from "./Type";

const initialState = {
  isLoading: false,
  businessLoading: false,
  deleteLoading: false,
  allBusinessLoading: false,
  updateLoading: false,
  putUpdateLoading: false,
  statusLoading: false,
  businesses: [],
  updateBusiness: {},
  allBusinesses: [],
  deleteListLoading: false,
};

export const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.BUSINESS_POST_BEGIN:
      return {
        ...state,
        isLoading: true,
      };

    case actions.BUSINESS_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actions.BUSINESS_POST_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case actions.GET_BUSINESS_BEGIN:
      return {
        ...state,
        businessLoading: true,
      };

    case actions.GET_BUSINESS_SUCCESS:
      return {
        ...state,
        businesses: action.payload.data,
        businessLoading: false,
      };

    case actions.GET_BUSINESS_FAIL:
      return {
        ...state,
        businessLoading: false,
      };

    case actions.DELETE_BUSINESS_BEGIN:
      return {
        ...state,
        deleteLoading: true,
      };

    case actions.DELETE_BUSINESS_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
      };

    case actions.DELETE_BUSINESS_FAIL:
      return {
        ...state,
        deleteLoading: false,
      };

    case actions.GET_BUSINESS_UPDATE_BEGIN:
      return {
        ...state,
        updateLoading: true,
      };

    case actions.GET_BUSINESS_UPDATE_SUCCESS:
      return {
        ...state,
        updateBusiness: action.payload,
        updateLoading: false,
      };

    case actions.GET_BUSINESS_UPDATE_FAIL:
      return {
        ...state,
        updateLoading: false,
      };

    case actions.PUT_BUSINESS_UPDATE_BEGIN:
      return {
        ...state,
        putUpdateLoading: true,
      };

    case actions.PUT_BUSINESS_UPDATE_SUCCESS:
      return {
        ...state,
        updateBusiness: action.payload,
        putUpdateLoading: false,
      };

    case actions.PUT_BUSINESS_UPDATE_FAIL:
      return {
        ...state,
        putUpdateLoading: false,
      };

    case actions.GET_ALL_BUSINESS_BEGIN:
      return {
        ...state,
        allBusinessLoading: true,
      };

    case actions.GET_ALL_BUSINESS_SUCCESS:
      return {
        ...state,
        allBusinesses: action.payload,
        allBusinessLoading: false,
      };

    case actions.GET_ALL_BUSINESS_FAIL:
      return {
        ...state,
        allBusinessLoading: false,
      };

    case actions.POST_BUSINESS_STATUS_BEGIN:
      return {
        ...state,
        statusLoading: true,
      };

    case actions.POST_BUSINESS_STATUS_SUCCESS:
      return {
        ...state,
        statusLoading: false,
      };

    case actions.POST_BUSINESS_STATUS_FAIL:
      return {
        ...state,
        statusLoading: false,
      };

    case actions.CLEAN_BUSINESS_CATEGORY_UPDATE:
      return {
        ...state,
        updateBusiness: [],
      };

    case actions.BUSINESS_LIST_DELETE_SUCCESS:
    case actions.BUSINESS_LIST_DELETE_FAIL:
      return {
        ...state,
        deleteListLoading: false,
      };

    default:
      return state;
  }
};
