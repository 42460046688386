import { Breadcrumbs, Button, Grid, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';

import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import InsideHeader from '../../layout/InsideHeader'
import { Box } from '@mui/system';
import { serializer } from '../../utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import { getFallbackImage, postFallbackImage } from './redux/Action';
import FallbackImageField from './FallbackImageField';
import Loader from '../../pages/Home/Loader';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        "& .uploadAndEdit": {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'

        }
    },

    description: {
        '& .MuiOutlinedInput-root': {
            paddingLeft: '0px !important',
            paddingRight: '0px !important'
        }
    }
}));


const FallbackImage = () => {

    const classes = useStyles();

    const dispatch = useDispatch()

    const fallbackImages = useSelector((state) => state.siteSetting)


    const defaultValues = {
        house_feature_image: '',
        business_profile_picture: '',
        business_banner: '',
        client_profile_picture: '',
        job_feature_image: '',
        business_category_icon: '',
        house_category_icon: '',
    };

    const {
        handleSubmit,
        control,
        reset,
        watch,
        setValue,
        formState: { errors },
    } = useForm({ defaultValues: defaultValues });

    useEffect(() => {
        dispatch(getFallbackImage())
    }, [])

    const onSubmit = (values) => {
        const formData = new FormData();
        serializer(formData, values)

        dispatch(postFallbackImage(formData))
        reset();
    };

    const checkKeyDown = (e) => {
        if (e.code === "Enter") e.preventDefault();
    };


    return (fallbackImages === 'null' || fallbackImages?.fallbackImageLoading) ? <Loader /> : (
        <>
            <Paper elevation={0} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', background: '#f8f8f8', marginBottom: '15px' }}>
                <InsideHeader title="Fallback Images">
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Link color="inherit" to="/">
                            Setting
                        </Link>
                        <Typography color="textPrimary">Fallback Images</Typography>
                    </Breadcrumbs>
                </InsideHeader>
            </Paper>


            <Paper elevation={1} className={classes.paper} sx={{ padding: '25px', marginBottom: '30px' }}>
                <div className={classes.root}>
                    <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
                        <Grid container spacing={2}>

                            <Grid item sm={4}>

                                <FallbackImageField
                                    name='house_feature_image'
                                    title='House Feature Image'
                                    control={control}
                                    watch={watch}
                                    displayImage={fallbackImages?.fallbackImages?.find(item => item.key === 'house_feature_image')}
                                />
                            </Grid>

                            <Grid item sm={4}>
                                <FallbackImageField
                                    name='business_profile_picture'
                                    title='Business Profile Picture'
                                    control={control}
                                    watch={watch}
                                    displayImage={fallbackImages?.fallbackImages?.find(item => item.key === 'business_profile_picture')}
                                />
                            </Grid>

                            <Grid item sm={4}>
                                <FallbackImageField
                                    name='business_banner'
                                    title='Business Banner'
                                    control={control}
                                    watch={watch}
                                    displayImage={fallbackImages?.fallbackImages?.find(item => item.key === 'business_banner')}

                                />
                            </Grid>

                            <Grid item sm={4}>
                                <FallbackImageField
                                    name='client_profile_picture'
                                    title='Client Profile Picture'
                                    control={control}
                                    watch={watch}
                                    displayImage={fallbackImages?.fallbackImages?.find(item => item.key === 'client_profile_picture')}
                                />
                            </Grid>

                            <Grid item sm={4}>
                                <FallbackImageField
                                    name='job_feature_image'
                                    title='Job Feature Image'
                                    control={control}
                                    watch={watch}
                                    displayImage={fallbackImages?.fallbackImages?.find(item => item.key === 'job_feature_image')}
                                />
                            </Grid>

                            <Grid item sm={4}>
                                <FallbackImageField
                                    name='business_category_icon'
                                    title='Business Category Icon'
                                    control={control}
                                    watch={watch}
                                    displayImage={fallbackImages?.fallbackImages?.find(item => item.key === 'business_category_icon')}
                                />
                            </Grid>

                            <Grid item sm={4}>
                                <FallbackImageField
                                    name='house_category_icon'
                                    title='House Category Icon'
                                    control={control}
                                    watch={watch}
                                    displayImage={fallbackImages?.fallbackImages?.find(item => item.key === 'house_category_icon')}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                            <Grid item sm={2}>
                                <Box marginTop={2.5} marginLeft={0} sx={{ margiLeft: '0px', marginTop: '0px' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        type="submit"
                                    >
                                        Add
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Paper>
        </>
    )
}

export default FallbackImage