import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { deleteLookingFor, getLookingFor } from "./redux/Action";
import { Skeleton } from "@mui/material";
import CustomTableRow from "./CustomTableRow";
import CustomModal from "../common/customModal/CustomModal";

const columns = [
  {
    id: "title",
    label: "Title",
    minWidth: 50,
  },

  {
    id: "description",
    label: "Description",
    minWidth: 50,
  },
  {
    id: "action",
    label: "Action",
    minWidth: 100,
    align: "center",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    "& .MuiTableCell-stickyHeader": {
      background: theme.palette.background.dark,
      //   color: theme.palette.primary.dark,
    },
    "& .MuiTableBody-root": {
      "& tr:nth-of-type(odd)": {
        background: theme.palette.background.main,
      },
    },
  },
  delete: {
    color: "#ff6363",
    cursor: "pointer",
    "&:hover": {
      color: "red",
    },
  },
  container: {
    maxHeight: 550,
    borderRadius: "5px",
  },
}));

function ViewLookingFor({ setToggle, setValue, setButtonName, setFocus }) {
  const dispatch = useDispatch();

  const {
    lookingForData,
    lookingForLoading,
    lookingForPostStatus: postStatus,
    deleteLoading,
  } = useSelector((state) => state.siteSetting);

  const classes = useStyles();
  const rows = lookingForData || [];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = useState(false);
  const [currentId, setCurrentId] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDelete = () => {
    console.log("heree", currentId);
    dispatch(deleteLookingFor(currentId, setOpen));
  };

  useEffect(() => {
    if (postStatus === "idle") {
      dispatch(getLookingFor());
    }
  }, [dispatch, postStatus]);

  const skeleton = () => (
    <>
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
    </>
  );

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>S.No.</TableCell>
                {columns.map((column) => (
                  <TableCell
                    sx={{ fontWeight: "bold" }}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {!lookingForLoading && (
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <CustomTableRow
                        row={row}
                        index={index}
                        columns={columns}
                        setToggle={setToggle}
                        setValue={setValue}
                        setFocus={setFocus}
                        setButtonName={setButtonName}
                        setOpen={setOpen}
                        setCurrentId={setCurrentId}
                      />
                    );
                  })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {lookingForLoading && skeleton()}
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <CustomModal
          open={open}
          handleClose={() => setOpen(false)}
          handleDelete={handleDelete}
          deleteStyle={classes.modalDelete}
          loading={deleteLoading}
        />
      </Paper>
    </div>
  );
}

export default ViewLookingFor;
