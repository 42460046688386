import * as actions from "./Type";

import {
  deleteJobApi,
  getAllJobApi,
  getJobApi,
  getUpdateJobApi,
  postJobApi,
  postJobStatusApi,
  putUpdateJobApi,
} from "../../../api/job/JobApi";
import {
  getAllAdminApi,
  getPermissionApi,
  getSingleAdminPermissionApi,
  postChangePasswordApi,
  postCreateAdminApi,
  updatePermissionApi,
} from "../../../api/role";

export const postCreateAdmin =
  (data, handleToggle, reset, handleError, setButtonLoader) => (dispatch) => {
    dispatch({ type: actions.CREATE_ADMIN_POST_BEGIN });
    postCreateAdminApi(data)
      .then((res) => {
        dispatch({
          type: actions.CREATE_ADMIN_POST_SUCCESS,
          payload: res.data,
        });
        handleToggle(false);
        reset();
        dispatch(getAllAdmin());
      })
      .catch((err) => {
        console.log(err, "err");
        handleError && handleError(err.response.data?.message);

        dispatch({
          type: actions.CREATE_ADMIN_POST_FAIL,
        });
      })
      .finally(() => setButtonLoader(false));
  };

export const getPermission = () => (dispatch) => {
  dispatch({ type: actions.GET_PERMISSION_BEGIN });
  getPermissionApi()
    .then((res) => {
      dispatch({
        type: actions.GET_PERMISSION_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_PERMISSION_FAIL,
      });
    });
};

export const getAllAdmin = () => (dispatch) => {
  dispatch({ type: actions.GET_ALL_ADMIN_BEGIN });
  getAllAdminApi()
    .then((res) => {
      dispatch({
        type: actions.GET_ALL_ADMIN_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_ALL_ADMIN_FAIL,
      });
    });
};

export const postChangePassword =
  (data, handleToggle, reset, handleError, setSingleAdmin, setButtonLoader) =>
  (dispatch) => {
    dispatch({ type: actions.CREATE_ADMIN_POST_BEGIN });
    postChangePasswordApi(data)
      .then((res) => {
        dispatch({
          type: actions.CREATE_ADMIN_POST_SUCCESS,
          payload: res.data,
        });
        handleToggle(false);
        reset();
        setSingleAdmin("");
        dispatch(getAllAdmin());
      })
      .catch((err) => {
        console.log(err, "err");
        handleError && handleError(err.response.data?.message);

        dispatch({
          type: actions.CREATE_ADMIN_POST_FAIL,
        });
      })
      .finally(() => setButtonLoader(false));
  };

export const updatePermission =
  (id, data, handleClose, handleFunction) => (dispatch) => {
    updatePermissionApi(id, data)
      .then((res) => {
        handleClose(true);
        handleFunction && handleFunction();
      })
      .catch((err) => {});
    // .finally(() => setButtonLoader(false));
  };

export const getSingleAdminPermission = (id, reset) => (dispatch) => {
  dispatch({ type: actions.GET_SINGLE_ADMIN_PERMISSION_BEGIN });
  getSingleAdminPermissionApi(id)
    .then((res) => {
      reset && reset({});
      dispatch({
        type: actions.GET_SINGLE_ADMIN_PERMISSION_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_SINGLE_ADMIN_PERMISSION_FAIL,
      });
    });
};

export const getOtherAdminPermission = (id, reset) => (dispatch) => {
  dispatch({ type: actions.GET_OTHER_ADMIN_PERMISSION_BEGIN });
  getSingleAdminPermissionApi(id)
    .then((res) => {
      reset && reset({});
      dispatch({
        type: actions.GET_OTHER_ADMIN_PERMISSION_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_OTHER_ADMIN_PERMISSION_FAIL,
      });
    });
};

export const getJob = () => (dispatch) => {
  dispatch({ type: actions.GET_JOB_BEGIN });
  getJobApi()
    .then((res) => {
      dispatch({
        type: actions.GET_JOB_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_JOB_FAIL,
      });
    });
};

export const getUpdateJob = (slug) => (dispatch) => {
  dispatch({ type: actions.GET_JOB_UPDATE_BEGIN });
  getUpdateJobApi(slug)
    .then((res) => {
      dispatch({
        type: actions.GET_JOB_UPDATE_SUCCESS,
        payload: res.data,
      });
      dispatch(getJob());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_JOB_UPDATE_SUCCESS,
      });
    });
};

export const putUpdateJob =
  (slug, data, handleToggle, reset, handleError) => (dispatch) => {
    dispatch({ type: actions.GET_JOB_UPDATE_BEGIN });
    putUpdateJobApi(slug, data)
      .then((res) => {
        dispatch({
          type: actions.GET_JOB_UPDATE_SUCCESS,
          payload: res.data,
        });
        handleToggle(false);
        reset();
        dispatch(getJob());
        dispatch({ type: actions.CLEAN_JOB_CATEGORY_UPDATE });
      })
      .catch((err) => {
        console.log(err, "err");
        handleError && handleError(err.response.data?.errors);
        dispatch({
          type: actions.GET_JOB_UPDATE_SUCCESS,
        });
      });
  };

export const deleteJob = (slug, handleClose) => (dispatch) => {
  dispatch({ type: actions.JOB_DELETE_BEGIN });
  deleteJobApi(slug)
    .then((res) => {
      dispatch({
        type: actions.JOB_DELETE_SUCCESS,
        payload: res.id,
      });
      handleClose();
      dispatch(getJob());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.JOB_DELETE_FAIL,
      });
      handleClose();
      dispatch(getJob());
    });
};

export const getAllJob = () => (dispatch) => {
  dispatch({ type: actions.GET_ALL_JOB_BEGIN });
  getAllJobApi()
    .then((res) => {
      dispatch({
        type: actions.GET_ALL_JOB_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_ALL_JOB_FAIL,
      });
    });
};

export const postJobStatus = (slug, data) => (dispatch) => {
  dispatch({ type: actions.POST_JOB_STATUS_BEGIN });
  postJobStatusApi(slug, data)
    .then((res) => {
      dispatch({
        type: actions.POST_JOB_STATUS_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllJob());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.POST_JOB_STATUS_FAIL,
      });
    });
};
