import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import InsideHeader from "../../layout/InsideHeader";
import { useForm } from "react-hook-form";
import { serializer } from "../../utils/helper";
import { makeStyles } from "@mui/styles";

import { Box } from "@mui/system";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CustomInput } from "../common/customInput/CustomInput";
import { postSiteSetting, getSiteSetting } from "./redux/Action";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    "& .uploadAndEdit": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  about: {
    "& .MuiOutlinedInput-root": {
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
    },
  },
}));

const Reward = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { siteData, isLoading } = useSelector((state) => state.siteSetting);

  useEffect(() => {
    dispatch(getSiteSetting());
  }, []);

  const defaultValues = {
    referral_point: "",
    profile_completion_point: "",
    signup_point: "",
  };

  const schema = yup
    .object({
      referral_point: yup.number().required("Reward to profile is required"),
      profile_completion_point: yup
        .number()
        .required("Reward for affiliation is required"),
      signup_point: yup.number().required("Reward for affiliation is required"),
    })
    .required();

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    const referralPoint = siteData?.find(
      (data) => data?.key === "referral_point"
    );
    const profileCompletionPoint = siteData?.find(
      (data) => data?.key === "profile_completion_point"
    );

    const signUpPoint = siteData?.find((data) => data?.key === "signup_point");
    setValue("referral_point", referralPoint?.value);
    setValue("profile_completion_point", profileCompletionPoint?.value);
    setValue("signup_point", signUpPoint?.value);
  }, [siteData]);

  const onSubmit = (values) => {
    dispatch(postSiteSetting(values));
  };

  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          background: "#f8f8f8",
          marginBottom: "15px",
        }}
      >
        <InsideHeader title="Reward">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Setting
            </Link>
            <Typography color="textPrimary">Reward</Typography>
          </Breadcrumbs>
        </InsideHeader>
      </Paper>

      <Paper
        elevation={1}
        className={classes.paper}
        sx={{ padding: "25px", marginBottom: "30px" }}
      >
        <div className={classes.root}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => checkKeyDown(e)}
          >
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <CustomInput
                  label="Set Reward to Profile Complete"
                  control={control}
                  errors={errors}
                  name="referral_point"
                  type="number"
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.referral_point?.message}
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <CustomInput
                  label="Set Reward to for Affiliation"
                  control={control}
                  errors={errors}
                  name="profile_completion_point"
                  type="number"
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.profile_completion_point?.message}
                </Typography>
              </Grid>

              <Grid item sm={12}>
                <CustomInput
                  label="Set Reward to for Sign up"
                  control={control}
                  errors={errors}
                  name="signup_point"
                  type="number"
                />
                <Typography sx={{ color: "red !important", fontSize: "12px" }}>
                  {errors?.profile_completion_point?.message}
                </Typography>
              </Grid>
              <Grid item sm={2}>
                <Box
                  marginTop={2.5}
                  marginLeft={0}
                  sx={{ margiLeft: "0px", marginTop: "0px" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                  >
                    {!siteData?.passwordChanging ? (
                      "Submit"
                    ) : (
                      <CircularProgress size={25} sx={{ color: "white" }} />
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </div>
      </Paper>
    </>
  );
};

export default Reward;
