import * as actions from "./Type";

const initialState = {
  isLoading: false,
  newsLetter: [],
  singleNewsLetter: [],
  contact: [],
  singleContact: [],
};

export const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_NEWSLETTER_BEGIN:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_NEWSLETTER_SUCCESS:
      return {
        ...state,
        newsLetter: action.payload.data,
        isLoading: false,
      };

    case actions.GET_NEWSLETTER_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case actions.GET_CONTACT_BEGIN:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_CONTACT_SUCCESS:
      return {
        ...state,
        contact: action.payload.data,
        isLoading: false,
      };

    case actions.GET_CONTACT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actions.DELETE_CONTACT_BEGIN:
      return {
        ...state,
        isLoading: true,
      };

    case actions.DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actions.DELETE_CONTACT_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    // case actions.GET_SINGLE_NEWSLETTER_BEGIN:
    //     return {
    //         ...state,
    //         isLoading: true
    //     }

    // case actions.GET_SINGLE_NEWSLETTER_SUCCESS:
    //     return {
    //         ...state,
    //         singleNewsLetter: action.payload.data
    //     }

    // case actions.GET_SINGLE_NEWSLETTER_SUCCESS:
    //     return {
    //         ...state,
    //         isLoading: false
    //     }

    default:
      return state;
  }
};
