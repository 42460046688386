import {
  getUserClientListApi,
  getUsersApi,
  postUserStatusApi,
} from "../../../api/users/UserApi";
import * as actions from "./Type";

export const getUsers = () => (dispatch) => {
  dispatch({ type: actions.GET_USERS_BEGIN });
  getUsersApi()
    .then((res) => {
      dispatch({
        type: actions.GET_USERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.GET_USERS_FAIL,
      });
    });
};

export const postUserStatus = (id, data) => (dispatch) => {
  dispatch({ type: actions.POST_USER_STATUS_BEGIN });
  postUserStatusApi(id, data)
    .then((res) => {
      dispatch({
        type: actions.POST_USER_STATUS_SUCCESS,
        payload: res.data,
      });

      dispatch(getUsers());
    })
    .catch((err) => {
      console.log(err, "err");
      dispatch({
        type: actions.POST_USER_STATUS_FAIL,
      });
    });
};

export const getUserClientList = (id) => (dispatch) => {
  dispatch({ type: actions.GET_USER_CLIENT_LIST_BEGIN });
  getUserClientListApi(id)
    .then((res) => {
      dispatch({
        type: actions.GET_USER_CLIENT_LIST_SUCCESS,
        payload: res.data,
      });

      dispatch(getUsers());
    })
    .catch((err) => {
      dispatch({
        type: actions.GET_USER_CLIENT_LIST_FAIL,
      });
    });
};
