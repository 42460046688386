import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "./redux/Action";
import Search from "../common/SearchBar";
import { Breadcrumbs, Skeleton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import InsideHeader from "../../layout/InsideHeader";
import CustomTableRow from "./CustomTableRow";
import CustomButton from "../common/customButton/CustomButton";
import { useReactToPrint } from "react-to-print";
import Html2Pdf from "js-html2pdf";

const columns = [
  {
    id: "name",
    label: "Name",
    minWidth: 70,
  },

  {
    id: "email",
    label: "Email",
    minWidth: 50,
  },
  {
    id: "mobile",
    label: "Phone",
    minWidth: 50,
    isInProfile: true,
  },

  {
    id: "location",
    label: "Address",
    minWidth: 50,
    isInProfile: true,
  },
  {
    id: "looking_for",
    label: "Looking For",
    minWidth: 60,
  },
  // {
  //   id: "active",
  //   label: "Status",
  //   minWidth: 100,
  //   align: "center",
  // },
  {
    id: "action",
    label: "Action",
    minWidth: 100,
    align: "center",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),

    "& .MuiTableCell-stickyHeader": {
      background: theme.palette.background.dark,
      whiteSpace: "nowrap",
      //   color: theme.palette.primary.dark,
    },
    "& .MuiTableBody-root": {
      whiteSpace: "nowrap",
      "@media print": {
        whiteSpace: "nowrap",
      },
      "& tr:nth-of-type(odd)": {
        background: theme.palette.background.main,
      },
    },
  },
  delete: {
    color: "#ff6363",
    cursor: "pointer",
    "&:hover": {
      color: "red",
    },
  },
  container: {
    // maxHeight: 550,
    borderRadius: "5px",
    "@media print": {
      padding: "25px 50px",
    },
  },
}));

function ViewUsers() {
  const dispatch = useDispatch();

  const users = useSelector((state) => state.users);

  const classes = useStyles();
  const rows = users.users?.data || [];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [newRows, setNewRows] = useState();
  const [search, setSearch] = useState();
  const tableRef = useRef();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    const newData = rows?.filter((item) => {
      const lookingForArray = item?.profile?.looking_for?.map(
        (item) => item?.title
      );

      const searchHasLookinFor = lookingForArray?.some((item) =>
        item?.toLowerCase()?.includes(search?.toLowerCase())
      );
      return (
        item?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
        searchHasLookinFor
      );
    });
    setNewRows(newData);
  }, [search]);

  // const generatePDF = () => {
  //   const report = new JsPDF("landscape", "pt", "a3");
  //   report.html(tableRef.current).then((element) => {
  //     report.save("report.pdf");
  //   });
  // };

  const handleShare = useReactToPrint({
    onPrintError: (error) => console.log(error),
    content: () => tableRef.current,
    // documentTitle: `${documentTitle}.pdf`,
    copyStyles: true,
    removeAfterPrint: true,
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      if (document) {
        // const html = document.getElementsByTagName("html")[0];
        const html = document.querySelector("#print-table");
        console.log(html);
        // await html2pdf().from(html).save();
        //
        const options = {
          margin: 10,
          filename: "users.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: {
            scale: 2,
            logging: true,
            dpi: 192,
            letterRendering: true,
          },
          jsPDF: { unit: "pt", format: "a4", orientation: "landscape" },
        };
        const exporter = new Html2Pdf(html, options);
        await exporter.getPdf(true);
      }
    },
  });

  // const handlePrint = useReactToPrint({
  //   content: () => tableRef.current,
  //   copyStyles: false,
  //   print: async (printIframe) => {
  //     // Do whatever you want here, including asynchronous work
  //     console.log("print iframe", printIframe);
  //     generatePDF(printIframe);
  //   },
  // });

  const skeleton = () => (
    <>
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
    </>
  );

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          background: "#f8f8f8",
          marginBottom: "15px",
        }}
      >
        <InsideHeader title="Users">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Users
            </Link>
            <Typography color="textPrimary">Table</Typography>
          </Breadcrumbs>
        </InsideHeader>

        <CustomButton
          disabled={users?.userLoading}
          type="button"
          onClick={handleShare}
          parentDivStyle={{
            marginLeft: "auto",
            marginRight: "15px",
            padding: "3px 10px",
          }}
        >
          Export PDF
        </CustomButton>

        {/* <ReactToPrint
          trigger={() => (
            <CustomButton
              disabled={users?.userLoading}
              type="button"
              parentDivStyle={{
                marginLeft: "auto",
                marginRight: "15px",
                padding: "3px 10px",
              }}
            >
              Export PDF 2
            </CustomButton>
          )}
          content={() => tableRef.current}
        />*/}
        <Search setSearch={setSearch} />
      </Paper>

      <Paper className={classes.root} sx={{ maxWidth: "calc(100vw - 300px)" }}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>S.No.</TableCell>
                {columns.map((column) => (
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      "@media print": {
                        display: column.id === "action" && "none",
                      },
                    }}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {!users?.userLoading && (
              <TableBody>
                {newRows != ""
                  ? (newRows || [])
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <CustomTableRow
                            row={row}
                            index={index}
                            columns={columns}
                          />
                        );
                      })
                  : rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <CustomTableRow
                            row={row}
                            index={index}
                            columns={columns}
                          />
                        );
                      })}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <div style={{ display: "none" }}>
          <TableContainer
            className={classes.container}
            id="print-table"
            ref={tableRef}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>S.No.</TableCell>
                  {columns.map((column) => (
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        display: column.id === "action" && "none",
                        "@media print": {
                          display: column.id === "action" && "none",
                        },
                      }}
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {!users?.userLoading && (
                <TableBody>
                  {newRows != ""
                    ? (newRows || [])
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <CustomTableRow
                              row={row}
                              index={index}
                              columns={columns}
                              print={true}
                            />
                          );
                        })
                    : rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <CustomTableRow
                              row={row}
                              index={index}
                              columns={columns}
                              print={true}
                            />
                          );
                        })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
        {users.userLoading && skeleton()}
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

export default ViewUsers;
