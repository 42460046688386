import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomHiddenInput } from '../common/customInput/CustomHiddenInput';
import UploadIcon from '@mui/icons-material/Upload';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';
import { deleteFallbackImage } from './redux/Action';
import CustomModal from '../common/customModal/CustomModal';

const useStyles = makeStyles((theme) => ({
  delete: {
    color: '#ff6363',
    cursor: 'pointer',
    marginLeft: '10px',
    '&:hover': {
      color: 'red',
    },
  },
  modalDelete: {
    color: '#ff6363',
    cursor: 'pointer',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: '#DB143C !important',
    },
  },
}));

const FallbackImageField = React.forwardRef(
  ({ title, name, watch, control, displayImage }, ref) => {
    const upload = useRef(null);
    const dispatch = useDispatch();
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [deleteKey, setDeleteKey] = useState();

    const uploadImage = () => {
      upload.current.click();
    };
    const image = watch(name);

    const handleModal = (key) => {
      handleOpen();
      setDeleteKey(key);
    };

    const handleDelete = () => {
      dispatch(deleteFallbackImage(deleteKey));
      handleClose();
    };

    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
          <div className="uploadAndEdit">
            <label
              style={{
                color: '#828282',
                fontSize: '14px',
                paddingBottom: '4px',
              }}>
              {title}
            </label>
            {displayImage && (
              <CancelIcon
                sx={{
                  cursor: 'pointer',
                  opacity: '0.5',
                  fontSize: '16px',
                  '&:hover': {
                    color: 'red !important',
                  },
                }}
                onClick={() => handleModal(displayImage?.key)}
              />
            )}
          </div>
          <div
            onClick={uploadImage}
            style={{
              height: '179px',
              width: '100%',
              background: '#f4f9ff',
              border: '1px solid #e4e4e4',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              cursor: 'pointer',
            }}>
            <img
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                objectFit: 'cover',
              }}
              src={!image ? displayImage?.image : URL?.createObjectURL(image)}
              width="100%"
              height="100%"
              alt=""
            />
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}>
              <UploadIcon />
              Upload
            </span>
          </div>
          <CustomHiddenInput
            style={{
              display: 'none',
            }}
            className="uploadImage"
            type="file"
            accept="image/*"
            control={control}
            name={name}
            ref={upload}
          />
        </div>

        <CustomModal
          open={open}
          handleClose={handleClose}
          handleDelete={handleDelete}
          deleteStyle={classes.modalDelete}
          loading={false}
        />
      </>
    );
  }
);

export default FallbackImageField;
