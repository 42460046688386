import { List } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";

import React, { useEffect } from "react";

import FeedIcon from "@mui/icons-material/Feed";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import PeopleIcon from "@mui/icons-material/People";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { getSingleAdminPermission } from "../components/role/redux/Action";
import HasPermission from "../utils/HasPermission";
import * as permissionList from "../utils/PermissionList";
import SidebarDropDown from "./SidebarDropdown";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    display: "block",
    color: "gray",

    "&.active": {
      background: "hsl(348deg 83% 47%)",
      color: "white",
      "& svg": {
        color: "#fff !important",
      },
    },

    // '& .MuiListItemText-primary': {
    //   fontWeight: '600'
    // }
  },

  listItem: {
    color: "gray",
    padding: "8px 0 !important",

    "& .MuiListItemButton-root": {
      padding: "0px 15px !important",
    },
  },
}));

const ListItems = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openedDropdown, setOpenedDropdown] = React.useState("");
  const dispatch = useDispatch();
  const handleClick = (name) => {
    if (openedDropdown === name) {
      setOpenedDropdown(false);
      setOpen(false);
      return;
    }
    setOpenedDropdown(name);
    setOpen(true);
  };

  useEffect(() => {
    dispatch(getSingleAdminPermission(localStorage.getItem("user")));
  }, []);

  return (
    <List>
      <NavLink to="/" className={classes.link}>
        <ListItem button className={classes.listItem}>
          <ListItemIcon sx={{ paddingLeft: "15px !important" }}>
            <InboxIcon sx={{ color: "#fff" }} />
          </ListItemIcon>
          <ListItemText primary="Dashboard" sx={{ color: "#fff" }} />
        </ListItem>
      </NavLink>
      <HasPermission of={permissionList.USER}>
        <NavLink to="/users" className={classes.link}>
          <ListItem button className={classes.listItem}>
            <ListItemIcon sx={{ paddingLeft: "15px !important" }}>
              <PeopleIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Users" sx={{ color: "#fff" }} />
          </ListItem>
        </NavLink>
      </HasPermission>
      {/* <NavLink to='/rental' className={classes.link} > */}
      <HasPermission of={permissionList.RENTAL}>
        <ListItem button className={classes.listItem}>
          <SidebarDropDown
            optionOne="Rental"
            optionTwo="Category"
            showOptionTwo={permissionList.RENTAL_CATEGORY}
            feature={
              <HasPermission of={permissionList.RENTAL_FEATURE}>
                Feature
              </HasPermission>
            }
            optionThree="Amenities"
            showOptionThree={permissionList.RENTAL_FEATURE}
            optionFour="List"
            showOptionFour={permissionList.RENTAL_LIST}
            navPath="rental"
            firstPathId="category"
            secondPathId="amenities"
            thirdPathId="lists"
            handleClick={handleClick}
            open={open}
            name="first"
            openedDropdown={openedDropdown}
            showCategoryIcon={permissionList.RENTAL_CATEGORY}
            showFeatureIcon={permissionList.RENTAL_FEATURE}
          />
        </ListItem>
      </HasPermission>
      <HasPermission of={permissionList.RENTAL}>
        <ListItem button className={classes.listItem}>
          <SidebarDropDown
            optionOne="Sale"
            optionTwo="Category"
            showOptionTwo={permissionList.SALE_CATEGORY}
            feature={
              <HasPermission of={permissionList.SALE_FEATURE}>
                Feature
              </HasPermission>
            }
            optionThree="List"
            showOptionThree={permissionList.SALE_FEATURE}
            showOptionFour={permissionList.SALE_LIST}
            navPath="sale"
            firstPathId="category"
            secondPathId="lists"
            handleClick={handleClick}
            open={open}
            name="sale"
            openedDropdown={openedDropdown}
            showCategoryIcon={permissionList.SALE_CATEGORY}
            showFeatureIcon={permissionList.SALE_FEATURE}
          />
        </ListItem>
      </HasPermission>
      <HasPermission of={permissionList.PERMISSION}>
        <ListItem button className={classes.listItem}>
          <SidebarDropDown
            optionOne="Role"
            optionTwo="User Management"
            showOptionTwo={permissionList.PERMISSION}
            navPath="role"
            firstPathId="category"
            secondPathId="lists"
            handleClick={handleClick}
            open={open}
            name="seventh"
            openedDropdown={openedDropdown}
          />
        </ListItem>
      </HasPermission>
      {/* </NavLink> */}
      {/* <NavLink to="/job" className={classes.link}> */}
      <HasPermission of={permissionList.JOB}>
        <ListItem button className={classes.listItem}>
          <SidebarDropDown
            optionOne="Job"
            optionTwo="Category"
            showOptionTwo={permissionList.JOB_CATEGORY}
            optionThree="List"
            showOptionThree={permissionList.JOB_LIST}
            navPath="job"
            firstPathId="category"
            secondPathId="lists"
            handleClick={handleClick}
            open={open}
            name="second"
            openedDropdown={openedDropdown}
            showCategoryIcon={permissionList.RENTAL_CATEGORY}
          />
        </ListItem>
      </HasPermission>
      {/* </NavLink> */}
      {/* <NavLink to="/business" className={classes.link}> */}
      <HasPermission of={permissionList.BUSINESS}>
        <ListItem button className={classes.listItem}>
          <SidebarDropDown
            optionOne="Business"
            optionTwo="Category"
            showOptionTwo={permissionList.BUSINESS_CATEGORY}
            optionThree="List"
            showOptionThree={permissionList.BUSINESS_LIST}
            optionFour="Reviews"
            showOptionFour={permissionList.USER_REVIEW}
            navPath="business"
            firstPathId="category"
            secondPathId="lists"
            thirdPathId="userReviews"
            handleClick={handleClick}
            open={open}
            name="third"
            openedDropdown={openedDropdown}
            showCategoryIcon={permissionList.BUSINESS_CATEGORY}
          />
        </ListItem>
      </HasPermission>
      {/* </NavLink> */}
      <HasPermission of={permissionList.BLOG}>
        <NavLink to="/blogs" className={classes.link}>
          <ListItem button className={classes.listItem}>
            <ListItemIcon sx={{ paddingLeft: "15px !important" }}>
              <WysiwygIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText
              primary="Blogs"
              sx={{ color: "#fff", fontWeight: "600 !important" }}
            />
          </ListItem>
        </NavLink>
      </HasPermission>
      <HasPermission of={permissionList.QUICK_POST}>
        <NavLink to="/quickpost" className={classes.link}>
          <ListItem button className={classes.listItem}>
            <ListItemIcon sx={{ paddingLeft: "15px !important" }}>
              <FeedIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText
              primary="Quick Posts"
              sx={{ color: "#fff", fontWeight: "600 !important" }}
            />
          </ListItem>
        </NavLink>
      </HasPermission>

      {/* <NavLink to="/setting" className={classes.link}> */}
      <ListItem button className={classes.listItem}>
        <SidebarDropDown
          optionOne="Settings"
          // optionTwo={
          //   <HasPermission of={permissionList.SITE_SETTING}>
          //     Site Settings
          //   </HasPermission>
          // }
          optionTwo="Site Settings"
          showOptionTwo={permissionList.SITE_SETTING}
          optionThree="Banners"
          showOptionThree={permissionList.BANNER}
          optionFour=" Change Password"
          showOptionFour={permissionList.CHANGE_PASSWORD}
          optionFive="Fallback Image"
          showOptionFive={permissionList.FALLBACK_IMAGE}
          optionSix="Reward"
          showOptionSix={permissionList.REWARD}
          optionSeven="Looking For"
          showOptionSeven={permissionList.LOOKING_FOR}
          navPath="setting"
          firstPathId="siteSettings"
          secondPathId="banners"
          thirdPathId="password"
          fourthPathId="fallbackImage"
          fifthPathId="reward"
          seventhPathId="lookingFor"
          handleClick={handleClick}
          open={open}
          name="forth"
          openedDropdown={openedDropdown}
          showCategoryIcon={permissionList.SITE_SETTING}
        />
      </ListItem>
      {/* </NavLink> */}

      {/* <NavLink to="/adsManagement" className={classes.link}> */}
      <HasPermission of={permissionList.ADS_MANAGEMENT}>
        <ListItem button className={classes.listItem}>
          <SidebarDropDown
            optionOne="Ads Management"
            optionTwo="Home Page"
            showOptionTwo={permissionList.HOME_ADS}
            optionThree="Jobs Page"
            showOptionThree={permissionList.JOB_ADS}
            optionFour="Rentals Page"
            showOptionFour={permissionList.RENTAL_ADS}
            optionFive="Business Page"
            showOptionFive={permissionList.BUSINESS_ADS}
            optionSix="Promoted Content"
            showOptionSix={permissionList.PROMOTED_CONTENT_ADS}
            optionSeven="Blog Page"
            showOptionSeven={permissionList.BLOG_ADS}
            optionEight="Quick Post Page"
            showOptionEight={permissionList.QUICK_POST_ADS}
            optionNinth="Category Promotional"
            showOptionNinth={permissionList.CATEGORY_PROMOTIONAL_ADS}
            optionTen="Sale Page"
            showOptionTen={permissionList.RENTAL_ADS}
            navPath="adsManagement"
            firstPathId="homepage"
            secondPathId="jobspage"
            thirdPathId="rentalsPage"
            fourthPathId="businessPage"
            fifthPathId="promotedContent"
            seventhPathId="blogPage"
            eightPathId="quickPostPage"
            ninthPathId="categoryPromotional"
            tenPathId="salePage"
            handleClick={handleClick}
            open={open}
            name="fifth"
            openedDropdown={openedDropdown}
          />
        </ListItem>
      </HasPermission>
      {/* </NavLink> */}
      <HasPermission of={permissionList.CONTACT_US}>
        <ListItem button className={classes.listItem}>
          <SidebarDropDown
            optionOne="Contact"
            optionTwo="Contact Us"
            showOptionTwo={permissionList.CONTACT_US}
            optionThree="Newsletter"
            showOptionThree={permissionList.NEWSLETTER}
            navPath="contact"
            firstPathId="contactUs"
            secondPathId="newsLetter"
            handleClick={handleClick}
            open={open}
            name="sixth"
            openedDropdown={openedDropdown}
          />
        </ListItem>
      </HasPermission>
    </List>
  );
};

export default ListItems;
