import {
  Autocomplete,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import InsideHeader from "../../layout/InsideHeader";
import { CustomSelect } from "../common/customSelect/CustomSelect";
import ViewCategoryPromotion from "./ViewCategoryPromotion";
import {
  getCategoryPromotional,
  postCategoryPromotional,
} from "./redux/Action";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    // paddingRight: '16px',

    "& .uploadAndEdit": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },

  descriptionField: {
    "& .MuiOutlinedInput-root": {
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
    },
  },

  delete: {
    backgroundColor: "#ff6363 !important",
    color: "white",
    cursor: "pointer",
    marginLeft: "10px",
    fontSize: "14px !important",
    "&:hover": {
      backgroundColor: "#DB143C !important",
    },
  },
  labelItem: {
    marginBottom: "-10px",
  },
  auto: {
    marginTop: "-20px",
    "& .MuiOutlinedInput-input": {
      padding: "8px 14px",
      border: "1px solid #e4e4e4",
      background: theme.palette.background.main,
      borderRadius: theme.spacing(0.5),
    },
    "& .MuiTextField-root": {
      marginTop: "6px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiFormControl-fullWidth": {
      width: "100%",
    },
    "& .MuiInputLabel-outlined": {
      marginTop: "-20px",
      fontSize: "16px",
      marginLeft: "-10px",
    },

    // "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
    //   transform: "translate(0px, 0px) ",
    // },
  },
}));

let isInitial = false;
function CategoryPromotion() {
  const classes = useStyles();
  const [toggle, setToggle] = useState(false);
  const [buttonName, setButtonName] = useState("Add");
  const [postId, setPostId] = useState("");
  const dispatch = useDispatch();

  const defaultValues = {
    category: "",
    content: {
      label: "",
      value: "",
    },
  };

  const categoryContent = useSelector((state) => state.ads);

  // const {updateLoading } = categoryContent

  console.log("Category Content is ", categoryContent);

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: defaultValues,
  });

  const categoryWatch = watch("category");
  const contentWatch = watch("content");

  console.log("Watch Content ", contentWatch);

  const categoryOptions = [
    {
      value: "rental",
      label: "Rental",
    },
    {
      value: "business",
      label: "Business",
    },
    {
      value: "job",
      label: "Jobs",
    },
    {
      value: "quickpost",
      label: "Quick Post",
    },
    {
      value: "blog",
      label: "Blog",
    },
    {
      value: "sale",
      label: "Sale",
    },
  ];

  const contentOptions = categoryContent?.category_promotional?.content?.map(
    (obj) => {
      return { value: obj.id, label: obj.title };
    }
  );

  useEffect(() => {
    if (!isInitial) {
      isInitial = true;
      return;
    }
    dispatch(getCategoryPromotional({ category: categoryWatch }));
  }, [categoryWatch]);

  const handleToggle = () => {
    // dispatch({ type: actions.CLEAN_CATEGORY_UPDATE });
    setButtonName("Add");
    setToggle((prev) => !prev);
    // reset();
  };

  const onSubmit = (values) => {
    console.log("Values are ", values);

    dispatch(
      postCategoryPromotional({
        post_id: postId,
        category: values.category,
      })
    );
  };

  const handleCancelButton = (values, e) => {};

  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };

  const categoryChangeHandler = (e) => {
    console.log("Category has runned hehe");
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: "100000",
    }),

    // control: (_, { selectProps: { width }}) => ({
    //   width: width
    // }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          background: "#f8f8f8",
          marginBottom: "15px",
        }}
      >
        <InsideHeader title="Category Promotional">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" to="/">
              ads
            </Link>
            <Typography color="textPrimary">Category Promotional</Typography>
          </Breadcrumbs>
        </InsideHeader>
        <Button
          sx={{ height: "40px" }}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleToggle}
        >
          Add Category Promotional
        </Button>
      </Paper>

      {toggle && (
        <Paper
          elevation={1}
          className={classes.paper}
          sx={{ padding: "25px", marginBottom: "30px" }}
        >
          <div className={classes.root}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => checkKeyDown(e)}
            >
              <Grid container spacing={2}>
                <Grid item sm={5}>
                  <label className={classes.labelItem}>Category</label>

                  <CustomSelect
                    control={control}
                    errors={errors}
                    name="category"
                    options={categoryOptions}
                    onChange={categoryChangeHandler}
                  />
                </Grid>

                <Grid className={classes.auto} item sm={5}>
                  <label className={classes.labelItem}>Content</label>
                  <Autocomplete
                    id="content"
                    clearOnEscape
                    name="content"
                    options={contentOptions}
                    onChange={(event, data) => {
                      setPostId(data.value);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />

                  {/* <AutoCompleteSelect name = "content" control = {control} options = {contentOptions} /> */}
                </Grid>

                <Grid item sm={2}>
                  {buttonName === "Add" ? (
                    <Box
                      marginTop={2.5}
                      marginLeft={0}
                      sx={{
                        margiLeft: "0px",
                        marginTop: "0px",
                        display: "flex",
                      }}
                    >
                      <Button
                        sx={{ marginRight: "10px", fontSize: "14px" }}
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                      >
                        {!categoryContent?.updateLoading ? (
                          `${buttonName}`
                        ) : (
                          <CircularProgress size={30} sx={{ color: "white" }} />
                        )}
                      </Button>
                      <Button
                        className={classes.delete}
                        variant="contained"
                        fullWidth
                        size="large"
                        onClick={() => setToggle((prev) => !prev)}
                      >
                        Cancel
                      </Button>
                    </Box>
                  ) : (
                    <Box
                      marginTop={2.5}
                      marginLeft={0}
                      sx={{
                        margiLeft: "0px",
                        marginTop: "0px",
                        display: "flex",
                      }}
                    >
                      <Button
                        sx={{ marginRight: "10px", fontSize: "14px" }}
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                      >
                        {/* {!updateRentals?.updateLoading ? (
                          `${buttonName}`
                        ) : (
                          <CircularProgress size={30} sx={{ color: "white" }} />
                        )} */}
                      </Button>
                      <Button
                        className={classes.delete}
                        variant="contained"
                        fullWidth
                        size="large"
                        onClick={handleCancelButton}
                      >
                        Cancel
                      </Button>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </form>
          </div>
        </Paper>
      )}
      <ViewCategoryPromotion
        setToggle={setToggle}
        setButtonName={setButtonName}
      />
    </>
  );
}

export default CategoryPromotion;
