import { Button, Grid, Switch, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { serializer } from "../../utils/helper";
import UploadIcon from "@mui/icons-material/Upload";
import { CustomHiddenInput } from "../common/customInput/CustomHiddenInput";
import { CustomInput } from "../common/customInput/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import {
  postPromotedContent,
  postPromotedContentStatus,
  updatePromotedContent,
  updatePromotedContents,
} from "./redux/Action";
import CustomTextarea from "../common/customTextarea/CustomTextarea";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { deletePromotedContentApi } from "../../api/adsManagement/AdsManagementApi";
import * as actions from "./redux/Type";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const useStyles = makeStyles((theme) => ({
  descriptionField: {
    "& .MuiOutlinedInput-root": {
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
    },
  },

  delete: {
    bgcolor: "#ff000094 !important",
    padding: "10px !important",
    "&:hover": {
      backgroundColor: "#DB143C !important",
    },
  },

  edit: {
    backgroundColor: "#008000a6 !important",
    fontSize: "12px !important",
    "&:hover": {
      backgroundColor: "green !important",
    },
  },
}));

const PromotedForm = ({
  promotedContent,
  handleToggle,
  buttonName,
  setButtonName,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { updatePromotedContent } = useSelector((state) => state?.ads);

  const defaultValues = {
    title: "",
    description: "",
    link: "",
    image: "",
  };

  const schema = yup
    .object({
      title: yup.string().required("Title is required"),
      description: yup.string().required("Description is required"),
      image: yup.mixed().required("Image is required"),
      link: yup.string().required("Link is required"),
    })
    .required();

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
    setError,
  } = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema) });

  const upload = useRef(null);
  const image = watch("image");
  const uploadImage = () => {
    upload.current.click();
  };

  const handleError = (errors) => {
    console.log(errors, "errors");
    Object.entries(errors)?.map((item) => {
      console.log("error", item);
      setError(item[0], { message: item[1] });
    });
  };

  const onSubmit = (values, e) => {
    if (updatePromotedContent?.title) {
      const formData = new FormData();
      serializer(formData, { ...values, _method: "patch" });
      dispatch(
        updatePromotedContents(
          updatePromotedContent?.slug,
          formData,
          handleToggle,
          handleError
        )
      );
    } else {
      const formData = new FormData();
      serializer(formData, values);
      dispatch(postPromotedContent(formData, handleToggle, reset, handleError));
    }
  };

  const handleDelete = (slug) => {
    dispatch(deletePromotedContentApi(slug));
  };

  const handleCancel = () => {
    dispatch({ type: actions.CLEAN_PROMOTED_CONTENT_UPDATE });
    handleToggle(false);
    reset();
  };

  useEffect(() => {
    setValue("title", updatePromotedContent?.title);
    setValue("description", updatePromotedContent?.description);
    setValue("link", updatePromotedContent?.link);
    // setValue('image', updatePromotedContent?.image);
  }, [updatePromotedContent, setValue]);

  const getSwitchValue = (e, value, slug) => {
    dispatch(
      postPromotedContentStatus(slug, {
        _method: "patch",
        value: value === true ? 1 : 0,
      })
    );
  };

  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };
  console.log(updatePromotedContent, "formData");

  return (
    <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
      <Grid container spacing={2}>
        {/* {promotedContent && (
          <Grid item sm={12}>
            <Switch
              defaultChecked={
                promotedContent?.active?.title === 'active' ? 1 : 0
              }
              inputProps={{ 'aria-label': 'ant design' }}
              onChange={(e, value) =>
                getSwitchValue(e, value, promotedContent?.slug)
              }
            />
            {promotedContent?.active?.title === 'active'
              ? 'Active'
              : 'Inactive'}
          </Grid>
        )} */}
        <Grid item sm={12}>
          <CustomInput
            label="Title"
            control={control}
            name="title"
            errors={errors}
          />
          <Typography sx={{ color: "red !important", fontSize: "12px" }}>
            {errors?.title?.message}
          </Typography>
        </Grid>
        <Grid item sm={12} className={classes.descriptionField}>
          <CustomTextarea
            label="Description"
            control={control}
            name="description"
            errors={errors}
            rows={5}
          />
          <Typography sx={{ color: "red !important", fontSize: "12px" }}>
            {errors?.description?.message}
          </Typography>
        </Grid>

        <Grid item sm={12} sx={{ paddingTop: "8px !important" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="uploadAndEdit">
              <label style={{ color: "#828282", fontSize: "14px" }}>
                Upload Image
              </label>
            </div>
            <div
              onClick={uploadImage}
              style={{
                height: "250px",
                width: "400px",
                background: "#f4f9ff",
                border: "1px solid #e4e4e4",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                cursor: "pointer",
              }}
            >
              <img
                style={{ position: "absolute", top: "0", left: "0" }}
                src={
                  !image
                    ? updatePromotedContent?.image
                    : URL?.createObjectURL(image)
                }
                width="100%"
                height="100%"
                alt=""
              />
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <UploadIcon />
              </span>
            </div>
            <CustomHiddenInput
              style={{
                display: "none",
              }}
              className="uploadImage"
              type="file"
              accept="image/*"
              control={control}
              name="image"
              ref={upload}
            />
            <Typography sx={{ color: "red !important", fontSize: "12px" }}>
              {errors?.image?.message}
            </Typography>
          </div>
        </Grid>

        <Grid item sm={12}>
          <CustomInput
            label="Link"
            control={control}
            name="link"
            errors={errors}
          />
          <Typography sx={{ color: "red !important", fontSize: "12px" }}>
            {errors?.link?.message}
          </Typography>
        </Grid>

        <Grid item sm={1}>
          {/* {!promotedContent && (
            <Box
              marginTop={2.5}
              marginLeft={0}
              sx={{
                display: 'flex',
                margiLeft: '0px',
                marginTop: '0px',
                fontSize: '13px',
                width: 'fi-content',
              }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit">
                Post
              </Button>
            </Box>
          )}
          {promotedContent && ( */}
          <Box
            marginTop={2.5}
            marginLeft={0}
            sx={{
              display: "flex",
              margiLeft: "0px",
              marginTop: "0px",
              fontSize: "13px",
              width: "fi-content",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              className={classes.edit}
            >
              {buttonName}
            </Button>
            {/* <Button
              className={classes.delete}
              variant="contained"
              color="primary"
              size="large"
              sx={{
                bgcolor: '#ff000094 !important',
                marginLeft: '10px',
                fontSize: '12px',
              }}
              onClick={() => handleDelete(promotedContent?.slug)}>
              Delete
            </Button> */}
            <Button
              className={classes.delete}
              variant="contained"
              fullWidth
              size="large"
              sx={{
                bgcolor: "#ff000094 !important",
                marginLeft: "10px",
                fontSize: "12px",
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Box>
          {/* )} */}
        </Grid>
      </Grid>
    </form>
  );
};

export default PromotedForm;
