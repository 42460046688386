import React, { forwardRef } from "react";
import { Controller } from "react-hook-form";
import { FormHelperText, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        // marginTop: 8,
        "& .MuiOutlinedInput-input": {
            padding: "8px 14px",
            border: '1px solid #e4e4e4',
            background: theme.palette.background.main,
            borderRadius: theme.spacing(0.5),
        },
        "& .MuiTextField-root": {
            marginTop: theme.spacing(2),
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
        "& .MuiFormControl-fullWidth": {
            width: "100%",
        },
        "& .MuiInputLabel-outlined": {
            marginTop: "-26px",
            fontSize: "20px",
            marginLeft: "-10px",
        },

        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(14px, 3px) scale(.75)",
        },
    },
}));

export const CustomHiddenInput = forwardRef((props, ref) => {
    const {
        name,
        control,
        type = "text",
        errors,
        placeholder = null,
        label = "",
        fullWidth = true,
        defaultValue,
        rule = { required: false },
    } = props

    const classes = useStyles();
    return (
        <>
            <div className={classes.root}>
                <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    render={({ field: { onChange, value } }) => (
                        <input
                            type={type}
                            onChange={(e) => {
                                onChange(e.target.files[0])
                            }}
                            fullWidth={fullWidth}
                            placeholder={placeholder}
                            label={label}
                            size='medium'
                            ref={ref}
                            // InputLabelProps={{
                            //     shrink: true,
                            // }}
                            variant="outlined"
                            {...props}
                        />
                    )}
                    rules={rule}
                />
                {/* {errors[name] && errors[name].type === "required" && (
          <FormHelperText
            style={{ color: "red" }}
          >{`${name} is required`}</FormHelperText>
        )} */}
            </div>
        </>
    );
});
