import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";

import { useDispatch, useSelector } from "react-redux";
import { deleteBusinessReviews, getBusinessReviews } from "./redux/Action";
import IosShareIcon from "@mui/icons-material/IosShare";
import {
  Avatar,
  Box,
  Button,
  Modal,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CustomModal from "../common/customModal/CustomModal";
import CancelIcon from "@mui/icons-material/Cancel";
import FlagIcon from "@mui/icons-material/Flag";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StarIcon from "@mui/icons-material/Star";
import Loader from "../../pages/Home/Loader";

const columns = [
  {
    id: "title",
    label: "Business Title",
    minWidth: 100,
  },

  {
    id: "name",
    label: "Posted by ",
    minWidth: 150,
  },

  {
    id: "star",
    label: "Star",
    minWidth: 20,
    align: "center",
  },
  {
    id: "created_at",
    label: "Created At",
    align: "center",
  },

  {
    id: "flag",
    label: "Flagged/Unflagged",
    minWidth: 20,
    align: "center",
  },

  {
    id: "action",
    label: "Action",
    minWidth: 100,
    align: "center",
  },
];

const viewModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
    "& .MuiTableCell-stickyHeader": {
      background: theme.palette.background.dark,
    },
    "& .MuiTableBody-root": {
      "& tr:nth-of-type(odd)": {
        background: theme.palette.background.main,
      },
    },
  },
  btnStyle: {
    background: "#c0ddf9 !important",
    "&:hover": {
      background: "#c0ddf9 !important",
    },

    "&:active": {
      background: "grey !important",
    },
  },
  delete: {
    color: "#ff6363",
    cursor: "pointer",
    "&:hover": {
      color: "red",
    },
  },
  modalDelete: {
    color: "#ff6363",
    cursor: "pointer",
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: "#DB143C !important",
    },
  },
  container: {
    maxHeight: 550,
    borderRadius: "5px",
  },

  modalBox: {
    position: "relative",
    height: "40px",
    backgroundColor: "#C0DDF9",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    display: "flex",
    alignItems: "center",
  },

  cancelButton: {
    cursor: "pointer",
    color: "#dd6c82",
    position: "absolute",
    right: "10px",
    top: "10px",
    "&:hover": {
      color: "#DB143C",
    },
  },

  detailBox: {
    width: "100%",
    height: "100%",
    padding: "10px 30px",
    textAlign: "center",
    // display:'flex'
  },

  nameBox: {
    textAlign: "center",
    padding: "10px 0",
    width: "100%",
    borderTop: "1px solid #d9ecff",
  },
}));

function ViewReviews() {
  const frontUrl = process.env.REACT_APP_FRONT_URL;

  const dispatch = useDispatch();
  const reviews = useSelector((state) => state?.reviews);

  const classes = useStyles();
  const rows = reviews?.businessReviews || [];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filteredData, setFilteredData] = React.useState();
  const [debounceLoading, setDebounceLoading] = React.useState(false);

  const [singleReview, setSingleReview] = React.useState();

  const [deleteId, setDeleteId] = useState();

  const [open, setOpen] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEye = (id) => {
    setOpenView(true);

    const singleData = reviews?.businessReviews?.filter(
      (item) => item?.id === id
    );
    setSingleReview(singleData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleModal = (slug) => {
    setDeleteId(slug);
    handleOpen();
  };

  const handleDelete = () => {
    dispatch(deleteBusinessReviews(deleteId, handleClose));
  };

  //using debounce to delay the filtered data
  function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const handleUnflagedBtn = () => {
    const newData = rows?.filter((item) => item?.flag === 0);
    setFilteredData(newData);
  };

  const handleFlagedBtn = () => {
    const newData = rows?.filter((item) => item?.flag !== 0);
    setFilteredData(newData);
  };

  useEffect(() => {
    dispatch(getBusinessReviews());
  }, []);

  const skeleton = () => (
    <>
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
    </>
  );

  return (
    <>
      <Box sx={{ textAlign: "right", marginBottom: "5px", marginTop: "-60px" }}>
        <Tooltip arrow title="Unflaged reviews">
          <Button
            onClick={debounce(handleUnflagedBtn, 1000)}
            className={classes.btnStyle}
            sx={{ marginRight: "10px" }}
          >
            <CheckCircleIcon sx={{ color: "#259d16de", fontSize: "30px" }} />
          </Button>
        </Tooltip>
        <Tooltip arrow title="Flaged reviews">
          <Button
            onClick={debounce(handleFlagedBtn, 1000)}
            className={classes.btnStyle}
          >
            <FlagIcon sx={{ color: "#DB143C", fontSize: "30px" }} />
          </Button>
        </Tooltip>
      </Box>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>S.No.</TableCell>
                {columns.map((column) => (
                  <TableCell
                    sx={{ fontWeight: "bold" }}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {!reviews?.isLoading && (
              <TableBody>
                {!filteredData &&
                  rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          <TableCell>{index + 1}</TableCell>
                          {columns.map((column, ind) => {
                            const value = row[column.id];
                            if (column.id === "name") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {row?.reviewer?.name}
                                </TableCell>
                              );
                            }
                            if (column.id === "title") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {row?.business?.title}
                                </TableCell>
                              );
                            }

                            if (column.id === "flag") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {!row?.flag ? (
                                    <CheckCircleIcon
                                      sx={{
                                        color: "#259d16de",
                                        fontSize: "20px",
                                      }}
                                    />
                                  ) : (
                                    <FlagIcon sx={{ color: "#DB143C" }} />
                                  )}
                                </TableCell>
                              );
                            }

                            return columns?.length !== ind + 1 ? (
                              <TableCell key={column.id} align={column.align}>
                                {value}
                              </TableCell>
                            ) : (
                              <TableCell
                                align={column.align}
                                key={column.id}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Tooltip arrow title="View business">
                                  <a
                                    href={`${frontUrl}/api/business/${row?.business?.slug}`}
                                    target="_black"
                                    rel="noreferrer"
                                  >
                                    <IosShareIcon sx={{ fontSize: "20px" }} />
                                  </a>
                                </Tooltip>
                                <Tooltip arrow title="View review details">
                                  <RemoveRedEyeIcon
                                    sx={{
                                      cursor: "pointer",
                                      color: "#6d9ecd",
                                      marginLeft: "20px",
                                    }}
                                    onClick={() => handleEye(row?.id)}
                                  />
                                </Tooltip>
                                {row?.flag ? (
                                  <span style={{ marginLeft: "20px" }}>
                                    <Tooltip arrow title="Delete">
                                      <DeleteIcon
                                        className={classes.delete}
                                        onClick={() => handleModal(row?.id)}
                                      />
                                    </Tooltip>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}

                {filteredData &&
                  filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          <TableCell>{index + 1}</TableCell>
                          {columns.map((column, ind) => {
                            const value = row[column.id];
                            if (column.id === "name") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {row?.reviewer?.name}
                                </TableCell>
                              );
                            }
                            if (column.id === "title") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {row?.business?.title}
                                </TableCell>
                              );
                            }

                            if (column.id === "flag") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {!row?.flag ? (
                                    <CheckCircleIcon
                                      sx={{
                                        color: "#259d16de",
                                        fontSize: "20px",
                                      }}
                                    />
                                  ) : (
                                    <FlagIcon sx={{ color: "#DB143C" }} />
                                  )}
                                </TableCell>
                              );
                            }

                            return columns?.length !== ind + 1 ? (
                              <TableCell key={column.id} align={column.align}>
                                {value}
                              </TableCell>
                            ) : (
                              <TableCell
                                align={column.align}
                                key={column.id}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Tooltip arrow title="View business">
                                  <a
                                    href={`${frontUrl}/api/business/${row?.business?.slug}`}
                                    target="_black"
                                    rel="noreferrer"
                                  >
                                    <IosShareIcon sx={{ fontSize: "20px" }} />
                                  </a>
                                </Tooltip>
                                <Tooltip arrow title="View review details">
                                  <RemoveRedEyeIcon
                                    sx={{
                                      cursor: "pointer",
                                      color: "#6d9ecd",
                                      marginLeft: "20px",
                                    }}
                                    onClick={() => handleEye(row?.id)}
                                  />
                                </Tooltip>
                                {row?.flag ? (
                                  <span style={{ marginLeft: "20px" }}>
                                    <Tooltip arrow title="Delete">
                                      <DeleteIcon
                                        className={classes.delete}
                                        onClick={() => handleModal(row?.id)}
                                      />
                                    </Tooltip>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {reviews?.isLoading && skeleton()}
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <CustomModal
          open={open}
          handleClose={handleClose}
          handleDelete={handleDelete}
          deleteStyle={classes.modalDelete}
          loading={reviews?.deleteLoading}
        />

        <div>
          <Modal
            open={openView}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Paper elevation={2}>
              <Box sx={viewModalStyle}>
                <Box className={classes.modalBox}>
                  <CancelIcon
                    className={classes.cancelButton}
                    onClick={() => setOpenView(false)}
                  />

                  <Avatar
                    alt=""
                    src={`${singleReview?.[0]?.reviewer?.profile?.image?.original_url}`}
                    sx={{
                      position: "absolute",
                      left: "40%",
                      top: "-90%",
                      height: "100px",
                      width: "100px",
                      border: "2px solid black",
                    }}
                  />
                </Box>
                <Box className={classes.detailBox}>
                  <Typography sx={{ marginTop: "20px" }}>
                    {[...Array(singleReview?.[0]?.star).keys()].map((el) => (
                      <StarIcon sx={{ color: "#f1c047", fontSize: "40px" }} />
                    ))}
                  </Typography>
                  <Typography sx={{ fontSize: "19px", padding: "10px" }}>
                    {singleReview?.[0]?.description}
                  </Typography>
                </Box>
                <Box className={classes.nameBox}>
                  <Typography sx={{ fontSize: "20px", fontWeight: "500" }}>
                    {singleReview?.[0]?.reviewer?.name}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Modal>
        </div>
      </Paper>
    </>
  );
}

export default ViewReviews;
