import React from "react";
import { useParams } from "react-router-dom";
import PageNotFound from "../../pages/PageNotFound/Index";
import CategorySale from "./CategorySale";
import FeatureSale from "./FeatureSale";
import ListSale from "./ListSale";

const SalePage = () => {
  const { saleId } = useParams();
  return (
    <>
      {saleId === "category" || !saleId ? (
        <CategorySale />
      ) : saleId === "features" ? (
        <FeatureSale />
      ) : saleId === "lists" ? (
        <ListSale />
      ) : (
        <PageNotFound />
      )}
    </>
  );
};

export default SalePage;
