import { returnErrors } from '../redux/Error/Error.action';
import Store from '../redux/store';
import { returnSuccess } from '../redux/Success/Success.action';

export function handleError(error) {
  if (
    error?.response?.data?.status === 'Token is Expired' ||
    error?.response?.data?.status === 'Token is Invalid' ||
    error?.response?.data?.status === 'Authorization Token not found'
  ) {
    localStorage.clear();
    window.location.replace('/');
  }
  Store.dispatch(
    returnErrors(
      error.response?.data || { message: 'Error Occurred', code: '400' }
    )
  );
  throw error;
}

export function handleResponse(response) {
  Store.dispatch(returnSuccess(response.data));
  return response;
}
