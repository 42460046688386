import { axiosInstance } from "../../utils/axiosInstance";
import { handleError, handleResponse } from "../apiResponse";

export function postSiteSettingsApi(data) {
  return axiosInstance()
    .post("/admin/site-setting", data)
    .then(handleResponse)
    .catch(handleError);
}

export function getSiteSettingsApi() {
  return axiosInstance().get("/api/site-setting").catch(handleError);
}

export function postChangePasswordApi(data) {
  return axiosInstance()
    .post("/admin/change-password", data)
    .then(handleResponse)
    .catch(handleError);
}

////////////////////////////////////////////////////////////////////

export function postFallbackImageApi(data) {
  return axiosInstance()
    .post("/admin/fallback-image", data)
    .then(handleResponse)
    .catch(handleError);
}

export function getFallbackImageApi() {
  return axiosInstance().get("/admin/fallback-image").catch(handleError);
}

export function getFallbackImageByKeyApi(key) {
  return axiosInstance().get(`/admin/fallback-image/${key}`).catch(handleError);
}

export function deleteFallbackImageApi(key) {
  return axiosInstance()
    .delete(`/admin/fallback-image/${key}`)
    .then(handleResponse)
    .catch(handleError);
}

///////////////////////////////////////////////////////////////
export function postBannerApi(data) {
  return axiosInstance()
    .post("/admin/banner", data)
    .then(handleResponse)
    .catch(handleError);
}

export function getBannerApi() {
  return axiosInstance().get("/admin/banner").catch(handleError);
}

export function getBannerByKeyApi(key) {
  return axiosInstance().get(`/admin/banner/${key}`).catch(handleError);
}

export function deleteBannerApi(key) {
  return axiosInstance()
    .delete(`/admin/banner/${key}`)
    .then(handleResponse)
    .catch(handleError);
}

export function postLookingForApi(data) {
  return axiosInstance()
    .post("/admin/looking", data)
    .then(handleResponse)
    .catch(handleError);
}

export function updateLookingForApi(data) {
  return axiosInstance()
    .patch(`/admin/looking/${data.id}`, data)
    .then(handleResponse)
    .catch(handleError);
}

export function getLookingForApi() {
  return axiosInstance().get("/admin/looking");
}

export function deleteLookingForApi(id) {
  return axiosInstance().delete(`/admin/looking/${id}`);
}
