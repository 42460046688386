import { get } from 'react-hook-form'
import { toast } from 'react-toastify'
import { deleteContactApi, getContactApi, getNewsLetterApi, getSingleNewsLetterApi } from '../../../api/contact/ContactApi'
import * as actions from './Type'


export const getNewsLetter = () => (dispatch) => {
    dispatch({ type: actions.GET_NEWSLETTER_BEGIN })
    getNewsLetterApi()
        .then((res) => {
            dispatch({
                type: actions.GET_NEWSLETTER_SUCCESS,
                payload: res.data
            })
        })

        .catch((err) => {
            console.log(err, 'err')
            dispatch({
                type: actions.GET_NEWSLETTER_FAIL
            })
        })
}

export const getContact = () => (dispatch) => {
    dispatch({ type: actions.GET_CONTACT_BEGIN })
    getContactApi()
        .then((res) => {
            dispatch({
                type: actions.GET_CONTACT_SUCCESS,
                payload: res.data
            })
        })

        .catch((err) => {
            console.log(err, 'err')
            dispatch({
                type: actions.GET_CONTACT_FAIL
            })
        })
}

export const deleteContact = (data) => (dispatch) => {
    dispatch({ type: actions.DELETE_CONTACT_BEGIN })
    deleteContactApi(data)
        .then((res) => {
            dispatch({
                type: actions.DELETE_CONTACT_SUCCESS,
                payload: res.data
            })
            toast.success(res?.data?.message)
            dispatch(getContact());
        })

        .catch((err) => {
            console.log(err, 'err')
            dispatch({
                type: actions.DELETE_CONTACT_FAIL
            })
        })
}

// export const getSingleNewsLetter = (news_letter) => (dispatch) => {
//     dispatch({ type: actions.GET_SINGLE_NEWSLETTER_BEGIN })
//     getSingleNewsLetterApi(news_letter)
//         .then((res) => {
//             dispatch({
//                 type: actions.GET_SINGLE_NEWSLETTER_SUCCESS,
//                 payload: res.data
//             })
//         })

//         .catch((err) => {
//             console.log(err, 'err')
//             dispatch({
//                 type: actions.GET_SINGLE_NEWSLETTER_FAIL
//             })
//         })
// }