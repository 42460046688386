import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CustomInput } from "../common/customInput/CustomInput";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { makeStyles } from "@mui/styles";

import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  tagGrid: {
    position: "relative",

    "& span": {
      color: "rgba(0, 0, 0, 0.6)",
      fontWeight: "400",
      fontSize: "15px",
      lineHeight: "1em",
    },

    "& .tags": {
      position: "absolute",
      top: "10px",
      width: "100%",
      paddingRight: "10px",
    },
    "& .tagWrap": {
      width: "100%",
      overFlow: "scroll !important",
    },

    "& ul.tagLists": {
      marginTop: "7px",
      display: "flex",

      flexWrap: "wrap",
      listStyleType: "none",
      padding: "10px 0",

      paddingLeft: "8px",
      width: "100%",

      height: "120px",
      overflowY: "scroll",
      background: "#F4F9FF",
      border: "1px solid #e4e4e4",
      borderRadius: "4px",
    },

    "& ul.tagLists li": {
      display: "flex",
      alignItems: "center",
      padding: "0 7px",
      background: "#f8f8f8",
      border: "1px solid #e4e4e4",
      borderRadius: "50px",
      marginRight: "5px",
      marginBottom: "10px",
      height: "fit-content",
    },

    "& .MuiSvgIcon-root": {
      color: "#f87474",
      marginLeft: "5px",
      transition: "ease all 0.5s",

      "& :hover": {
        color: "red",
        transition: "ease all 0.5s",
      },
    },
  },
}));

const Tags = ({ addTags, metaData }) => {
  const classes = useStyles();
  const [tags, setTags] = useState([]);

  const onSubmit = (val, e) => {
    if (e.keyCode === 13) {
      reset();
      val.meta_keyword.trim() && setTags([val.meta_keyword.trim(), ...tags]);
    }
  };

  useEffect(() => {
    addTags(tags);
  }, [tags]);

  const defaultValues = {
    meta_keyword: [],
  };

  const deleteTags = (id) => {
    const updatedTags = tags.filter((item, index) => {
      return index !== id;
    });
    setTags(updatedTags);
  };

  const { handleSubmit, control, reset, setValue } = useForm({ defaultValues });

  useEffect(() => {
    if (metaData) {
      if (Array.isArray(metaData)) {
        return setTags(metaData);
      }

      const metaDataArr = metaData.split(",");
      setTags([...metaDataArr]);
    }
  }, [metaData]);

  return (
    <>
      <div className={classes.tagGrid}>
        <span>Meta Keyword</span>
        <form className="tags" onKeyUp={handleSubmit(onSubmit)}>
          <CustomInput control={control} name="meta_keyword" />

          <div className="tagWrap">
            <ul className="tagLists">
              {tags.map((item, index) => (
                <li key={index}>
                  {" "}
                  {item}
                  <BackspaceIcon
                    onClick={() => deleteTags(index)}
                    sx={{ color: "red", fontSize: "16px !important" }}
                  />
                </li>
              ))}
            </ul>
          </div>
        </form>
      </div>
    </>
  );
};

export default Tags;
