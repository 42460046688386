import { Box, Button, CircularProgress, Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { CustomInput } from "../common/customInput/CustomInput";
import CustomTextarea from "../common/customTextarea/CustomTextarea";
import UploadIcon from "@mui/icons-material/Upload";
import { Breadcrumbs, Typography } from "@mui/material";
import InsideHeader from "../../layout/InsideHeader";
import { CustomHiddenInput } from "../common/customInput/CustomHiddenInput";
import { useDispatch, useSelector } from "react-redux";
import { postBlog, putBlogUpdate } from "./redux/Action";
import { serializer } from "../../utils/helper";
import { Link } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Tags from "../rental/Tags";
import * as actions from "./redux/Type";
import { QuillTextEditor } from "../common/customTextarea/QuillTextEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    // paddingRight: '16px',

    "& .uploadAndEdit": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },

  descriptionField: {
    "& .MuiOutlinedInput-root": {
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
    },
  },

  delete: {
    backgroundColor: "#ff6363 !important",
    color: "white",
    cursor: "pointer",
    marginLeft: "10px",
    fontSize: "14px !important",
    "&:hover": {
      backgroundColor: "#DB143C !important",
    },
  },
}));

function BlogPost({ setToggle, toggle, buttonName, setButtonName }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const [metaData, setMetaData] = useState([])

  const updateBlog = useSelector((state) => state.blogs);

  const defaultValues = {
    title: "",
    description: "",
    image: "",
    meta_title: "",
    meta_description: "",
    meta_key: [],
  };

  const schema = yup
    .object({
      title: yup.string().required("Title is required"),
      description: yup.string().required("Description is required"),
      image: yup.mixed().required("Image is required"),
      meta_title: yup.string().required("Meta title is required"),
      meta_description: yup.string().required("Meta description is required"),
    })
    .required();

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const upload = useRef(null);
  const picture = watch("image");

  useEffect(() => {
    setValue("title", updateBlog?.updateBlog?.data?.title);
    setValue("description", updateBlog?.updateBlog?.data?.description);
    setValue("meta_title", updateBlog?.updateBlog?.data?.meta_title);
    setValue(
      "meta_description",
      updateBlog?.updateBlog?.data?.meta_description,
    );
    setValue("meta_key", updateBlog?.updateBlog?.data?.meta_key);
  }, [updateBlog?.updateBlog?.data, setValue]);

  const uploadImage = () => {
    upload.current.click();
  };

  const [tags, setTags] = useState([]);

  const addTags = (data) => {
    setValue("meta_key", data);
  };

  const handleButton = () => {
    dispatch({ type: actions.CLEAN_UPDATE_BLOG });
    setButtonName("Post");
    setToggle((prev) => !prev);
    reset();
  };

  const handleError = (errors) => {
    console.log(errors, "errors");
    Object.entries(errors)?.map((item) => {
      console.log("error", item);
      setError(item[0], { message: item[1] });
    });
  };

  const handleCancelButton = () => {
    setToggle((prev) => !prev);
    dispatch({ type: actions.CLEAN_UPDATE_BLOG });
  };

  const onSubmit = (values, e) => {
    if (e.keyCode === 32) {
      values.meta_key.trim() &&
        setTags([...tags, { meta_key: values.meta_key.trim() }]);
    }
    const formData = new FormData();
    if (updateBlog?.updateBlog?.data?.slug) {
      serializer(formData, { ...values, _method: "patch" });
      dispatch(
        putBlogUpdate(
          updateBlog?.updateBlog?.data?.slug,
          formData,
          setToggle,
          reset,
          handleError,
        ),
      );
    } else {
      serializer(formData, values);
      dispatch(postBlog(formData, setToggle, reset, handleError));
    }
  };

  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          background: "#f8f8f8",
          marginBottom: "15px",
        }}
      >
        <InsideHeader title="Blogs">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Blogs
            </Link>
            <Typography color="textPrimary">Posts</Typography>
          </Breadcrumbs>
        </InsideHeader>
        <Button
          sx={{ height: "40px" }}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleButton}
        >
          Add Blogs
        </Button>
      </Paper>
      {toggle && (
        <Paper
          elevation={1}
          className={classes.paper}
          sx={{ padding: "25px", marginBottom: "30px" }}
        >
          <div className={classes.root}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => checkKeyDown(e)}
            >
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <CustomInput
                    label="Blog-Title"
                    control={control}
                    errors={errors}
                    name="title"
                  />
                  <Typography
                    sx={{ color: "red !important", fontSize: "12px" }}
                  >
                    {errors?.title?.message}
                  </Typography>
                </Grid>

                <Grid
                  item
                  sm={8}
                  sx={{ marginTop: "20px" }}
                  className={classes.descriptionField}
                >
                  <Typography
                    sx={{
                      color: "rgba(0, 0, 0, 0.6)",
                      fontWeight: "400",
                      fontSize: "15px",
                      paddingLeft: "8px",
                    }}
                  >
                    Description
                  </Typography>

                  <QuillTextEditor
                    title=""
                    control={control}
                    errors={errors}
                    name="description"
                    setValue={setValue}
                    initialText={updateBlog?.updateBlog?.data?.description}
                  />
                  <Typography
                    sx={{ color: "red !important", fontSize: "12px" }}
                  >
                    {errors?.description?.message}
                  </Typography>
                </Grid>

                <Grid item sm={4}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingTop: "23px",
                    }}
                  >
                    <div className="uploadAndEdit">
                      <label style={{ color: "#828282" }}>Image</label>
                    </div>
                    <div
                      onClick={uploadImage}
                      style={{
                        height: "195px",
                        width: "100%",
                        background: "#f4f9ff",
                        border: "1px solid #e4e4e4",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        style={{ position: "absolute", top: "0", left: "0" }}
                        src={
                          !picture
                            ? updateBlog?.updateBlog?.data?.image?.original_url
                            : URL?.createObjectURL(picture)
                        }
                        width="100%"
                        height="100%"
                        alt=""
                      />

                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={uploadImage}
                      >
                        <UploadIcon />
                        Upload
                      </span>
                    </div>
                    <CustomHiddenInput
                      style={{
                        display: "none",
                      }}
                      className="uploadImage"
                      type="file"
                      accept="image/*"
                      control={control}
                      name="image"
                      ref={upload}
                    />
                    <Typography
                      sx={{ color: "red !important", fontSize: "12px" }}
                    >
                      {errors?.image?.message}
                    </Typography>
                  </div>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  sx={{
                    paddingTop: "15px !important",
                    paddingLeft: "0px !important",
                    marginTop: "40px",
                    marginLeft: "16px",
                    borderTop: "1px solid #cfddea",
                  }}
                >
                  <Grid item sm={12} sx={{ paddingLeft: "0px !important" }}>
                    <Typography
                      variant="h6"
                      noWrap
                      component="div"
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                        paddingBottom: "15px",
                        fontSize: "25px",
                      }}
                    >
                      SEO
                    </Typography>
                  </Grid>

                  <Grid item sm={6} sx={{ paddingLeft: "0px !important" }}>
                    <CustomInput
                      label="Meta title"
                      control={control}
                      errors={errors}
                      name="meta_title"
                    />
                    <Typography
                      sx={{ color: "red !important", fontSize: "12px" }}
                    >
                      {errors?.meta_title?.message}
                    </Typography>
                  </Grid>

                  <Grid item sm={6} sx={{ paddingTop: "6px !important" }}>
                    <Tags
                      meta_key="meta_key"
                      addTags={addTags}
                      errors={errors?.tag?.message}
                      metaData={updateBlog?.updateBlog?.data?.meta_key}
                    />
                  </Grid>

                  <Grid
                    item
                    sm={6}
                    sx={{ paddingLeft: "0px !important" }}
                    className={classes.descriptionField}
                  >
                    <CustomTextarea
                      label="Meta Description"
                      control={control}
                      errors={errors}
                      name="meta_description"
                    />
                    <Typography
                      sx={{ color: "red !important", fontSize: "12px" }}
                    >
                      {errors?.meta_description?.message}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item sm={2}>
                  {buttonName === "Post" ? (
                    <Box
                      marginTop={2.5}
                      marginLeft={0}
                      sx={{
                        margiLeft: "0px",
                        marginTop: "0px",
                        display: "flex",
                      }}
                    >
                      <Button
                        sx={{ marginRight: "10px", fontSize: "14px" }}
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                      >
                        {!updateBlog?.isLoading ? (
                          `${buttonName}`
                        ) : (
                          <CircularProgress size={30} sx={{ color: "white" }} />
                        )}
                      </Button>
                      <Button
                        className={classes.delete}
                        variant="contained"
                        fullWidth
                        size="large"
                        onClick={() => setToggle((prev) => !prev)}
                      >
                        Cancel
                      </Button>
                    </Box>
                  ) : (
                    <Box
                      marginTop={2.5}
                      marginLeft={0}
                      sx={{
                        margiLeft: "0px",
                        marginTop: "0px",
                        display: "flex",
                      }}
                    >
                      <Button
                        sx={{ marginRight: "10px", fontSize: "14px" }}
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                      >
                        {!updateBlog?.updateLoading ? (
                          `${buttonName}`
                        ) : (
                          <CircularProgress size={30} sx={{ color: "white" }} />
                        )}
                      </Button>
                      <Button
                        className={classes.delete}
                        variant="contained"
                        fullWidth
                        size="large"
                        onClick={handleCancelButton}
                      >
                        Cancel
                      </Button>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </form>
          </div>
        </Paper>
      )}
    </>
  );
}

export default BlogPost;
