import React from "react";
import { Icon, Switch, TableCell, TableRow, Tooltip } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { postRentalStatus } from "./redux/Action";
import { Box } from "@mui/system";
import CustomModal from "../common/customModal/CustomModal";
import { useState } from "react";
import { deleteRentalList } from "./redux/Action";

const CustomTableRow = ({ index, columns, row, page, rowsPerPage }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { deleteListLoading } = useSelector((state) => state.rentals);

  const handleClose = () => {
    setOpen((prev) => !prev);
  };

  const handleDelete = () => {
    dispatch(deleteRentalList(row?.slug, handleClose));
  };
  const getSwitchValue = (e, value, slug) => {
    dispatch(
      postRentalStatus(slug, {
        _method: "patch",
        value: value === true ? 1 : 0,
      })
    );
  };

  const frontUrl = process.env.REACT_APP_FRONT_URL;

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
      <TableCell>{page * rowsPerPage + index + 1}</TableCell>
      {columns.map((column, ind) => {
        if (column.id === "category")
          return (
            <TableCell key={column.id} align={column.align}>
              {row[column.id].title}
            </TableCell>
          );
        const value = row[column.id];
        return columns?.length !== ind + 1 && column.id !== "status" ? (
          <TableCell key={column.id} align={column.align}>
            {value}
          </TableCell>
        ) : column.id === "status" ? (
          <TableCell align={column.align} key={column.id}>
            {row?.status?.title}
          </TableCell>
        ) : (
          <TableCell
            key={column.id}
            align={column.align}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Tooltip arrow title={`${row?.status?.title}`}>
              <Switch
                defaultChecked={row?.status.title === "active" ? 1 : 0}
                inputProps={{ "aria-label": "ant design" }}
                onChange={(e, value) => getSwitchValue(e, value, row?.slug)}
              />
            </Tooltip>

            <a
              href={`${frontUrl}/rental/${row?.slug}`}
              target="_black"
              rel="noreferrer"
            >
              <Tooltip arrow title="View details">
                <RemoveRedEyeIcon
                  sx={{
                    cursor: "pointer",
                    color: "#6d9ecd",
                    marginLeft: "20px",
                    marginTop: "8px",
                  }}
                />
              </Tooltip>
            </a>
            <Tooltip arrow title="Delete">
              <Icon
                style={{
                  color: "#f7675d",
                  cursor: "pointer",
                  marginLeft: "15px",
                }}
                color="red"
              >
                <DeleteIcon onClick={handleClose} />
              </Icon>
            </Tooltip>
          </TableCell>
        );
      })}
      <CustomModal
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        loading={deleteListLoading}
      />
    </TableRow>
  );
};

export default CustomTableRow;
