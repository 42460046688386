import { returnErrors } from "../../redux/Error/Error.action";
import { axiosInstance } from "../../utils/axiosInstance";
import { handleError, handleResponse } from "../apiResponse";

export function postRentalApi(data) {
  return axiosInstance()
    .post("/admin/house-category", data)
    .then(handleResponse);
  // .catch(handleError);
}

export function getupdateRentalApi(slug) {
  return axiosInstance()
    .get(`/admin/house-category/${slug}`)
    .catch(handleError);
}

export function putupdateRentalApi(slug, data) {
  return axiosInstance()
    .post(`/admin/house-category/${slug}`, data)
    .then(handleResponse)
    .catch(handleError);
}

export function deleteRentalApi(house_category_id) {
  return axiosInstance()
    .delete(`/admin/house-category/${house_category_id}`)
    .then(handleResponse)
    .catch(handleError);
}

export function getRentalApi(page, rowsPerPage) {
  return axiosInstance()
    .get("/admin/house-category", {
      params: {
        page: page + 1,
        limit: rowsPerPage,
      },
    })
    .catch(handleError);
}

export function postRentalFeatureApi(data) {
  return axiosInstance()
    .post("/admin/house-feature", data)
    .then(handleResponse)
    .catch(handleError);
}

export function getRentalFeatureApi() {
  return axiosInstance().get("/admin/house-feature").catch(handleError);
}

export function getupdateRentalFeatureApi(id) {
  return axiosInstance().get(`/admin/house-feature/${id}`).catch(handleError);
}

export function putupdateRentalFeatureApi(id, data) {
  return axiosInstance()
    .post(`/admin/house-feature/${id}`, data)
    .then(handleResponse)
    .catch(handleError);
}

export function deleteRentalFeatureApi(id) {
  return axiosInstance()
    .delete(`/admin/house-feature/${id}`)
    .then(handleResponse)
    .catch(handleError);
}

export function postRentalAmenitiesApi(data) {
  return axiosInstance()
    .post("/admin/house-amenity", data)
    .then(handleResponse)
    .catch(handleError);
}

export function getRentalAmenitiesApi() {
  return axiosInstance().get("/admin/house-amenity").catch(handleError);
}

export function getupdateRentalAmenityApi(id) {
  return axiosInstance().get(`/admin/house-amenity/${id}`).catch(handleError);
}

export function putupdateRentalAmenitiyApi(id, data) {
  return axiosInstance()
    .post(`/admin/house-amenity/${id}`, data)
    .then(handleResponse)
    .catch(handleError);
}

export function deleteRentalAmenityApi(id) {
  return axiosInstance()
    .delete(`/admin/house-amenity/${id}`)
    .then(handleResponse)
    .catch(handleError);
}

//For all Rental lists

export function getAllRentalApi(page, rows) {
  const limit = rows ? `&limit=${rows}` : "";
  const active = rows ? `?page=${page + 1}` : "";
  return axiosInstance()
    .get(`/admin/house${active}${limit}`)
    .catch(handleError);
}

export function postRentalStatusApi(slug, data) {
  return axiosInstance()
    .post(`/admin/house/${slug}/change-active`, data)
    .then(handleResponse)
    .catch(handleError);
}

export function deleteRentalListApi(house_category_id) {
  return axiosInstance()
    .delete(`/admin/house/${house_category_id}`)
    .then(handleResponse)
    .catch(handleError);
}
