import { axiosInstance } from "../../utils/axiosInstance";
import { handleError, handleResponse } from "../apiResponse";

export function getNewsLetterApi() {
    return axiosInstance()
        .get('/admin/newsletter')
        .catch(handleError)
}

export function getContactApi() {
    return axiosInstance()
        .get('/admin/contact-message')
        .catch(handleError)
}

export function deleteContactApi(id){
    return axiosInstance()
    .delete(`/admin/contact-message/${id}`)
}


export function getSingleNewsLetterApi(news_letter) {
    return axiosInstance()
        .get(`/admin/newsletter/${news_letter}`)
        .catch(handleError)
}