import React from "react";
import { useParams } from "react-router-dom";
import BannerUpload from "./BannerUpload";
import SiteSetting from "./SiteSetting";
import ChangePassword from "./ChangePassword";
import FallbackImage from "./FallbackImage";
import PageNotFound from "../../pages/PageNotFound/Index";
import Reward from "./Reward";
import LookingFor from "./LookingFor";

const SettingPage = () => {
  const { settingId } = useParams();
  return (
    <>
      {settingId === "siteSettings" || !settingId ? (
        <SiteSetting />
      ) : settingId === "banners" ? (
        <BannerUpload />
      ) : settingId === "password" ? (
        <ChangePassword />
      ) : settingId === "reward" ? (
        <Reward />
      ) : settingId === "lookingFor" ? (
        <LookingFor />
      ) : settingId === "fallbackImage" ? (
        <FallbackImage />
      ) : (
        <PageNotFound />
      )}
    </>
  );
};

export default SettingPage;
