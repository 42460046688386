export const groupPermission = {
  Dashboard: [
    {
      id: 1,
      name: "dashboard",
    },
  ],
  Users: [
    {
      id: 2,
      name: "user",
    },
    {
      id: 3,
      name: "create-admin",
    },
    {
      id: 22,
      name: "user-review",
    },
  ],
  Rental: [
    {
      id: 4,
      name: "rental-category",
    },
    {
      id: 5,
      name: "rental-feature",
    },
    {
      id: 6,
      name: "rental-amenity",
    },
    {
      id: 7,
      name: "rental",
    },
    {
      id: 34,
      name: "rental-list",
    },
  ],
  Role: [
    {
      id: 23,
      name: "permission",
    },
  ],
  Job: [
    {
      id: 8,
      name: "job-category",
    },
    {
      id: 9,
      name: "job",
    },
    {
      id: 26,
      name: "job-list",
    },
  ],
  Business: [
    {
      id: 10,
      name: "business-category",
    },
    {
      id: 11,
      name: "business",
    },
    {
      id: 25,
      name: "business-list",
    },
  ],
  Blogs: [
    {
      id: 12,
      name: "blog",
    },
  ],
  "Quick Posts": [
    {
      id: 13,
      name: "quick-post",
    },
  ],
  Settings: [
    {
      id: 14,
      name: "site-setting",
    },
    {
      id: 15,
      name: "banner",
    },
    {
      id: 16,
      name: "change-password",
    },
    {
      id: 17,
      name: "change-admin-password",
    },
    {
      id: 18,
      name: "fallback-image",
    },
    {
      id: 24,
      name: "country",
    },
    {
      id: 35,
      name: "reward",
    },
  ],
  "Ads Management": [
    {
      id: 19,
      name: "ads-management",
    },
    {
      id: 27,
      name: "ad-home-page",
    },
    {
      id: 28,
      name: "ad-job-page",
    },
    {
      id: 29,
      name: "ad-rental-page",
    },
    {
      id: 30,
      name: "ad-business-page",
    },
    {
      id: 31,
      name: "ad-promoted-content",
    },
    {
      id: 32,
      name: "ad-blog-page",
    },
    {
      id: 33,
      name: "ad-quick-post-page",
    },
    {
      id: 36,
      name: "promoted-post",
    },
  ],
  Contact: [
    {
      id: 20,
      name: "contact-us",
    },
    {
      id: 21,
      name: "newsletter",
    },
  ],
};
