export const GET_USERS_BEGIN = "GET_USERS_BEGIN";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

export const POST_USER_STATUS_BEGIN = "POST_USER_STATUS_BEGIN";
export const POST_USER_STATUS_SUCCESS = "POST_USER_STATUS_SUCCESS";
export const POST_USER_STATUS_FAIL = "POST_USER_STATUS_FAIL";

export const GET_USER_CLIENT_LIST_BEGIN = "GET_USER_CLIENT_LIST_BEGIN";
export const GET_USER_CLIENT_LIST_SUCCESS = "GET_USER_CLIENT_LIST_SUCCESS";
export const GET_USER_CLIENT_LIST_FAIL = "GET_USER_CLIENT_LIST_FAIL";
