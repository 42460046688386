import * as actions from './Type'

const initialState = {
    isLoading: false,
    dashboardData: []
};

export const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_DASHBOARD_DATA_BEGIN:
            return {
                ...state,
                isLoading: true
            }

        case actions.GET_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                dashboardData: action.payload
            }

        case actions.GET_DASHBOARD_DATA_FAIL:
            return {
                ...state,
                isLoading: false
            }

        default:
            return state
    }
}