import React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Login from "../../components/auth/login/Login";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function Copyright() {
  return (
    <Box
      variant="body2"
      color="textSecondary"
      align="center"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {"Copyright ©  "}
      {new Date().getFullYear()}{" "}
      <a color="inherit" href="https://scodus.com">
        Scodus
      </a>
      {"."}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "calc(50vh - 60%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "0px 2px 6px #c5c5c5",
    borderRadius: "6px",
    padding: "45px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  title: {
    color: "#2b92d7",
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "18px",
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const auth = useSelector((state) => state.auth);

  if (auth.token) {
    return <Navigate to="/" replace />;
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" className={classes.title}>
          Phase One
        </Typography>
        <Login />
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
