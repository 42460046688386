import React from "react";

function ReviewStar({ rating = 0, small = false }) {
  const checkSize = () => {
    return small === true
      ? "small-wh"
      : small === "medium"
      ? "medium-wh"
      : "wh";
  };
  return (
    <div className="rating">
      {[...Array(parseInt(rating))?.keys()]?.map((index) => (
        <img key={index} src="/images/star.svg" width="15px" height="15px" />
      ))}
      {[...Array(5 - parseInt(rating))?.keys()]?.map((index) => (
        <img
          key={index}
          src="/images/starGrey.svg"
          width="15px"
          height="15px"
        />
      ))}
    </div>
  );
}

export default ReviewStar;
