import axios from 'axios';
import TokenService from './TokenService';

// for multiple requests
let isRefreshing = false;
let failedQueue = [];
const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

export const axiosInstance = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const instance = axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  instance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token; // for Spring Boot back-end
        // config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== '/admin/user/login' && err.response) {
        // Access Token was expired
        if (
          !originalConfig._retry &&
          err.response.data?.message === 'Invalid token.'
        ) {
          if (isRefreshing) {
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject });
            })
              .then((token) => {
                originalConfig.headers['Authorization'] = 'Bearer ' + token;
                return instance.request(originalConfig);
              })
              .catch((err) => {
                return Promise.reject(err);
              });
          }
          originalConfig._retry = true;
          isRefreshing = true;

          try {
            const rs = await instance.post('/admin/user/login/refresh', {
              refresh_token: TokenService.getLocalRefreshToken(),
            });
            const { accessToken, refreshToken } = rs.data.data;
            TokenService.updateLocalAccessToken(accessToken);
            TokenService.updateLocalRefreshToken(refreshToken);
            processQueue(null, accessToken);
            isRefreshing = false;
            return instance(originalConfig);
          } catch (_error) {
            processQueue(err, null);
            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(err);
    }
  );
  return instance;
};
