import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Skeleton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import CustomModal from "../common/customModal/CustomModal";
import {
  deleteSaleFeature,
  getSaleFeature,
  getSingleSaleFeature,
} from "./redux/Action";

const columns = [
  {
    id: "title",
    label: "Title",
    minWidth: 200,
  },

  {
    id: "slug",
    label: "Slug ",
    minWidth: 200,
  },
  {
    id: "action",
    label: "Action ",
    minWidth: 30,
    align: "center",
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  p: 3,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    "& .MuiTableCell-stickyHeader": {
      background: theme.palette.background.dark,
      //   color: theme.palette.primary.dark,
    },
    "& .MuiTableBody-root": {
      "& tr:nth-of-type(odd)": {
        background: theme.palette.background.main,
      },
    },
  },
  delete: {
    color: "#ff6363",
    cursor: "pointer",
    "&:hover": {
      color: "red",
    },
  },

  modalDelete: {
    color: "white",
    cursor: "pointer",
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: "#DB143C !important",
    },
  },
  edit: {
    color: "#15c388",
    cursor: "pointer",
    "&:hover": {
      color: "green",
    },
  },
  container: {
    maxHeight: 550,
    borderRadius: "5px",
  },
}));

function ViewRentalFeature({ setToggle, setButtonName }) {
  const dispatch = useDispatch();

  const { saleFeatures, featureLoading } = useSelector((state) => state.sale);

  const classes = useStyles();
  const rows = saleFeatures || [];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [id, setId] = useState();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleModal = (id) => {
    setId(id);
    handleOpen();
  };

  const handleDelete = () => {
    id && dispatch(deleteSaleFeature(id, handleClose));
  };

  const handleEdit = (id) => {
    setButtonName("Update");
    setToggle(true);
    dispatch(getSingleSaleFeature(id));
  };

  useEffect(() => {
    dispatch(getSaleFeature());
  }, []);

  const skeleton = () => (
    <>
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
    </>
  );

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>S.No.</TableCell>
              {columns.map((column) => (
                <TableCell
                  sx={{ fontWeight: "bold" }}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {!featureLoading && (
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      <TableCell>{index + 1}</TableCell>
                      {columns.map((column, ind) => {
                        const value = row[column.id];
                        return columns?.length !== ind + 1 ? (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        ) : (
                          <TableCell
                            align={column.align}
                            key={column.id}
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <Tooltip title="Edit" arrow>
                              <span>
                                <Link to={`features/${row?.id}`}>
                                  <EditIcon
                                    className={classes.edit}
                                    onClick={() => handleEdit(row?.id)}
                                  />
                                </Link>
                              </span>
                            </Tooltip>

                            <Tooltip title="Delete" arrow>
                              <span>
                                <DeleteIcon
                                  className={classes.delete}
                                  onClick={() => handleModal(row?.id)}
                                />
                              </span>
                            </Tooltip>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {featureLoading && skeleton()}

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <CustomModal
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        deleteStyle={classes.modalDelete}
        loading={featureLoading}
      />
    </Paper>
  );
}

export default ViewRentalFeature;
