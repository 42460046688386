import React from 'react'
import { useParams } from 'react-router-dom'
import NewsLetter from './NewsLettter';
import ContactUs from './ContactUs';
import PageNotFound from '../../pages/PageNotFound/Index';

const ContactPage = () => {
    const { contactId } = useParams()
    return (
        <>
            {
                contactId === 'newsLetter' || !contactId ?
                    <NewsLetter />
                    : contactId === 'contactUs' || !contactId ?
                        <ContactUs />
                        :
                        <PageNotFound />
            }
        </>
    )
}

export default ContactPage