import { returnErrors } from "../../redux/Error/Error.action";
import { axiosInstance } from "../../utils/axiosInstance";
import { handleError, handleResponse } from "../apiResponse";

export function postJobApi(data) {
  return axiosInstance().post("/admin/job-category", data).then(handleResponse);
  // .catch(handleError);
}

export function getJobApi(page, rowsPerPage) {
  return axiosInstance()
    .get("/admin/job-category", {
      params: { page: page + 1, limit: rowsPerPage },
    })
    .catch(handleError);
}

export function getUpdateJobApi(slug) {
  return axiosInstance().get(`/admin/job-category/${slug}`).catch(handleError);
}

export function putUpdateJobApi(slug, data) {
  return axiosInstance()
    .post(`/admin/job-category/${slug}`, data)
    .then(handleResponse)
    .catch(handleError);
}

export function deleteJobApi(slug) {
  return axiosInstance()
    .delete(`/admin/job-category/${slug}`)
    .then(handleResponse)
    .catch(handleError);
}

export function getAllJobApi(page, rows) {
  const limit = rows ? `&limit=${rows}` : "";
  const active = rows ? `?page=${page + 1}` : "";
  return axiosInstance().get(`/admin/job${active}${limit}`).catch(handleError);
}

export function postJobStatusApi(slug, data) {
  return axiosInstance()
    .post(`/admin/job/${slug}/change-active`, data)
    .then(handleResponse)
    .catch(handleError);
}

export function deleteJobListApi(slug) {
  return axiosInstance()
    .delete(`/admin/job/${slug}`)
    .then(handleResponse)
    .catch(handleError);
}
