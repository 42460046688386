import { clearSuccess } from "../Success/Success.action";
import * as actions from "./Error.type";

export const returnErrors = (data) => (dispatch) => {
  dispatch(clearErrors());
  dispatch(clearSuccess());

  dispatch({
    type: actions.GET_ERRORS,
    payload: data,
  });
};

export const clearErrors = () => {
  return {
    type: actions.CLEAR_ERRORS,
  };
};
