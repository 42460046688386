import { Breadcrumbs, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import InsideHeader from '../../layout/InsideHeader'
import { getAllRental } from './redux/Action'

const SingleRental = () => {

    const { id } = useParams()
    const dispatch = useDispatch()
    const allRental = useSelector((state) => state.rentals)


    const singleRental = allRental?.allRental?.filter((item) => {
        return (
            item.slug === id
        )
    })

    useEffect(() => {
        dispatch(getAllRental())
    }, [])

    return (
        <div>
            <InsideHeader title={`${singleRental[0]?.title}`}>
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                    <Link color="inherit" to="/">
                        Client Posted Rental
                    </Link>
                    <Typography color="textPrimary">Details</Typography>
                </Breadcrumbs>
            </InsideHeader>
            {singleRental &&
                <Card sx={{ width: '100%' }}>
                    <CardMedia
                        component="img"
                        height="300px"
                        image={singleRental[0]?.feature_image}
                        alt=""
                    />

                    {singleRental[0]?.gallery &&
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {singleRental[0]?.gallery?.map((item, index) =>
                            (
                                <CardMedia
                                    sx={{ width: '200px', height: '200px' }}
                                    key={index}
                                    component="img"
                                    image={item.original_url}
                                    alt=""
                                />
                            )
                            )}
                        </div>}
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {singleRental[0]?.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {singleRental[0]?.description ? singleRental[0]?.category?.description : null}
                        </Typography>
                    </CardContent>
                    <CardActions>
                    </CardActions>
                </Card>

                /* <h1>-----------------------------------------------</h1>
                <h1>{singleRental[0]?.address}</h1>
                <Typography>Amenities Data</Typography>
                <p>{singleRental[0]?.amenity[0].created_at}</p>
                <p>{singleRental[0]?.amenity[0]?.title}</p>
                <p>{singleRental[0]?.amenity[0]?.slug}</p>
                <img src={singleRental[0]?.amenity[0]?.icon?.original_url} alt="" />
                <p>{singleRental[0]?.available_from}</p>
                <h1>{singleRental[0]?.category?.description}</h1>
                <p>{singleRental[0]?.category?.created_at}</p>
                <p>{singleRental[0]?.category?.status?.title}</p>
                <img src={singleRental[0]?.category?.icon} alt="" />
                <img src={singleRental[0]?.feature_image} alt="" />

                {

            singleRental[0]?.gallery?.map((item, index) =>
            (
                <img key={index} src={item.original_url} alt="" srcset="" />
            )
            ) */

            }
        </div>
    )
}

export default SingleRental