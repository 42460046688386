import { axiosInstance } from "../../utils/axiosInstance";
import { handleError, handleResponse } from "../apiResponse";

export function postBlogApi(data) {
    return axiosInstance()
        .post('/admin/blog', data)
        .then(handleResponse)
        .catch(handleError)
}

export function getBlogApi() {
    return axiosInstance()
        .get(`/admin/blog`)
        .catch(handleError)
}

export function getUpdateBlogApi(slug) {
    return axiosInstance()
        .get(`/admin/blog/${slug}`)
        .catch(handleError)
}

export function putUpdateBlogApi(slug, data) {
    return axiosInstance()
        .post(`/admin/blog/${slug}`, data)
        .then(handleResponse)
        .catch(handleError)
}

export function deleteBlogApi(id) {
    return axiosInstance()
        .delete(`/admin/blog/${id}`)
        .then(handleResponse)
        .catch(handleError)
}

export function postBlogStatusApi(slug, data) {
    return axiosInstance()
        .post(`/admin/blog/${slug}/change-active`, data)
        .then(handleResponse)
        .catch(handleError)
}

