import React from "react";
import ListItems from "./listItems";
import Drawer from "@mui/material/Drawer";
import { Box, Divider, Toolbar } from "@mui/material";

const drawerWidth = 240;

export default function Sidebar() {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
          background: "#1281d9",

          "& .MuiBox-root::-webkit-scrollbar": {
            display: "none",
          },
        },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <ListItems />
        <Divider />
      </Box>
    </Drawer>
  );
}
