import React, { useRef, useEffect, useState } from "react";
import "quill/dist/quill.snow.css";
import Quill from "quill";
import { Controller } from "react-hook-form";
import { Box, InputLabel } from "@mui/material";
import { useMemo } from "react";

export const QuillTextEditor = ({
  name,
  errors,
  control,
  setValue,
  placeholder,
  title,
  textToInsert,
  setTextToInsert,
  initialText,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={() => {
        return (
          <TextEditor
            name={name}
            setValue={setValue}
            errors={errors}
            placeholder={placeholder}
            title={title}
            textToInsert={textToInsert}
            setTextToInsert={setTextToInsert}
            initialText={initialText}
          />
        );
      }}
    />
  );
};

const TextEditor = ({
  name,
  setValue,
  errors,
  placeholder,
  title,
  textToInsert,
  setTextToInsert,
  initialText,
}) => {
  const editorRef = useRef(null);
  const quill = useRef(null);
  const [cursorIndex, setCursorIndex] = useState(0);

  // remove duplicated toolbar when component remounts
  const removeDuplicatedToolbar = (parentElement) => {
    const toolBars = parentElement.querySelectorAll(".ql-toolbar");
    toolBars.forEach((toolBar, index) => {
      if (toolBars.length !== index + 1) {
        toolBar.remove();
      }
    });
  };

  const editorOptions = useMemo(() => {
    return {
      theme: "snow",
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],
          [{ color: [] }, { background: [] }],
          ["clean"],
          ["link", "image"],
          // ["link"],
        ],
      },
      placeholder: placeholder || "",
    };
  }, [placeholder]);

  useEffect(() => {
    if (editorRef.current) {
      quill.current = new Quill(editorRef.current, editorOptions);
    }
  }, [editorOptions]);

  useEffect(() => {
    if (quill.current && initialText) {
      quill.current.clipboard.dangerouslyPasteHTML(initialText);
    }
  }, [initialText]);

  useEffect(() => {
    if (editorRef.current) {
      const getCursorPosition = () => {
        if (quill.current.getSelection()) {
          setCursorIndex(quill.current.getSelection().index);
        }
      };

      const parentElement = document.querySelector("#text-editor-container");
      removeDuplicatedToolbar(parentElement);

      if (textToInsert) {
        quill.current.insertText(cursorIndex, " " + textToInsert);
        // quill.current.clipboard.dangerouslyPasteHTML(cursorIndex, textValue);
        setTextToInsert("");
      }

      parentElement.addEventListener("click", getCursorPosition);

      quill.current.on("text-change", () => {
        setValue(name, quill.current.root.innerHTML);
        getCursorPosition();
      });

      return () =>
        parentElement.removeEventListener("click", getCursorPosition);
    }
  }, [textToInsert]);

  return (
    <Box id="text-editor-container">
      {title && (
        <Box sx={{ display: "flex", columnGap: "0.1rem", mb: "5px" }}>
          <InputLabel className="title">{title} </InputLabel>
        </Box>
      )}
      <Box
        className="text-editor"
        ref={editorRef}
        style={{ height: "150px" }}
      />
      {errors?.message && (
        <Box sx={{ color: "red", fontSize: "10px", marginTop: "5px" }}>
          {errors?.message}
        </Box>
      )}
    </Box>
  );
};
