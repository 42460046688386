import * as actions from "./Type";

const initialState = {
  isLoading: false,
  isLoadingCategory: false,
  deleteLoading: false,
  contentLoading: false,
  updateLoading: false,
  statusLoading: false,
  adsCategory: [],
  promotedContent: [],
  ads: [],
  editAds: [],
  updatePromotedContent: {},
  category_promotional: {
    category: "",
    content: [],
    data: [],
  },
};

export const adsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.ADS_POST_BEGIN:
      return {
        ...state,
        isLoading: true,
      };

    case actions.ADS_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actions.ADS_POST_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case actions.GET_ADS_BEGIN:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_ADS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ads: action.payload.data,
      };

    case actions.GET_ADS_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case actions.GET_ADS_CATEGORY_BEGIN:
      return {
        ...state,
        isLoadingCategory: true,
      };

    case actions.GET_ADS_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoadingCategory: false,
        adsCategory: action.payload.data,
      };

    case actions.GET_ADS_CATEGORY_FAIL:
      return {
        ...state,
        isLoadingCategory: false,
      };

    case actions.DELETE_ADS_BEGIN:
      return {
        ...state,
        deleteLoading: true,
      };

    case actions.DELETE_ADS_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
      };

    case actions.DELETE_ADS_FAIL:
      return {
        ...state,
        deleteLoading: false,
      };

    case actions.GET_ADS_BY_TITLE_BEGIN:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_ADS_BY_TITLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        editAds: action.payload.data,
      };

    case actions.GET_ADS_BY_TITLE_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case actions.POST_ADS_UPDATE_BEGIN:
      return {
        ...state,
        isLoading: true,
      };

    case actions.POST_ADS_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actions.POST_ADS_UPDATE_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    ////////////////////////////////////////////

    case actions.POST_PROMOTED_CONTENT_BEGIN:
      return {
        ...state,
        contentLoading: true,
      };

    case actions.POST_PROMOTED_CONTENT_SUCCESS:
      return {
        ...state,
        contentLoading: false,
      };

    case actions.POST_PROMOTED_CONTENT_FAIL:
      return {
        ...state,
        contentLoading: false,
      };

    case actions.GET_PROMOTED_CONTENT_BEGIN:
      return {
        ...state,
        contentLoading: true,
      };

    case actions.GET_PROMOTED_CONTENT_SUCCESS:
      console.log("12", action.payload.data);
      return {
        ...state,
        contentLoading: false,
        promotedContent: action.payload.data,
      };

    case actions.GET_PROMOTED_CONTENT_FAIL:
      return {
        ...state,
        contentLoading: false,
      };

    case actions.DELETE_PROMOTED_CONTENT_BEGIN:
      return {
        ...state,
        contentLoading: true,
      };

    case actions.DELETE_PROMOTED_CONTENT_SUCCESS:
      return {
        ...state,
        contentLoading: false,
      };

    case actions.DELETE_PROMOTED_CONTENT_FAIL:
      return {
        ...state,
        contentLoading: false,
      };

    case actions.UPDATE_PROMOTED_CONTENT_BEGIN:
      return {
        ...state,
        editLoading: true,
      };

    case actions.UPDATE_PROMOTED_CONTENT_SUCCESS:
      return {
        ...state,
        editLoading: false,
      };

    case actions.UPDATE_PROMOTED_CONTENT_FAIL:
      return {
        ...state,
        editLoading: false,
      };

    case actions.POST_PROMOTED_CONTENT_STATUS_BEGIN:
      return {
        ...state,
        statusLoading: true,
      };

    case actions.POST_PROMOTED_CONTENT_STATUS_SUCCESS:
      return {
        ...state,
        statusLoading: false,
      };
    case actions.GET_PROMOTED_CONTENT_UPDATE_BEGIN:
      return {
        ...state,
        updateLoading: true,
      };

    case actions.GET_PROMOTED_CONTENT_UPDATE_SUCCESS:
      return {
        ...state,
        updatePromotedContent: action.payload.data,
        updateLoading: false,
      };

    case actions.GET_PROMOTED_CONTENT_UPDATE_FAIL:
      return {
        ...state,
        updateLoading: false,
      };
    case actions.POST_PROMOTED_CONTENT_STATUS_FAIL:
      return {
        ...state,
        statusLoading: false,
      };

    case actions.CLEAN_PROMOTED_CONTENT_UPDATE:
      return {
        ...state,
        updatePromotedContent: {},
      };

    case actions.GET_CATEGORY_PROMOTIONAL_BEGIN:
      return {
        ...state,
        updateLoading: true,
        category_promotional: {
          ...state.category_promotional
        },

      };

    case actions.GET_CATEGORY_PROMOTIONAL_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        category_promotional: {
          ...state.category_promotional,
          category: action.payload.category,
          content: [...action.payload.content],
        },
      };

    case actions.GET_CATEGORY_PROMOTIONAL_FAIL:
      return {
        ...state,
        updateLoading: false,
      };

    case actions.POST_CATEGORY_PROMOTIONAL_BEGIN:
      return {
        ...state,
        updateLoading: true,
      };

    case actions.POST_CATEGORY_PROMOTIONAL_SUCCESS:
      return {
        ...state,
        updateLoading: false,
      };

    case actions.POST_CATEGORY_PROMOTIONAL_FAIL:
      return {
        ...state,
        updateLoading: false,
      };

    case actions.GET_CATEGORY_PROMOTIONAL_DATA_BEGIN:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_CATEGORY_PROMOTIONAL_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        category_promotional: {
          ...state.category_promotional,
          data: {...action.payload.data},

        },
      };

    case actions.GET_CATEGORY_PROMOTIONAL_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
      };

      case actions.DELETE_CATEGORY_PROMOTIONAL_DATA_BEGIN:
      return {
        ...state,
        isLoading: true,
      };

    case actions.DELETE_CATEGORY_PROMOTIONAL_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actions.DELETE_CATEGORY_PROMOTIONAL_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
