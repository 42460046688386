import { returnErrors } from '../../redux/Error/Error.action';
import { axiosInstance } from '../../utils/axiosInstance';
import { handleError, handleResponse } from '../apiResponse';

export function postCreateAdminApi(data) {
  return axiosInstance()
    .post('/admin/create-admin', data)
    .then(handleResponse)
    .catch(handleError);
}

export function getPermissionApi() {
  return axiosInstance().get('/admin/permission').catch(handleError);
}

export function getAllAdminApi() {
  return axiosInstance().get('/admin/get-all-admin').catch(handleError);
}
export function postChangePasswordApi(data) {
  return axiosInstance()
    .post('/admin/change-admin-password', data)
    .then(handleResponse)
    .catch(handleError);
}

export function updatePermissionApi(id, data) {
  return axiosInstance()
    .patch(`/admin/update-permission/${id}`, data)
    .then(handleResponse)
    .catch(handleError);
}

export function getSingleAdminPermissionApi(id) {
  return axiosInstance().get(`/admin/permission/${id}`).catch();
}

export function getUpdateJobApi(slug) {
  return axiosInstance().get(`/admin/job-category/${slug}`).catch(handleError);
}

export function putUpdateJobApi(slug, data) {
  return axiosInstance()
    .post(`/admin/job-category/${slug}`, data)
    .then(handleResponse)
    .catch(handleError);
}

export function deleteJobApi(slug) {
  return axiosInstance()
    .delete(`/admin/job-category/${slug}`)
    .then(handleResponse)
    .catch(handleError);
}

export function getAllJobApi() {
  return axiosInstance().get('/admin/job').catch(handleError);
}

export function postJobStatusApi(slug, data) {
  return axiosInstance()
    .post(`/admin/job/${slug}/change-active`, data)
    .then(handleResponse)
    .catch(handleError);
}
