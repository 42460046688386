import React from 'react'
import BusinessPage from '../../components/business/Index'

const Job = () => {
    return (
        <>
            <BusinessPage />
        </>
    )
}

export default Job