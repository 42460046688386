import { Breadcrumbs, Button, Grid, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import UploadIcon from '@mui/icons-material/Upload';

import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import InsideHeader from '../../layout/InsideHeader'
import { CustomHiddenInput } from '../common/customInput/CustomHiddenInput';
import { Box } from '@mui/system';
import CustomTextarea from '../common/customTextarea/CustomTextarea';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBanner, getBanner, postBanner } from './redux/Action';
import { serializer } from '../../utils/helper';
import Loader from '../../pages/Home/Loader';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        "& .uploadAndEdit": {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'

        }
    },
    delete: {
        backgroundColor: '#ff000094 !important',
        padding: '10px !important',
        fontSize: '13px !important',
        '&:hover': {
            backgroundColor: '#DB143C !important'
        }
    },

    description: {
        '& .MuiOutlinedInput-root': {
            paddingLeft: '0px !important',
            paddingRight: '0px !important'
        }
    },

    edit: {
        backgroundColor: '#008000a6 !important',
        marginRight: '20px !important',
        fontSize: '13px !important',
        '&:hover': {
            backgroundColor: 'green !important'
        }
    },
}));


const BannerUpload = () => {

    const classes = useStyles();
    const dispatch = useDispatch()
    const banner = useSelector((state) => state?.siteSetting)


    const defaultValues = {
        image: '',
        description: '',
        key: 'Search',
        link: 'https://nepalina.com/'
    };

    const {
        handleSubmit,
        control,
        reset,
        watch,
        setValue,
        formState: { errors },
    } = useForm({ defaultValues: defaultValues });

    const searchBannerRef = useRef(null);

    const searchBanner = watch('image')
    const uploadSearchBanner = () => {
        searchBannerRef.current.click();
    };

    const onSubmit = (values) => {
        const formData = new FormData();
        serializer(formData, values)
        dispatch(postBanner(formData))
        reset();
    };

    const handleDelete = (key) => {
        dispatch(deleteBanner(key, reset))
    }

    useEffect(() => {
        setValue('description', banner?.banner?.[0]?.description)
    }, [banner, setValue])

    useEffect(() => {
        dispatch(getBanner())
    }, [])

    const checkKeyDown = (e) => {
        if (e.code === "Enter") e.preventDefault();
    };

    return (banner === 'null' || banner?.bannerLoading) ? <Loader /> : (
        <>
            <Paper elevation={0} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', background: '#f8f8f8', marginBottom: '15px' }}>
                <InsideHeader title="Banner Upload">
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Link color="inherit" to="/">
                            Setting
                        </Link>
                        <Typography color="textPrimary">Banner Upload</Typography>
                    </Breadcrumbs>
                </InsideHeader>
            </Paper>


            <Paper elevation={1} className={classes.paper} sx={{ padding: '25px', marginBottom: '30px' }}>
                <div className={classes.root}>
                    <form onSubmit={handleSubmit(onSubmit)}
                        onKeyDown={(e) => checkKeyDown(e)}>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                    <div className="uploadAndEdit">
                                        <label style={{ color: '#828282', fontSize: '14px', paddingBottom: '4px' }}>Upload Search Banner</label>
                                    </div>
                                    <div
                                        onClick={uploadSearchBanner}
                                        style={{
                                            height: '179px',
                                            width: '100%',
                                            background: '#f4f9ff',
                                            border: '1px solid #e4e4e4',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            position: 'relative',
                                            cursor: 'pointer'
                                        }}>
                                        <img style={{ position: 'absolute', top: '0', left: '0', objectFit: 'cover' }}
                                            src={!searchBanner ? banner?.banner?.[0]?.image : URL?.createObjectURL(searchBanner)}
                                            width="100%"
                                            height="100%"
                                            alt=""
                                        />
                                        <span
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                            }}>
                                            <UploadIcon />
                                            Upload
                                        </span>
                                    </div>
                                    <CustomHiddenInput
                                        style={{
                                            display: 'none',
                                        }}
                                        className="uploadImage"
                                        type="file"
                                        accept="image/*"
                                        control={control}
                                        name="image"
                                        ref={searchBannerRef}
                                    />
                                </div>
                            </Grid>

                            <Grid item sm={12} className={classes.description} sx={{ marginTop: '20px' }} >
                                <CustomTextarea
                                    label="Description"
                                    control={control}
                                    errors={errors}
                                    name="description"
                                    rows={5}
                                />
                            </Grid>

                            <Grid item sm={2}>
                                {banner?.banner.length === 0 ?
                                    <Box marginTop={2.5} marginLeft={0} sx={{ margiLeft: '0px', marginTop: '0px' }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            size="large"
                                            type="submit"
                                        >
                                            Post
                                        </Button>
                                    </Box>
                                    :
                                    <Box marginTop={2.5} marginLeft={0} sx={{ margiLeft: '0px', marginTop: '0px', display: 'flex' }}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            className={classes.edit}
                                        >
                                            Update
                                        </Button>
                                        <Button
                                            className={classes.delete}
                                            variant="contained"
                                            fullWidth
                                            size="large"
                                            onClick={() => handleDelete(banner?.banner?.[0]?.key)}
                                        >
                                            Delete
                                        </Button>
                                    </Box>}
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Paper>
        </>
    )
}

export default BannerUpload