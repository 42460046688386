import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";
import { Box, Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useRef } from "react";
import { CustomHiddenInput } from "../common/customInput/CustomHiddenInput";
import { CustomInput } from "../common/customInput/CustomInput";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },

  delete: {
    backgroundColor: "#ff6363 !important",
    color: "white",
    cursor: "pointer",
    marginLeft: "10px",
    fontSize: "14px !important",
    "&:hover": {
      backgroundColor: "#DB143C !important",
    },
  },
}));

function Amenities({ control, setValue, errors, watch }) {
  const classes = useStyles();

  const amenities = watch()?.amenities;

  console.log(watch()?.amenities);

  const handleAmenities = () => {
    const length = amenities.length;
    setValue(`amenities[${length}][title]`, "");
  };

  return (
    <>
      <div className={classes.root}>
        <Box display="flex" justifyContent="flex-end" marginBottom={3}>
          <Button
            sx={{ marginRight: "10px", fontSize: "14px" }}
            variant="contained"
            color="primary"
            size="large"
            onClick={handleAmenities}
          >
            Add Amenities <AddIcon />
          </Button>
        </Box>

        {amenities?.map((list, index) => (
          <AmenitiesList
            control={control}
            errors={errors}
            index={index}
            list={list}
            key={index}
            watch={watch}
            setValue={setValue}
          />
        ))}
      </div>
    </>
  );
}

export default Amenities;

const AmenitiesList = ({ control, errors, index, list, watch, setValue }) => {
  const classes = useStyles();

  const upload = useRef(null);
  const uploadImage = () => {
    upload.current.click();
  };
  const amenities = watch()?.amenities;
  const handleDelete = (ind) => {
    const data = amenities?.filter((list, index) => index !== ind);
    setValue("amenities", data);
  };

  return (
    <Box marginTop={2}>
      <Grid container spacing={2}>
        <Grid item sm={8}>
          <CustomInput
            label="Title"
            control={control}
            errors={errors}
            name={`amenities[${index}][title]`}
          />
          <Typography sx={{ color: "red !important", fontSize: "12px" }}>
            {errors?.title?.message}
          </Typography>
        </Grid>
        <Grid item sm={2} sx={{ paddingTop: "8px !important" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="uploadAndEdit">
              <label style={{ color: "#828282", fontSize: "13px" }}>
                Upload Icon
              </label>
            </div>
            <div
              onClick={uploadImage}
              style={{
                height: "50px",
                width: "50px",
                background: "#f4f9ff",
                border: "1px solid #e4e4e4",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                cursor: "pointer",
              }}
            >
              <img
                style={{ position: "absolute", top: "0", left: "0" }}
                src={!list["icon"] ? "" : URL?.createObjectURL(list["icon"])}
                width="100%"
                height="100%"
                alt=""
              />

              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={uploadImage}
              >
                <UploadIcon />
              </span>
            </div>
            <CustomHiddenInput
              style={{
                display: "none",
              }}
              className="uploadImage"
              type="file"
              control={control}
              name={`amenities[${index}][icon]`}
              ref={upload}
            />
            <Typography sx={{ color: "red !important", fontSize: "12px" }}>
              {errors?.icon?.message}
            </Typography>
          </div>
        </Grid>

        <Grid item sm={1}>
          <Box marginTop={2} display="flex">
            <Button
              className={classes.delete}
              variant="contained"
              fullWidth
              size="large"
              onClick={() => handleDelete(index)}
            >
              <DeleteIcon />
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
