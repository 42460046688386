import { Box, Button, CircularProgress, Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { CustomInput } from "../common/customInput/CustomInput";
import CustomTextarea from "../common/customTextarea/CustomTextarea";
import { Breadcrumbs, Typography } from "@mui/material";
import InsideHeader from "../../layout/InsideHeader";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as actions from "./redux/Type";
import { postLookingFor, updateLookingFor } from "./redux/Action";
import ViewLookingFor from "./ViewLookingFor";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    // paddingRight: '16px',

    "& .uploadAndEdit": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },

  descriptionField: {
    "& .MuiOutlinedInput-root": {
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
    },
  },

  delete: {
    backgroundColor: "#ff6363 !important",
    color: "white",
    cursor: "pointer",
    marginLeft: "10px",
    fontSize: "14px !important",
    "&:hover": {
      backgroundColor: "#DB143C !important",
    },
  },
}));

function LookingFor() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { lookingForLoading, lookingForPostStatus: postStatus } = useSelector(
    (state) => state?.siteSetting
  );

  const [toggle, setToggle] = useState(false);
  const [buttonName, setButtonName] = useState("Add");

  const defaultValues = {
    title: "",
    description: "",
  };

  const schema = yup
    .object({
      title: yup.string().required("Title is required"),
      description: yup.string().required("Description is required"),
    })
    .required();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
    setFocus,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (postStatus === "completed") {
      setToggle((prev) => !prev);
      dispatch({ type: actions.RESET_LOOKING_POST_STATUS });
    }
  }, [postStatus, dispatch]);

  const handleToggle = () => {
    // dispatch({ type: actions.CLEAN_CATEGORY_UPDATE });
    setButtonName("Add");
    setToggle((prev) => !prev);
    reset();
  };

  const onSubmit = (values) => {
    if (values?.id) {
      dispatch(updateLookingFor(values));
    } else {
      dispatch(postLookingFor(values));
    }
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          background: "#f8f8f8",
          marginBottom: "15px",
        }}
      >
        <InsideHeader title="Looking For">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Settings
            </Link>
            <Typography color="textPrimary">Looking For</Typography>
          </Breadcrumbs>
        </InsideHeader>
        <Button
          sx={{ height: "40px" }}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleToggle}
        >
          Add
        </Button>
      </Paper>
      {toggle && (
        <Paper
          elevation={1}
          className={classes.paper}
          sx={{ padding: "25px", marginBottom: "30px" }}
        >
          <div className={classes.root}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <CustomInput
                    label="Title"
                    control={control}
                    errors={errors}
                    name="title"
                  />
                  <Typography
                    sx={{ color: "red !important", fontSize: "12px" }}
                  >
                    {errors?.title?.message}
                  </Typography>
                </Grid>

                <Grid
                  item
                  sm={12}
                  sx={{ marginTop: "20px" }}
                  className={classes.descriptionField}
                >
                  <CustomTextarea
                    label="Description"
                    control={control}
                    errors={errors}
                    name="description"
                  />
                  <Typography
                    sx={{ color: "red !important", fontSize: "12px" }}
                  >
                    {errors?.description?.message}
                  </Typography>
                </Grid>

                <Grid item sm={2}>
                  <Box
                    marginTop={2.5}
                    marginLeft={0}
                    sx={{
                      margiLeft: "0px",
                      marginTop: "0px",
                      display: "flex",
                    }}
                  >
                    <Button
                      sx={{ marginRight: "10px", fontSize: "14px" }}
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                    >
                      {!lookingForLoading ? (
                        `${buttonName}`
                      ) : (
                        <CircularProgress size={30} sx={{ color: "white" }} />
                      )}
                    </Button>
                    <Button
                      className={classes.delete}
                      variant="contained"
                      fullWidth
                      size="large"
                      onClick={() => setToggle((prev) => !prev)}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </div>
        </Paper>
      )}
      <ViewLookingFor
        setToggle={setToggle}
        setButtonName={setButtonName}
        setValue={setValue}
        setFocus={setFocus}
      />
    </>
  );
}

export default LookingFor;
