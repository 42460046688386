import React from 'react'
import JobPage from '../../components/job/Index'

const Job = () => {
    return (
        <>
            <JobPage />
        </>
    )
}

export default Job