import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  Paper,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CustomHiddenInput } from '../common/customInput/CustomHiddenInput';
import UploadIcon from '@mui/icons-material/Upload';
import { CustomInput } from '../common/customInput/CustomInput';
import { serializer } from '../../utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAds, postAds, postAdsUpdate } from './redux/Action';
import { makeStyles } from '@mui/styles';
import CustomModal from '../common/customModal/CustomModal';

const useStyles = makeStyles(() => ({
  edit: {
    backgroundColor: '#008000a6 !important',
    '&:hover': {
      backgroundColor: 'green !important',
    },
  },

  delete: {
    bgColor: '#ff000094 !important',
    padding: '10px !important',
    '&:hover': {
      backgroundColor: '#DB143C !important',
    },
  },

  post: {
    backgroundColor: '#67b3fa !important',
    '&:hover': {
      backgroundColor: '#1b8af1 !important',
    },
  },

  modalDelete: {
    color: '#ff6363',
    cursor: 'pointer',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: '#DB143C !important',
    },
  },
}));

const CommonAdCard = ({ title, height, category, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const deleteLoading = useSelector((state) => state?.ads?.deleteLoading);

  const defaultValues = {
    image: '',
    link: '',
    ad_category: category,
  };

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
    setError,
  } = useForm({ defaultValues: defaultValues });

  const upload = useRef(null);

  const image = watch('image');

  const uploadImage = () => {
    upload.current.click();
  };

  const [deleteId, setDeleteId] = useState();
  const [open, setOpen] = React.useState(false);
  const [buttonLoader, setButtonLoader] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDelete = () => {
    deleteId && dispatch(deleteAds(deleteId, handleClose));
  };
  console.log('buttonLoader', buttonLoader);
  const handledeleteAds = (id) => {
    handleOpen();
    setDeleteId(id);
  };
  const handleError = (errors) => {
    console.log(errors, 'errors');
    Object.entries(errors)?.map((item) => {
      console.log('error', item);
      setError(item[0], { message: item[1] });
    });
  };
  const onSubmit = (values, e) => {
    setButtonLoader(true);
    if (data) {
      const formData = new FormData();
      serializer(formData, { ...values, title: data?.title, _method: 'patch' });
      dispatch(postAdsUpdate(data?.id, formData, handleError, setButtonLoader));
    } else {
      const formData = new FormData();
      serializer(formData, {
        ...values,
        title: title,
      });
      dispatch(postAds(formData, handleError, setButtonLoader));
    }
  };

  useEffect(() => {
    setValue('link', data?.link);
  }, [data, setValue]);

  const checkKeyDown = (e) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  return (
    <>
      <Paper elevation={3} sx={{ padding: '8px' }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => checkKeyDown(e)}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <div className="uploadAndEdit">
                  <label
                    style={{
                      color: '#828282',
                      fontSize: '14px',
                      color: 'black',
                    }}>
                    {title}
                  </label>
                  <label style={{ fontSize: '11px' }}>(Dimensions)</label>
                </div>
                <div
                  style={{
                    height: `${height}`,
                    width: '100%',
                    background: '#f4f9ff',
                    border: '1px solid #e4e4e4',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    cursor: 'pointer',
                  }}
                  onClick={uploadImage}>
                  <img
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      objectFit: 'fill',
                    }}
                    src={!image ? data?.image : URL?.createObjectURL(image)}
                    width="100%"
                    height="100%"
                    alt=""
                  />

                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}>
                    <UploadIcon />
                    Upload
                  </span>
                </div>
                <CustomHiddenInput
                  style={{
                    display: 'none',
                  }}
                  className="uploadImage"
                  type="file"
                  accept="image/*"
                  control={control}
                  name="image"
                  ref={upload}
                />
                <Typography sx={{ color: 'red !important', fontSize: '12px' }}>
                  {errors?.image?.message}
                </Typography>
              </div>
            </Grid>

            <Grid item sm={12}>
              <CustomInput
                label="Ads Link"
                control={control}
                errors={errors}
                name="link"
              />
              <Typography sx={{ color: 'red !important', fontSize: '12px' }}>
                {errors?.link?.message}
              </Typography>
            </Grid>
            <Grid item sm={1}>
              {!data ? (
                <Box
                  marginTop={2.5}
                  marginLeft={0}
                  sx={{
                    display: 'flex',
                    margiLeft: '0px',
                    marginTop: '0px',
                    fontSize: '13px',
                  }}>
                  <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    type="submit"
                    className={classes.post}>
                    POST
                  </Button>
                </Box>
              ) : (
                <Box
                  marginTop={2.5}
                  marginLeft={0}
                  sx={{
                    display: 'flex',
                    margiLeft: '0px',
                    marginTop: '0px',
                    fontSize: '13px',
                  }}>
                  <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    type="submit"
                    className={classes.edit}
                    disabled={buttonLoader}
                    sx={{ fontSize: '14px' }}>
                    {buttonLoader ? (
                      <CircularProgress size={30} sx={{ color: 'white' }} />
                    ) : (
                      'Update'
                    )}
                  </Button>

                  <Button
                    className={classes.delete}
                    variant="contained"
                    onClick={() => handledeleteAds(data?.id)}
                    sx={{
                      bgcolor: '#ff000094 !important',
                      marginLeft: '10px',
                      fontSize: '12px',
                    }}>
                    Delete
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </form>
      </Paper>

      <CustomModal
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        deleteStyle={classes.modalDelete}
        loading={deleteLoading}
      />
    </>
  );
};

export default CommonAdCard;
