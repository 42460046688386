import React from 'react'
import UserReviewsPage from '../../components/userReviews/Index'

const UserReviews = () => {
    return (
        <>
            <UserReviewsPage />
        </>
    )
}

export default UserReviews