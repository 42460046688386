import React from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import SingleBusinessPage from "./components/business/SingleBusinessPage";
import FullSpinner from "./components/common/spinner/FullSpinner";
import Toaster from "./components/common/toaster/Toaster";
import SingleJobPage from "./components/job/SingleJobPage";
import SingleRental from "./components/rental/SingleRental";
import SingleSale from "./components/sale/SingleSale";
import UserProfile from "./components/users/UserProfile";
import DashboardLayout from "./layout/DashboardLayout";
import AdsManagement from "./pages/Dashboard/AdsManagement";
import Blogs from "./pages/Dashboard/Blogs";
import Business from "./pages/Dashboard/Business";
import Contact from "./pages/Dashboard/Contact";
import Dashboard from "./pages/Dashboard/Index";
import Job from "./pages/Dashboard/Job";
import QuickPost from "./pages/Dashboard/QuickPost";
import Rental from "./pages/Dashboard/Rental";
import Role from "./pages/Dashboard/Role";
import Sale from "./pages/Dashboard/Sale";
import Settings from "./pages/Dashboard/Settings";
import UserReviews from "./pages/Dashboard/UserReviews";
import Users from "./pages/Dashboard/Users";
import SignIn from "./pages/Home/SignIn";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/signIn" element={<SignIn />} exact />
          <Route
            path="/"
            element={
              <RequireAuth>
                <DashboardLayout />
              </RequireAuth>
            }
          >
            <Route index element={<Dashboard />} />
            {/* {/* <Route path="about" element={<About />} /> */}

            <Route path="rental" element={<Rental />}>
              <Route path=":rentalId" element={<Rental />} />
              <Route path=":rentalId/:id" element={<Rental />} />
            </Route>
            <Route path="rental/lists/:id" element={<SingleRental />} />

            <Route path="sale" element={<Sale />}>
              <Route path=":saleId" element={<Sale />} />
              <Route path=":saleId/:id" element={<Sale />} />
            </Route>
            <Route path="sale/lists/:id" element={<SingleSale />} />

            <Route path="job" element={<Job />}>
              <Route path=":jobId" element={<Job />} />
              <Route path=":id/:id" element={<Job />} />
            </Route>

            <Route path="job/lists/:id" element={<SingleJobPage />} />

            <Route path="role" element={<Role />}>
              <Route path=":roleId" element={<Role />} />
              <Route path=":id/:id" element={<Role />} />
            </Route>

            <Route path="business" element={<Business />}>
              <Route path=":businessId" element={<Business />} />
              <Route path=":id/:id" element={<Business />} />
            </Route>

            <Route path="business/lists/:id" element={<SingleBusinessPage />} />

            <Route path="contact" element={<Contact />}>
              <Route path=":contactId" element={<Contact />} />
            </Route>

            <Route path="blogs" element={<Blogs />}>
              <Route path=":blogId" element={<Blogs />} />
            </Route>
            <Route path="quickpost" element={<QuickPost />}>
              <Route path=":quickpost" element={<QuickPost />} />
            </Route>

            <Route path="users" element={<Users />} />
            <Route path="users/:id" element={<UserProfile />} />

            <Route path="setting" element={<Settings />}>
              <Route path=":settingId" element={<Settings />} />
            </Route>

            <Route path="adsManagement" element={<AdsManagement />}>
              <Route path=":adsManagementId" element={<AdsManagement />} />
            </Route>

            <Route path="business/userReviews" element={<UserReviews />} />
          </Route>
        </Routes>
      </Router>
      <Toaster />
      <FullSpinner />
    </>
  );
}

function RequireAuth({ children }) {
  let auth = useSelector((state) => state.auth);
  let location = useLocation();

  if (!auth.token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return children;
}

export default App;
