export const ADS_POST_BEGIN = 'ADS_POST_BEGIN';
export const ADS_POST_SUCCESS = 'ADS_POST_SUCCESS';
export const ADS_POST_FAIL = 'ADS_POST_FAIL';

export const GET_ADS_BEGIN = 'GET_ADS_BEGIN';
export const GET_ADS_SUCCESS = 'GET_ADS_SUCCESS';
export const GET_ADS_FAIL = 'GET_ADS_FAIL';

export const GET_ADS_CATEGORY_BEGIN = 'GET_ADS_CATEGORY_BEGIN';
export const GET_ADS_CATEGORY_SUCCESS = 'GET_ADS_CATEGORY_SUCCESS';
export const GET_ADS_CATEGORY_FAIL = 'GET_ADS_CATEGORY_FAIL';

export const DELETE_ADS_BEGIN = 'DELETE_ADS_BEGIN';
export const DELETE_ADS_SUCCESS = 'DELETE_ADS_SUCCESS';
export const DELETE_ADS_FAIL = 'DELETE_ADS_FAIL';

export const GET_ADS_BY_TITLE_BEGIN = 'GET_ADS_BY_TITLE_BEGIN';
export const GET_ADS_BY_TITLE_SUCCESS = 'GET_ADS_BY_TITLE_SUCCESS';
export const GET_ADS_BY_TITLE_FAIL = 'GET_ADS_BY_TITLE_FAIL';

export const POST_ADS_UPDATE_BEGIN = 'POST_ADS_UPDATE_BEGIN';
export const POST_ADS_UPDATE_SUCCESS = 'POST_ADS_UPDATE_SUCCESS';
export const POST_ADS_UPDATE_FAIL = 'POST_ADS_UPDATE_FAIL';

export const POST_PROMOTED_CONTENT_BEGIN = 'POST_PROMOTED_CONTENT_BEGIN';
export const POST_PROMOTED_CONTENT_SUCCESS = 'POST_PROMOTED_CONTENT_SUCCESS';
export const POST_PROMOTED_CONTENT_FAIL = 'POST_PROMOTED_CONTENT_FAIL';

export const GET_PROMOTED_CONTENT_BEGIN = 'GET_PROMOTED_CONTENT_BEGIN';
export const GET_PROMOTED_CONTENT_SUCCESS = 'GET_PROMOTED_CONTENT_SUCCESS';
export const GET_PROMOTED_CONTENT_FAIL = 'GET_PROMOTED_CONTENT_FAIL';

export const DELETE_PROMOTED_CONTENT_BEGIN = 'DELETE_PROMOTED_CONTENT_BEGIN';
export const DELETE_PROMOTED_CONTENT_SUCCESS =
  'DELETE_PROMOTED_CONTENT_SUCCESS';
export const DELETE_PROMOTED_CONTENT_FAIL = 'DELETE_PROMOTED_CONTENT_FAIL';

export const UPDATE_PROMOTED_CONTENT_BEGIN = 'UPDATE_PROMOTED_CONTENT_BEGIN';
export const UPDATE_PROMOTED_CONTENT_SUCCESS =
  'UPDATE_PROMOTED_CONTENT_SUCCESS';
export const UPDATE_PROMOTED_CONTENT_FAIL = 'UPDATE_PROMOTED_CONTENT_FAIL';

export const POST_PROMOTED_CONTENT_STATUS_BEGIN =
  'POST_PROMOTED_CONTENT_STATUS_BEGIN';
export const POST_PROMOTED_CONTENT_STATUS_SUCCESS =
  'POST_PROMOTED_CONTENT_STATUS_SUCCESS';
export const POST_PROMOTED_CONTENT_STATUS_FAIL =
  'POST_PROMOTED_CONTENT_STATUS_FAIL';

export const GET_PROMOTED_CONTENT_UPDATE_BEGIN =
  'GET_PROMOTED_CONTENT_UPDATE_BEGIN';
export const GET_PROMOTED_CONTENT_UPDATE_SUCCESS =
  'GET_PROMOTED_CONTENT_UPDATE_SUCCESS';
export const GET_PROMOTED_CONTENT_UPDATE_FAIL =
  'GET_PROMOTED_CONTENT_UPDATE_FAIL';

export const CLEAN_PROMOTED_CONTENT_UPDATE = 'CLEAN_PROMOTED_CONTENT_UPDATE';

export const GET_CATEGORY_PROMOTIONAL_BEGIN = 'GET_CATEGORY_PROMOTIONAL_BEGIN'
export const GET_CATEGORY_PROMOTIONAL_SUCCESS = 'GET_CATEGORY_PROMOTIONAL_SUCCESS'
export const GET_CATEGORY_PROMOTIONAL_FAIL = 'GET_CATEGORY_PROMOTIONAL_FAIL'

export const POST_CATEGORY_PROMOTIONAL_BEGIN = 'POST_CATEGORY_PROMOTIONAL_BEGIN'
export const POST_CATEGORY_PROMOTIONAL_SUCCESS = 'POST_CATEGORY_PROMOTIONAL_SUCCESS'
export const POST_CATEGORY_PROMOTIONAL_FAIL = 'POST_CATEGORY_PROMOTIONAL_FAIL'

export const GET_CATEGORY_PROMOTIONAL_DATA_BEGIN = 'GET_CATEGORY_PROMOTIONAL_DATA_BEGIN'
export const GET_CATEGORY_PROMOTIONAL_DATA_SUCCESS = 'GET_CATEGORY_PROMOTIONAL_DATA_SUCCESS'
export const GET_CATEGORY_PROMOTIONAL_DATA_FAIL = 'GET_CATEGORY_PROMOTIONAL_DATA_FAIL'

export const DELETE_CATEGORY_PROMOTIONAL_DATA_BEGIN = 'DELETE_CATEGORY_PROMOTIONAL_DATA_BEGIN'
export const DELETE_CATEGORY_PROMOTIONAL_DATA_SUCCESS = 'DELETE_CATEGORY_PROMOTIONAL_DATA_SUCCESS'
export const DELETE_CATEGORY_PROMOTIONAL_DATA_FAIL = 'DELETE_CATEGORY_PROMOTIONAL_DATA_FAIL'

